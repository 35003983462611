import React from 'react'
import {
  Routes,
  Route,
  Navigate
} from 'react-router-dom'
import { Header } from './components/Header'
import { useSession } from './contexts/SessionContext'
import { ProtectedRoute } from './components/ProtectedRoutes'

// pages
import { Home } from './pages/Home'
import { Login } from './pages/Login'
import { ForgotPassword } from './pages/ForgotPassword'
import { ResetPassword } from './pages/ResetPassword'
import { ProjectList } from './pages/ProjectList'
import { ProjectDetails } from './components/ProjectDetails'
import { LocationDetails } from './components/LocationDetails'
import { DeviceMessageList } from './components/DeviceMessageList'
import { DeviceList } from './pages/DeviceList'
import { UsersList } from './pages/UsersList'
import { Profile } from './pages/Profile'
import { ChangePassword } from './pages/ChangePassword'
import { PageNotFound } from './pages/PageNotFound'
import 'react-loading-skeleton/dist/skeleton.css'
import { DeviceTypeList } from './pages/DeviceTypeList'


export const App = () => {
  const [{ loading, authInfo }] = useSession()

  return (
    <>
      {!loading && (
        <>
          {authInfo && <Header />}
          <Routes>
            <Route path='/login' element={authInfo ? <Navigate to='/projects' /> : <Login/>} />
            <Route path='/forgot-password' element={authInfo ? <Navigate to='/projects' /> : <ForgotPassword/>} />
            <Route path='/reset-password/:email' element={authInfo ? <Navigate to='/projects' /> : <ResetPassword/>} />
            <Route path='/projects' element={<ProtectedRoute children={<ProjectList />} />} />
            <Route path='/projects/:id' element={<ProtectedRoute children={<ProjectDetails />} />} />
            <Route path='/projects/:project_id/locations/:id' element={<ProtectedRoute children={<LocationDetails />} />} />
            <Route path='/projects/:project_id/devices/:id' element={<ProtectedRoute children={<DeviceMessageList />} />} />
            <Route path='/devices' element={<ProtectedRoute children={<DeviceList />} />} />
            <Route path='/devices/:id' element={<ProtectedRoute children={<DeviceMessageList />} />} />
            <Route path='/users' element={<ProtectedRoute children={<UsersList />} />} />
            <Route path='/device-types' element={<ProtectedRoute children={<DeviceTypeList />} />} />
            <Route path='/my-profile' element={<ProtectedRoute children={<Profile />} />} />
            <Route path='/changepass' element={<ProtectedRoute children={<ChangePassword />} />} />
            <Route path='/' element={ <Navigate to="/login" /> } />
            <Route path='*' element={<PageNotFound/>} />
          </Routes>
        </>
      )}
    </>
  )
}
