import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { ProjectIcon, DeviceIcon, UsergroupIcon } from '../Shared/SvgIcons'
import { UserPopover } from './UserPopover'
import {
  Container,
  InnerContainer,
  LogoWrapper,
  MainMenuList,
  MenuItem
} from './styles'
import { useSession } from '../../contexts/SessionContext'

export const Header = () => {
  const theme = useTheme()
  const [{ authInfo }] = useSession()
  const location = useLocation()
  const navigate = useNavigate()
  return (
    <Container>
      <InnerContainer>
        <LogoWrapper onClick={() => navigate('/projects')}>
          <img src={theme.images.logo} alt='logo' />
        </LogoWrapper>
        <MainMenuList>
          <MenuItem
            active={location.pathname.includes('/projects')}
            onClick={() => navigate('/projects')}
          >
            <ProjectIcon />
            <span>Projects</span>
          </MenuItem>
          {authInfo.user['custom:role'] === 'admin' &&
            <>
              <MenuItem
                active={location.pathname.includes('/devices')}
                onClick={() => navigate('/devices')}
              >
                <DeviceIcon />
                <span>Devices</span>
              </MenuItem>
              <MenuItem
                active={location.pathname === '/users'}
                onClick={() => navigate('/users')}
              >
                <UsergroupIcon />
                <span>Users</span>
              </MenuItem>
            </>
          }
          
          <MenuItem
            active={location.pathname === '/device-types'}
            onClick={() => navigate('/device-types')}
          >
            <DeviceIcon />
            <span>Device Types</span>
          </MenuItem>
        </MainMenuList>
        <UserPopover />
      </InnerContainer>
    </Container>
  )
}
