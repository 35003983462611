import React, { useEffect, useState, useMemo, useRef } from 'react'
import { SectionBar } from '../Shared'
import { BackIcon } from '../Shared/SvgIcons'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Container,
  InnerContainer,
  HeaderContainer,
  MessageList,
  PaginationWrapper,
  BackButtonWrapper
} from './styles'
import { Pagination } from './Pagination';
import { Message } from './Message'
import cogoToast from 'cogo-toast'
import { getMessages } from '../../services'

export const DeviceMessageList = (props) => {
  const nShowMessages = 10
  const navigate = useNavigate()
  const { id, project_id } = useParams();
  const [pagination, setPagination] = useState({ currentPage: 0, totalPages: 0 });
  const [isLoading, setIsLoading] = useState(true)
  const [messageList, setMessageList] = useState([]);
  const nextToken = useRef('');

  const messagesToShow = useMemo(() => {
    const messagesToShow = [];

    if (pagination.currentPage === 0) return [];

    const startIndex = (pagination.currentPage - 1) * nShowMessages;
    const endIndex = Math.min(messageList.length, startIndex + nShowMessages);
    for (let i = startIndex; i < endIndex; i++) {
      messagesToShow.push(messageList[i]);
    }

    return messagesToShow;
  }, [messageList, pagination]);
  
  const handleChangePage = async (page) => {
    console.log('page', page);
    if (page > pagination.totalPages) {
      await getMessageList(nextToken.current);
    } else {
      setPagination({
        ...pagination,
        currentPage: page
      })
    }
  }

  const getMessageList = async (next_token) => {
    setIsLoading(true);

    try {
      const result = await getMessages(id, next_token);
      nextToken.current = result.nextToken;
      setMessageList([ ...messageList, ...result.records ]);
      setPagination({
        currentPage: Math.ceil((result.records.length + messageList.length) / nShowMessages),
        totalPages: Math.ceil((result.records.length + messageList.length) / nShowMessages)
      });

      if (result.records.length === 0 && result.nextToken) {
        await getMessageList(nextToken.current);
      }
    } catch(error) {
      cogoToast.error(error.data.message || error.data);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMessageList(nextToken.current);
  }, [])

  return (
    <Container>
      <InnerContainer>
        <SectionBar />
        <HeaderContainer>
          <div className='title'>
            <h1>Messages</h1>
            <BackButtonWrapper onClick={() => {
              if (project_id) {
                navigate(`/projects/${project_id}`);
              } else {
                navigate('/devices');
              }
            }}>
              <BackIcon />
              <span>Back to Devices tab</span>
            </BackButtonWrapper>
          </div>
          {/* <div className='action'>
            <FilterPopover />
            <SortPopover />
            <Button
              color='primary'
              borderRadius='4px'
            >
              <DownLoadIcon />
              <span>Export as PDF</span>
            </Button>
          </div> */}
        </HeaderContainer>
        {isLoading ? (
          <MessageList>
            {[...Array(6).keys()].map((i) => (
              <Message isSkeleton key={i} />
            ))}
          </MessageList>
        ) : (
          messageList.length === 0 ?
            <h3 style={{textAlign:'center', margin: 30}}>No Messages</h3>
          :
            <MessageList>
              {messagesToShow.map((message, key) => (
                <Message
                  message={message}
                  key={key} 
                  no={ (pagination.currentPage - 1) * nShowMessages + key + 1 }
                />
              ))}
            </MessageList>
        )}
          
        { messageList.length > 0 &&
          <PaginationWrapper>
            <Pagination
              nextEnabled={nextToken.current !== undefined}
              currentPage={pagination.currentPage}
              totalPages={pagination.totalPages}
              handleChangePage={handleChangePage}
            />
          </PaginationWrapper>
        }
      </InnerContainer>
    </Container>
  )
}
