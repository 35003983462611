import React from 'react'
import { ChangePassword as ChangePasswordController } from '../../components/ChangePassword'

export const ChangePassword = (props) => {
  return (
    <>
      <ChangePasswordController {...props} />
    </>
  )
}
