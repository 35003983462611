import React, { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Modal } from '../../Shared'
import { MessageIcon, PhoneIcon, BioGrapy, DeleteIcon, AlarmIcon, LocationConfigIcon } from '../../Shared/SvgIcons'
import { DeleteUser } from '../DeleteUser'
import {
  Container,
  Header,
  InfoItem,
  ActionContainer,
  DeleteButton
} from './styles'
import { EditProjectButton } from '../../DeviceList/DeviceDetail/styles'
import { SetConfig } from '../SetConfig'

export const UserDetail = (props) => {
  const { isSkeleton, user, no, location_id, onDeleted, onConfigured } = props
  const [isDelete, setIsDelete] = useState(false)
  const [isConfig, setIsConfig] = useState(false)

  return (
    <>
      <Container>
        <Header>
          {isSkeleton ? (
            <>
              <Skeleton width={120} height={25} />
              <Skeleton width={35} height={25} />
            </>
          ) : (
            <>
              <p className='name'>
                {user.user.Attributes.find(attr => attr.Name === 'given_name').Value} {user.user.Attributes.find(attr => attr.Name === 'family_name').Value}
              </p>
              <p className='number'>{String(no).padStart(2, '0')}</p>
            </>
          )}
        </Header>
        <InfoItem>
          {isSkeleton ? <Skeleton width={18} height={18} /> : <MessageIcon />}
          <div>
            <label>{isSkeleton ? <Skeleton width={60} /> : 'User Email'}</label>
            <p>{isSkeleton ? <Skeleton width={100} /> : user.user.Attributes.find(attr => attr.Name === 'email').Value}</p>
          </div>
        </InfoItem>
        <InfoItem>
          {isSkeleton ? <Skeleton width={18} height={18} /> : <PhoneIcon />}
          <div>
            <label>{isSkeleton ? <Skeleton width={60} /> : 'Phone Number'}</label>
            <p>{isSkeleton ? <Skeleton width={100} /> : user.user.Attributes.find(attr => attr.Name === 'phone_number').Value}</p>
          </div>
        </InfoItem>
        <InfoItem>
          {isSkeleton ? <Skeleton width={18} height={18} /> : <BioGrapy />}
          <div>
            <label>{isSkeleton ? <Skeleton width={60} /> : 'Bio'}</label>
            <p>{isSkeleton ? <Skeleton width={100} /> : user.user.Attributes.find(attr => attr.Name === 'custom:bio').Value}</p>
          </div>
        </InfoItem>
        <InfoItem>
          {isSkeleton ? <Skeleton width={18} height={18} /> : <AlarmIcon />}
          <div>
            <label>{isSkeleton ? <Skeleton width={60} /> : 'Alarm Types'}</label>
            <p>{isSkeleton ? <Skeleton width={100} /> : user.alarm_types || ''}&nbsp;</p>
          </div>
        </InfoItem>
        <ActionContainer>
          <EditProjectButton onClick={() => !isSkeleton && setIsConfig(true)} color='primary'>
            {isSkeleton ? <Skeleton width={24} height={24} /> : <LocationConfigIcon />}
            {isSkeleton ? <Skeleton width={60} style={{ marginLeft: '10px'}} /> : <span className='title'>Config</span>}
          </EditProjectButton>
          <DeleteButton onClick={() => !isSkeleton && setIsDelete(true)}>
            {isSkeleton ? <Skeleton width={24} height={24} /> : <DeleteIcon />}
            {isSkeleton ? <Skeleton width={60} style={{ marginLeft: '10px'}} /> : <span className='title'>Delete</span>}
          </DeleteButton>
        </ActionContainer>
      </Container>
      {isDelete && (
        <Modal
          onClose={() => setIsDelete(false)}
        >
          <DeleteUser
            location_id={location_id}
            user={user.user}
            onClose={() => setIsDelete(false)}
            onDeleted={() => {
              setIsDelete(false)
              onDeleted && onDeleted()
            }}
          />
        </Modal>
      )}

      {isConfig && (
        <Modal
          onClose={() => setIsConfig(false)}
        >
          <SetConfig
            location_id={location_id}
            user={user}
            onClose={() => setIsDelete(false)}
            onConfigured={(alarm_types) => {
              setIsConfig(false)
              onConfigured && onConfigured(alarm_types)
            }}
          />
        </Modal>
      )}
    </>
  )
}