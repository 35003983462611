import React from 'react'
import { DeviceList as DeviceListController } from '../../components/DeviceList'

export const DeviceList = (props) => {
  return (
    <>
      <DeviceListController {...props} />
    </>
  )
}
