import styled from 'styled-components'

export const Container = styled.div`
  padding-top: 120px;
  margin-bottom: 80px;

  @media (min-width: 768px) {
    padding-top: 140px;
    margin-bottom: 0px;
  }
`
export const InnerContainer = styled.div`
  max-width: 1440px;
  min-height: inherit;
  margin: 0px auto;
  padding: 15px;

  @media (min-width: 576px) {
    padding: 15px 20px;
  }

  @media (min-width: 768px) {
    padding: 20px 30px;
  }

  @media (min-width: 993px) {
    padding: 24px 40px;
  }

  @media (min-width: 1024px) {
    padding: 28px 60px;
  }
  
  @media (min-width: 1500px) {
    padding: 28px 30px;
  }
`
export const ProfileForm = styled.div`
  max-width: 1044px;
  margin: auto;
`
export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`
export const PhotoWrapper = styled.div`
  width: 120px;
  height: 120px;
  margin-bottom: 16px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  @media (min-width: 768px) {
    width: 200px;
    height: 200px;
    margin-right: 52px;
    margin-bottom: 0px;
  }
`
export const MainInfoContainer = styled.div`
  p.name {
    font-weight: 600;
    font-size: 28px;
    line-height: 130%;
    color: ${props => props.theme.colors.black};
    text-align: center;
  }
  p.role {
    font-size: 16px;
    line-height: 140%;
    margin-top: 8px;
    text-align: center;
  }
  @media (min-width: 768px) {
    p.name {
      font-size: 40px;
      text-align: initial;
    }
    p.role {
      font-size: 24px;
      text-align: initial;
    }
  }
`
export const ChangePhotoButton = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0;
  margin: 32px auto 0 auto;
  
  span {
    color: ${props => props.theme.colors.primary};
    margin-left: 12px;
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
  }
  svg {
    width: 24px;
  }
  > input {
    display: none;
  }
  @media (min-width: 768px) {
    margin: 32px 0 0 0;

    span {
      font-size: 20px;
      margin-left: 16px;
    }
    svg {
      width: 34px;
    }
  }
`
export const MainFormContainer = styled.form`
  margin: 104px 0;

  > button {
    display: flex;
    align-items: center;
    height: 55px;
    font-size: 20px;
    font-weight: 700;
    margin-top: 52px;

    svg {
      width: 24px;
      height: 18px;
      margin-right: 12px;
      path {
        fill: ${props => props.theme.colors.white};
      }
    }
  }

  @media (min-width: 768px) {
    > button {
      font-size: 24px;
      margin-top: 72px;
      height: 69px;
    }
  }
`
export const Row = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 56px;
  }
`
export const FormController = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  label {
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    margin-bottom: 16px;
  }
  @media (min-width: 768px) {
    width: calc(50% - 28px);
    margin-bottom: 0px;
    label {
      font-size: 24px;
    }
  }
`
