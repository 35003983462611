import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 170px;
`

export const InnerContainer = styled.div`
  max-width: 1440px;
  margin: 0px auto;
  padding: 0px 15px;

  @media (min-width: 576px) {
    padding: 0px 20px;
  }

  @media (min-width: 769px) {
    padding: 0px 30px;
  }

  @media (min-width: 993px) {
    padding: 0px 40px;
  }

  @media (min-width: 1024px) {
    padding: 0px 60px;
  }
  
  @media (min-width: 1500px) {
    padding: 0px 30px;
  }
`

export const InterestedBox = styled.div`
  background: linear-gradient(97.01deg, #2C8DFF 0%, #004698 100%);
  border-radius: 20px;
  width: 100%;
  padding: 20px;

  > div {
    width: 100%;
    max-width: 946px;
    margin: 0 auto;
    h1 {
      font-weight: 700;
      font-size: 33px;
      line-height: 160%;
      text-align: center;
      color: ${props => props.theme.colors.white};
      margin-bottom: 24px;
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 160%;
      text-align: center;
      color: ${props => props.theme.colors.white};
      margin-bottom: 52px;
    }
  }

  @media (min-width: 576px) {
    padding: 30px;
    > div {
      h1 {
        font-size: 38px;
      }
      p {
        font-size: 18px;
      }
    }

    }
    @media (min-width: 769px) {
      padding: 40px;
      > div {
        h1 {
          font-size: 40px;
        }
        p {
          font-size: 20px;
        }
      }
    }

    @media (min-width: 993px) {
      padding: 58px;
      > div {
        h1 {
          font-size: 42px;
        }
        p {
          font-size: 22px;
        }
      }
    }

    @media (min-width: 1200px) {
      padding: 67px;
      > div {
        h1 {
          font-size: 48px;
        }
        p {
          font-size: 24px;
        }  
      }
    }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    padding: 20px 23px;
    font-weight: 700;
    font-size: 18px;
    line-height: 130%;
  }

  @media (min-width: 769px) {
    button {
      padding: 23px 26px;
      font-size: 24px;
    }
  }
`
