import React, { useState, useEffect, useRef } from 'react'
import { usePopper } from 'react-popper'
import { SortIcon, CheckIcon } from '../../../Shared/SvgIcons'
import {
  HeaderItem,
  PopoverBody,
  PopoverListItem,
  Divider
} from './styles'

export const SortPopover = (props) => {
  const {setIsAscending, setIsSortDate, isSortDate, isAscending} = props;

  const [open, setOpen] = useState(false)
  const referenceElement = useRef()
  const popperElement = useRef()

  const popper = usePopper(referenceElement.current, popperElement.current, {
    placement: 'bottom-start',
    modifiers: [
      { name: 'arrow' },
      {
        name: 'offset',
        options: {
          offset: [0, 12]
        }
      }
    ]
  })

  const { styles, attributes, update } = popper

  useEffect(() => {
    update && update()
  }, [open])

  const handleClickOutside = (e) => {
    if (!open) return
    const outsidePopover = !popperElement.current?.contains(e.target)
    const outsidePopoverMenu = !referenceElement.current?.contains(e.target)
    if (outsidePopover && outsidePopoverMenu) {
      setOpen(false)
    }
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 27) {
      setOpen(false)
    }
  }

  useEffect(() => {
    window.addEventListener('mouseup', handleClickOutside)
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('mouseup', handleClickOutside)
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [open])

  const popStyle = { ...styles.popper, visibility: open ? 'visible' : 'hidden', minWidth: '207px' }
  if (!open) {
    popStyle.transform = 'translate3d(0px, 0px, 0px)'
  }

  return (
    <div style={{ overflow: 'hidden' }}>
      <HeaderItem
        ref={referenceElement}
        onClick={() => setOpen(!open)}
      >
        <SortIcon />
        <span>Sort by</span>
      </HeaderItem>
      <PopoverBody ref={popperElement} style={popStyle} {...attributes.popper}>
        <PopoverListItem onClick={() => setIsSortDate(true)}>
          {isSortDate && <CheckIcon />}
          <label>Received At</label>
        </PopoverListItem>
        <PopoverListItem onClick={() => setIsSortDate(false)}>
          {!isSortDate && <CheckIcon />}
          <label>Name</label>
        </PopoverListItem>
        <Divider />
        <PopoverListItem onClick={() => setIsAscending(true)}>
          {isAscending && <CheckIcon />}
          <label>Ascending</label>
        </PopoverListItem>
        <PopoverListItem onClick={() => setIsAscending(false)}>
          {!isAscending && <CheckIcon />}
          <label>Descending</label>
        </PopoverListItem>
      </PopoverBody>
    </div>
  )
}
