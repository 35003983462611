import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useSession } from '../../contexts/SessionContext'
import { useTheme } from 'styled-components'
import { SectionBar, Input, Button } from '../Shared'
import { ArrowRight } from '../Shared/SvgIcons'
import {
  Container,
  InnerContainer,
  LogoWrapper,
  LoginForm,
  HeaderTitle,
  FormController,
  RedirectLink,
  FormBottomContainer
} from './styles'
import cogoToast from 'cogo-toast';
import { confirmPassword, forgotPassword } from '../../services';

export const ResetPassword = (props) => {
  const theme = useTheme();
  const { email } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPass, setConfirmPass] = useState('');

  const goLogin = () => {
    navigate('/login');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password !== confirmPass) return;
    if (loading) return;

		try {
			setLoading(true);
      await confirmPassword(email, code, password);
			cogoToast.success("Your password was reset successfully. Please login.")
			navigate('/login');

		} catch (error) {
			cogoToast.error(error.data.message || error.data);

		} finally {
			this.setState({
				submitting: false
			});
		}

    try {
      setLoading(true);
      // await forgotPassword(email);
      // cogoToast.success("We've sent verification code to your email. Please check your email.");
      // navigate.push(`/reset-password/${email}`);

    } catch (error) {
      cogoToast.error(error.data.message || error.data);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <InnerContainer>
        <LogoWrapper>
          <img src={theme.images.logo} alt='logo' />
        </LogoWrapper>
        <LoginForm onSubmit={handleSubmit}>
          <HeaderTitle>
            <SectionBar />
            <h2>Reset Password</h2>
          </HeaderTitle>
          <FormController>
            <label>Verification Code</label>
            <Input 
              placeholder="Enter verification code"
              required
              onChange={(event) => setCode(event.target.value)}
            />
          </FormController>
          <FormController>
            <label>New Password</label>
            <Input
              type="password"
              placeholder="Enter new password"
              required
              onChange={(event) => setPassword(event.target.value)}
            />
          </FormController>
          <FormController>
            <label>New Password</label>
            <Input
              type="password"
              placeholder="Confirm new password"
              required
              onChange={(event) => setConfirmPass(event.target.value)}
            />
          </FormController>
          <FormBottomContainer>
            <Button
              color='primary'
              isLoading={loading}
            >
              <span>Submit</span>
              <ArrowRight />
            </Button>
            <RedirectLink onClick={goLogin}>Sign In</RedirectLink>
          </FormBottomContainer>
        </LoginForm>
      </InnerContainer>
    </Container>
  )
}