import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 200px;
`

export const InnerContainer = styled.div`
  max-width: 1440px;
  margin: 0px auto;
  padding: 0px 15px;

  @media (min-width: 576px) {
    padding: 0px 20px;
  }

  @media (min-width: 769px) {
    padding: 0px 30px;
  }

  @media (min-width: 993px) {
    padding: 50px 40px;
  }

  @media (min-width: 1024px) {
    padding: 80px 60px;
  }
  
  @media (min-width: 1500px) {
    padding: 80px 30px;
  }
`

export const FeedbackBox = styled.div`
  background-color: ${props => props.theme.colors.primaryBoxBg};
  border-radius: 20px;
  padding: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;

  > img {
    margin-top: 0px;
    width: 85%;
    max-width: 350px;
  }

  @media (min-width: 576px) {
    padding: 15px 20px;
  }

  @media (min-width: 769px) {
    flex-direction: row;
    padding: 30px;
    > img {
      margin-top: 0px;
      width: 30%;
      max-width: 400px;
    }
  }

  @media (min-width: 993px) {
    padding: 30px 25px 0px 10px;
    > img {
      margin-top: -80px;
    }
  }

  @media (min-width: 1200px) {
    padding: 50px 35px 0px 15px;
    > img {
      margin-top: -120px;
    }
  }

  @media (min-width: 1440px) {
    > img {
      margin-top: -170px;
    }
    padding: 70px 40px 0px 20px;
  }
`

export const InfoWrapper = styled.div`
  > p {
    margin-top: 20px;
    font-family: 'PT Sans';
    font-style: italic;
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    padding-bottom: 20px;
  }

  @media (min-width: 769px) {
    > p {
    font-size: 22px;
    margin-top: 20px;
   }
  }

  @media (min-width: 1200px) {
   > p {
    font-size: 24px;
    margin-top: 25px;
   }
  }

  @media (min-width: 1440px) {
   > p {
    font-size: 28px;
    margin-top: 40px;
   }
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    > h2 {
      font-weight: 600;
      font-size: 28px;
      line-height: 130%;
      margin-bottom: 2px;
    }
    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 130%;
    }
  }
  img {
    height: 35px;
  }

  @media (min-width: 769px) {
    img {
      height: 40px;
    }
    > div {
      h2 {
        font-size: 30px;
      }
      p {
        font-size: 20px;
      }
    }
  }

  @media (min-width: 1200px) {
    img {
      height: 45px;
    }
    > div {
      h2 {
        font-size: 34px;
      }
      p {
        font-size: 22px;
      }
    }
  }

  @media (min-width: 1440px) {
    > div {
      h2 {
        font-size: 36px;
      }
      p {
        font-size: 24px;
      }
    }
  }
`
