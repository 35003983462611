import styled from 'styled-components'

export const HeaderItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 28px;
  span {
    font-size: 16px;
    line-height: 130%;
    color: ${props => props.theme.colors.black};
  }
  svg {
    color: ${props => props.theme.colors.black};
    margin-right: 8px;
  }
  &:hover {
    span {
      color: ${props => props.theme.colors.primary};
    }
    svg {
      path {
        fill: ${props => props.theme.colors.primary};
      }
    }
  }
`

export const PopoverBody = styled.div`
  background-color: ${props => props.theme.colors.white};
  z-index: 1001;
  border: 1px solid #E9EFFC;
  box-shadow: 0px 2px 30px rgba(215, 224, 235, 0.5);
  border-radius: 4px;
  padding: 6px 18px;
`

export const PopoverListItem = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 12px 0;

  label {
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    margin-bottom: 14px;
  }
  input {
    padding: 7px 8px;
    font-size: 12px;
    line-height: 14px;
  }
`
