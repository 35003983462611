import React, { useState } from 'react'
import cogoToast from 'cogo-toast'
import { NewProjectIcon } from '../../../Shared/SvgIcons'
import { Button, Input, Select } from '../../../Shared'
import {
  Container,
  InnerContainer,
  Header,
  Description,
  FormController
} from './styles'
import { createLocation } from '../../../../services/location'

export const AddLocation = (props) => {
  const { project_id, onAdded } = props

  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (loading) return;

    try {
      setLoading(true);
      const location = await createLocation(project_id, name);

      onAdded && onAdded(location);
			cogoToast.success('Location was created successfully.')

    } catch (error) {
      cogoToast.error(error.data.message || error.data);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      <InnerContainer onSubmit={handleSubmit}>
        <Header>
          <NewProjectIcon />
          <h1>Add Location</h1>
        </Header>
        <Description>Please fill out the location name and then submit.</Description>
        <FormController>
          <label>Location Name</label>
          <Input
            type="text"
            value={name}
            required
            onChange={(event) => setName(event.target.value)}
          />
        </FormController>

        <Button color='darkBlue' isLoading={loading}>Submit</Button>
      </InnerContainer>
    </Container>
  )
}
