import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useSession } from '../../contexts/SessionContext'
import { useTheme } from 'styled-components'
import { SectionBar, Input, Button } from '../Shared'
import { ArrowRight } from '../Shared/SvgIcons'
import {
  Container,
  InnerContainer,
  LogoWrapper,
  LoginForm,
  HeaderTitle,
  FormController,
  RedirectLink,
  FormBottomContainer
} from './styles'
import cogoToast from 'cogo-toast';
import { forgotPassword } from '../../services';

export const ForgotPassword = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  const goLogin = () => {
    navigate('/login');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (loading) return;

    try {
      setLoading(true);
      await forgotPassword(email);
      cogoToast.success("We've sent verification code to your email. Please check your email.");
      navigate(`/reset-password/${email}`);

    } catch (error) {
      cogoToast.error(error.data.message || error.data);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <InnerContainer>
        <LogoWrapper>
          <img src={theme.images.logo} alt='logo' />
        </LogoWrapper>
        <LoginForm onSubmit={handleSubmit}>
          <HeaderTitle>
            <SectionBar />
            <h2>Forgot Password</h2>
            <div>Enter your email address and your password will be reset and emailed to you.</div>
          </HeaderTitle>
          <FormController>
            <label>Your Email</label>
            <Input 
              type="email"
              placeholder="Enter your email"
              required
              onChange={(event) => setEmail(event.target.value)}
            />
          </FormController>
          <FormBottomContainer>
            <Button
              color='primary'
              isLoading={loading}
            >
              <span>Submit</span>
              <ArrowRight />
            </Button>
            <RedirectLink onClick={goLogin}>Sign In</RedirectLink>
          </FormBottomContainer>
        </LoginForm>
      </InnerContainer>
    </Container>
  )
}