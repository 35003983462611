import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import { useSession } from '../../contexts/SessionContext'
import { useTheme } from 'styled-components'
import { SectionBar, Input, Button } from '../Shared'
import { ArrowRight } from '../Shared/SvgIcons'
import {
  Container,
  InnerContainer,
  LogoWrapper,
  LoginForm,
  HeaderTitle,
  FormController,
  RedirectLink,
  FormBottomContainer
} from './styles'

export const Login = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [, { login }] = useSession();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async (event) => {
    event.preventDefault();
    if (loading) return;

    try {
      setLoading(true);
      await login({email, password});
      navigate('/projects');
    } catch (error) {
      cogoToast.error(error.data.message || error.data);
    } finally {
      setLoading(false);
    }
  };

  const goForgotPassword = () => {
    navigate('/forgot-password');
  };

  return (
    <Container>
      <InnerContainer>
        <LogoWrapper>
          <img src={theme.images.logo} alt='logo' />
        </LogoWrapper>
        <LoginForm onSubmit={handleLogin}>
          <HeaderTitle>
            <SectionBar />
            <h2>Login to your Account</h2>
          </HeaderTitle>
          <FormController>
            <label>Your Email</label>
            <Input 
              type="email"
              placeholder="Enter your email"
              required
              onChange={(event) => setEmail(event.target.value)}
            />
          </FormController>
          <FormController>
            <label>Your Password</label>
            <Input
              type="password"
              placeholder="Enter your password"
              required
              onChange={(event) => setPassword(event.target.value)}
            />
          </FormController>
          <FormBottomContainer>
            <Button
              color='primary'
              isLoading={loading}
            >
              <span>Login</span>
              <ArrowRight />
            </Button>
            <RedirectLink onClick={goForgotPassword}>Forgot Password ?</RedirectLink>
          </FormBottomContainer>
        </LoginForm>
      </InnerContainer>
    </Container>
  )
}