import styled, { css } from 'styled-components'

export const Container = styled.div`
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0px 2px 30px rgba(215, 224, 235, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1000;
`
export const InnerContainer = styled.div`
  max-width: 1440px;
  margin: 0px auto;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 576px) {
    padding: 22px 20px;
  }

  @media (min-width: 768px) {
    padding: 22px 30px;
  }

  @media (min-width: 993px) {
    padding: 22px 40px;
  }

  @media (min-width: 1024px) {
    padding: 22px 60px;
  }
  
  @media (min-width: 1500px) {
    padding: 22px 30px;
  }
`
export const LogoWrapper = styled.div`
  width: 182px;
  > img {
    width: 100%;
  }
  cursor: pointer;
  transition: all 0.2s ease-out;
  &:hover {
    transform: scale(1.04);
  }
`
export const MainMenuList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 20px 0;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0px -12px 80px rgba(215, 224, 235, 0.5);

  @media (min-width: 768px) {
    position: relative;
    box-shadow: none;
    padding: 0;
    width: initial;
  }
`
export const MenuItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  cursor: pointer;

  svg {
    path {
      fill: ${props => props.theme.colors.black};
    }
  }
  span {
    font-weight: 700;
    font-size: 18px;
    color: ${props => props.theme.colors.black};
    margin-top: 10px;
    white-space: nowrap;
  }

  ${({ active }) => active && css`
    svg {
      path {
        fill: ${props => props.theme.colors.primary};
      }
    }
    span {
      color: ${props => props.theme.colors.primary};
    }
  `}
  @media (min-width: 768px) {
    align-items: center;
    flex-direction: row;
    span {
      margin-top: 0;
    }
    svg {
      margin-right: 10px;
    }
  }
`
