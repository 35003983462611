import axios from 'axios';

export const getDeviceTypes = () => {
	return axios.get(`${process.env.REACT_APP_SERVER_URL}/device-types`)
		.then(data => data.data)
		.catch(error => { throw error.response });
};

export const addDeviceType = (name, configs) => {
	return axios.post(`${process.env.REACT_APP_SERVER_URL}/device-types`, { name, configs})
		.then(data => data.data)
		.catch(error => { throw error.response });
};

export const updateDeviceType = (id, name, configs) => {
	return axios.put(`${process.env.REACT_APP_SERVER_URL}/device-types/${id}`, { name, configs })
		.then(data => data.data)
		.catch(error => { throw error.response });
};

export const deleteDeviceType = (id) => {
	return axios.delete(`${process.env.REACT_APP_SERVER_URL}/device-types/${id}`)
		.then(data => data.data)
		.catch(error => { throw error.response });
}