import React, { useState } from 'react'
import cogoToast from 'cogo-toast'
import { useTheme } from 'styled-components'
import { Button } from '../../Shared'
import {
  Container,
  Header,
  Description
} from './styles'

import { deleteDevice } from '../../../services'

export const DeleteDevice = (props) => {
  const {
    device,
    onDeleted
  } = props

  const theme = useTheme()
  const [loading, setLoading] = useState(false)

  const handleDeleteDevice = async () => {
    try {
      if (loading) return;
      setLoading(true);
			await deleteDevice(device.project_id, device.reg_id);
      cogoToast.success('Device was deleted successfully.');
			onDeleted && onDeleted()
		} catch (error) {
			cogoToast.error(error.data.message || error.data);

		} finally {
			setLoading(false)
		}
  }

  return(
    <Container>
      <Header>
        <img src={theme.icons.deleteIcon} alt='deleteIcon' />
        <h1>Are You Sure ?</h1>
      </Header>
      <Description>Please confirm that you want to delete this device.</Description>
      <Button color='red' onClick={handleDeleteDevice} isLoading={loading}>Confirm & Delete</Button>
    </Container>
  )
}
