import styled from 'styled-components'

export const HeaderItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  span {
    font-size: 16px;
    line-height: 130%;
    color: ${props => props.theme.colors.black};
  }
  svg {
    color: ${props => props.theme.colors.black};
    margin-right: 8px;
  }
  &:hover {
    span {
      color: ${props => props.theme.colors.primary};
    }
    svg {
      path {
        fill: ${props => props.theme.colors.primary};
      }
    }
  }
`

export const PopoverBody = styled.div`
  background-color: ${props => props.theme.colors.white};
  z-index: 1001;
  border: 1px solid #E9EFFC;
  box-shadow: 0px 2px 30px rgba(215, 224, 235, 0.5);
  border-radius: 4px;
  padding: 10px 18px;
`

export const PopoverListItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px 0;
  position: relative;

  label {
    font-size: 16px;
    line-height: 21px;
    margin-left: 38px;
  }

  svg {
    position: absolute;
  }
`
export const Divider = styled.div`
  margin: 7px 0;
  background-color: #E0E0E0;
  height: 1px;
`
