import React, { useEffect, useState, useRef } from 'react'
import cogoToast from 'cogo-toast';
import { SectionBar, Button, Select } from '../Shared'
import { useNavigate, useParams } from 'react-router-dom'
import { BackIcon, ConfigIcon, DeviceIcon, LocationIcon, LocationThinIcon, UsergroupIcon, UserIcon } from '../Shared/SvgIcons'
import { FilterPopover as DeviceFilterPopover } from './DeviceList/FilterPopover';
import { SortPopover as DeviceSortPopover } from './DeviceList/SortPopover';
import { FilterPopover as LocationFilterPopover } from './LocationList/FilterPopover';
import { SortPopover as LocationSortPopover } from './LocationList/SortPopover';
import { FilterPopover as UserFilterPopover } from './UserList/FilterPopover';
import { SortPopover as UserSortPopover } from './UserList/SortPopover';
import { useWindowSize } from '../../hooks/useWindowSize'
import { DeviceList } from './DeviceList'
import { LocationList } from './LocationList'
import { useTheme } from 'styled-components'
import {
  Container,
  InnerContainer,
  HeaderContainer,
  MenuContainer,
  BackButtonWrapper,
  MenuListWrapper,
  MenuItem,
  ButtonWrapper,
  SelectWrapper,
  TabSelectWrapper,
  SelectedWrapper,
  DropDownList,
  DropDownItem
} from './styles'
import { getProject, getLocations } from '../../services';
import { UserList } from './UserList';
import Skeleton from 'react-loading-skeleton';

export const ProjectDetails = (props) => {
  const { id } = useParams();
  const navigate = useNavigate()
  const { width } = useWindowSize()
  const theme = useTheme()

  const [typeSelected, setTypeSelected] = useState('devices')
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isLoadingLocations, setIsLoadingLocations] = useState(false);
  const [project, setProject] = useState(null)
  const [locations, setLocations] = useState([{value:'', name:'All Locations'}])
  const [locationList, setLocationList] = useState([]);
  const dropdownReference = useRef()

  // Role Filter
  const [filterRole, setFilterRole] = useState('');

  // Location Filter
  const [filterLocationId, setFilterLocationId] = useState('');
  const [filterLocationName, setFilterLocationName] = useState('');
  
  // Device Filter
  const [filterDeviceName, setFilterDeviceName] = useState('');
  const [filterDeviceRegID, setFilterDeviceRegID] = useState('');

  // User Filter
  const [filterName, setFilterName] = useState('');
  const [filterEmail, setFilterEmail] = useState('');
  const [filterPhoneNumber, setFilterPhoneNumber] = useState('');
  const [filterBio, setFilterBio] = useState('');
  
  // Sort
  const [isAscending, setIsAscending] = useState(true);
  const [isSortDate, setIsSortDate] = useState(true);

  // Modal Open
  const [isAddDevice, setIsAddDevice] = useState(false);
  const [isAddLocation, setIsAddLocation] = useState(false);
  const [isAddUser, setIsAddUser] = useState(false);

  const [menuList, setMenuList] = useState([
    { value: 'devices', name: 'Devices', icon: <DeviceIcon /> },
    { value: 'locations', name: 'Locations', icon: <LocationIcon /> }
  ]);

  const roleList = [
    {value: '', name: 'All Roles'},
    {value: 'admin', name: 'Administrator'},
    {value: 'user', name: 'User'}
  ]

  const handleChangeType = (value) => {
    setIsOpen(false)
    setTypeSelected(value)
  }

  const closeSelect = (e) => {
    if (isOpen) {
      const outsideDropdown = !dropdownReference.current?.contains(e.target)
      if (outsideDropdown) {
        setIsOpen(false)
      }
    }
  }

  const getProjectLocations = async () => {
    try {
      setIsLoadingLocations(true);
      let _locations = await getLocations(id)
      setLocationList(_locations);
      _locations = _locations.map(location => {
        return { value: location.id, name: location.name }
      })
      _locations = [{value: '', name: 'All Locations'}, ..._locations]
      setLocations(_locations)
    } catch (error) {
      cogoToast.error(error.data.message || error.data);
    } finally {
      setIsLoadingLocations(false);
    }
  };

  const getProjectDetails = async () => {
    try {
      setLoading(true);
      const _project = await getProject(id);
      setProject(_project);
      if (_project.role !== 'user') {
        menuList.push({ value: 'users', name: 'Users', icon: <UsergroupIcon /> })
        setMenuList([...menuList])
      }
    } catch (error) {
      cogoToast.error(error.data.message || error.data);
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    getProjectLocations();
    getProjectDetails();
  }, [])

  useEffect(() => {
    document.addEventListener('mouseup', closeSelect)
    return () => {
      document.removeEventListener('mouseup', closeSelect)
    }
  }, [isOpen])

  return (
    <>
      <Container>
        <InnerContainer>
          <SectionBar />
          <HeaderContainer>
            <div className='title'>
              { loading ? <Skeleton width={190} height={56} /> : <h1>{project?.name}</h1> }
              <BackButtonWrapper onClick={() => navigate('/projects')}>
                <BackIcon />
                <span>Back to Projects</span>
              </BackButtonWrapper>
            </div>
            <div className='action'>

              {typeSelected === 'devices' &&
                <>
                  <DeviceFilterPopover
                    filterDeviceName={filterDeviceName}
                    filterDeviceRegID={filterDeviceRegID}
                    setFilterDeviceName={setFilterDeviceName}
                    setFilterDeviceRegID={setFilterDeviceRegID}
                  />

                  <DeviceSortPopover
                    isAscending={isAscending}
                    isSortDate={isSortDate}
                    setIsAscending={setIsAscending}
                    setIsSortDate={setIsSortDate}
                  />
                </>
              }

              {typeSelected === 'locations' &&
                <>
                  <LocationFilterPopover
                    filterLocationName={filterLocationName}
                    setFilterLocationName={setFilterLocationName}
                  />

                  <LocationSortPopover
                    isAscending={isAscending}
                    isSortDate={isSortDate}
                    setIsAscending={setIsAscending}
                    setIsSortDate={setIsSortDate}
                  />
                </>
              }

              {typeSelected === 'users' &&
                <>
                  <UserFilterPopover
                    filterName={filterName}
                    filterEmail={filterEmail}
                    filterPhoneNumber={filterPhoneNumber}
                    filterBio={filterBio}
                    setFilterName={setFilterName}
                    setFilterEmail={setFilterEmail}
                    setFilterPhoneNumber={setFilterPhoneNumber}
                    setFilterBio={setFilterBio}
                  />

                  <UserSortPopover
                    isAscending={isAscending}
                    isSortDate={isSortDate}
                    setIsAscending={setIsAscending}
                    setIsSortDate={setIsSortDate}
                  />
                </>
              }

              {/* <Button
                color='darkGreen'
                onClick={() => console.log('config...')}
              >
                <ConfigIcon />
                <span>Project Config</span>
              </Button> */}
            </div>
          </HeaderContainer>
          <MenuContainer>
            <MenuListWrapper>
              {width > 769 ? (
                <>
                  {menuList.map((item, i) => (
                    <MenuItem
                      key={i}
                      active={typeSelected === item.value}
                      onClick={() => setTypeSelected(item.value)}
                    >
                      {item.icon}
                      <span>{item.name}</span>
                    </MenuItem>
                  ))}
                </>
              ) : (
                <TabSelectWrapper>
                  <SelectedWrapper onClick={() => setIsOpen(true)}>
                    <MenuItem
                      active
                    >
                      {(menuList.find(item => item.value === typeSelected)).icon}
                      <span>{(menuList.find(item => item.value === typeSelected)).name}</span>
                    </MenuItem>
                    <img src={theme.icons.downArrow} alt='' />
                  </SelectedWrapper>
                  {isOpen && (
                    <DropDownList ref={dropdownReference}>
                      {menuList.map((menu, i) => (
                        <DropDownItem
                          key={i}
                          onClick={() => handleChangeType(menu.value)}
                          active={typeSelected === menu.value}
                        >
                          {menu.name}
                        </DropDownItem>
                      ))}
                    </DropDownList>
                  )}
                </TabSelectWrapper>
              )}
              {width < 769 && (
                <>
                  {typeSelected === 'devices' && project && project?.role !== 'user' && (
                    <Button
                      className='new-btn'
                      color='primary'
                      naked
                      borderRadius='4px'
                      onClick={() => setIsAddDevice(true)}
                    >
                      <DeviceIcon />
                      <span>New Device</span>
                    </Button>
                  )}
                  {typeSelected === 'locations' && project && project?.role !== 'user' && (
                    <Button
                      className='new-btn'
                      color='primary'
                      naked
                      borderRadius='4px'
                      onClick={() => setIsAddLocation(true)}
                    >
                      <LocationIcon />
                      <span>New Location</span>
                    </Button>
                  )}
                  {typeSelected === 'users' && (
                    <Button
                      color='primary'
                      className='new-btn'
                      naked
                      borderRadius='4px'
                      onClick={() => setIsAddUser(true)}
                    >
                      <UserIcon />
                      <span>New User</span>
                    </Button>
                  )}
                </>
              )}
            </MenuListWrapper>
            <ButtonWrapper>
              {typeSelected !== 'locations' && (
                <SelectWrapper>
                  <Select
                    options={locations}
                    defaultValue={filterLocationId}
                    onChange={setFilterLocationId}
                    leftIcon={<LocationThinIcon />}
                    noBg
                  />
                </SelectWrapper>
              )}
              {typeSelected === 'users' && (
                <SelectWrapper className='user-role'>
                  <Select
                    options={roleList}
                    defaultValue={filterRole}
                    onChange={setFilterRole}
                    leftIcon={<UserIcon />}
                    noBg
                  />
                </SelectWrapper>
              )}
              {width > 769 && (
                <>
                  {typeSelected === 'devices' && project && project?.role !== 'user' && (
                    <Button
                      color='primary'
                      className='new-btn'
                      naked
                      borderRadius='4px'
                      onClick={() => setIsAddDevice(true)}
                    >
                      <DeviceIcon />
                      <span>New Device</span>
                    </Button>
                  )}
                  {typeSelected === 'locations' && project && project?.role !== 'user' && (
                    <Button
                      color='primary'
                      naked
                      className='new-btn'
                      borderRadius='4px'
                      onClick={() => setIsAddLocation(true)}
                    >
                      <LocationIcon />
                      <span>New Location</span>
                    </Button>
                  )}
                  {typeSelected === 'users' && (
                    <Button
                      className='new-btn'
                      color='primary'
                      naked
                      borderRadius='4px'
                      onClick={() => setIsAddUser(true)}
                    >
                      <UserIcon />
                      <span>New User</span>
                    </Button>
                  )}
                </>
              )}

            </ButtonWrapper>
          </MenuContainer>
          {typeSelected === 'devices' && (
            <DeviceList
              project_id={id}
              project={project}
              filterDeviceName={filterDeviceName}
              filterDeviceRegID={filterDeviceRegID}
              filterLocationId={filterLocationId}
              isSortDate={isSortDate}
              isAscending={isAscending}
              isAddDevice={isAddDevice}
              locations={locations}
              onAdded={() => setIsAddDevice(false)}
            />
          )}
          {typeSelected === 'users' && (
            <UserList
              project_id={id}
              filterName={filterName}
              filterEmail={filterEmail}
              filterPhoneNumber={filterPhoneNumber}
              filterBio={filterBio}
              isAscending={isAscending}
              isSortDate={isSortDate}
              filterLocationId={filterLocationId}
              filterRole={filterRole}
              isAddUser={isAddUser}
              onAdded={() => setIsAddUser(false)}
              onClose={() => setIsAddUser(false)}
            />
          )}
          {typeSelected === 'locations' && (
            <LocationList
              project_id={id}
              project={project}
              locationList={locationList}
              isLoading={isLoadingLocations}
              filterLocationName={filterLocationName}
              isSortDate={isSortDate}
              isAscending={isAscending}
              isAddLocation={isAddLocation}
              onAdded={(location) => {
                if (location) {
                  setLocationList([...locationList, location])
                  setLocations([...locations, {value: location.id, name: location.name}])
                }
                setIsAddLocation(false)
              }}
              onDeleted={(location) => {
                if (location) {
                  setLocationList(locationList.filter(_location => _location.id !== location.id))
                  setLocations(locations.filter(_location => _location.value !== location.id))
                }
              }}
              onEdited={(location) => {
                if (location) {
                  let _loc = locationList.find(_location => _location.id === location.id);
                  if (_loc) {
                    _loc.name = location.name;
                  }
                  
                  _loc = locations.find(_location => _location.value === location.id);
                  if (_loc) {
                    _loc.name = location.name;
                  }

                  setLocationList([...locationList]);
                  setLocations([...locations])
                }
              }}
            />
          )}
        </InnerContainer>
      </Container>
    </>
  )
}
