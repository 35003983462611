import React, { useState, useEffect, useRef } from 'react'
import { usePopper } from 'react-popper'
import { Input } from '../../Shared'
import { FilterIcon } from '../../Shared/SvgIcons'
import {
  HeaderItem,
  PopoverBody,
  PopoverListItem,
} from './styles'

export const FilterPopover = (props) => {
  const {
    filterProjectName,
    filterAdminName,
    filterAdminEmail,
    filterCreationTime,
    setFilterProjectName,
    setFilterAdminName,
    setFilterAdminEmail,
    setFilterCreationTime
  } = props;
  const [open, setOpen] = useState(false)
  const referenceElement = useRef()
  const popperElement = useRef()

  const popper = usePopper(referenceElement.current, popperElement.current, {
    placement: 'bottom-end',
    modifiers: [
      { name: 'arrow' },
      {
        name: 'offset',
        options: {
          offset: [0, 12]
        }
      }
    ]
  })

  const { styles, attributes, update } = popper

  useEffect(() => {
    update && update()
  }, [open])

  const handleClickOutside = (e) => {
    if (!open) return
    const outsidePopover = !popperElement.current?.contains(e.target)
    const outsidePopoverMenu = !referenceElement.current?.contains(e.target)
    if (outsidePopover && outsidePopoverMenu) {
      setOpen(false)
    }
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 27) {
      setOpen(false)
    }
  }

  useEffect(() => {
    window.addEventListener('mouseup', handleClickOutside)
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('mouseup', handleClickOutside)
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [open])

  const popStyle = { ...styles.popper, visibility: open ? 'visible' : 'hidden', minWidth: '207px' }
  if (!open) {
    popStyle.transform = 'translate3d(0px, 0px, 0px)'
  }

  return (
    <div style={{ overflow: 'hidden' }}>
      <HeaderItem
        ref={referenceElement}
        onClick={() => setOpen(!open)}
      >
        <FilterIcon />
        <span>Filter</span>
      </HeaderItem>
      <PopoverBody ref={popperElement} style={popStyle} {...attributes.popper}>
        <PopoverListItem>
          <label>By Project Name</label>
          <Input
            type="text"
            value={filterProjectName} 
            onChange={(event) => setFilterProjectName(event.target.value)} 
          />
        </PopoverListItem>
        <PopoverListItem>
          <label>By Admin Name</label>
          <Input
            type="text"
            value={filterAdminName}
            onChange={(event) => setFilterAdminName(event.target.value)}
          />
        </PopoverListItem>
        <PopoverListItem>
          <label>By Admin Email</label>
          <Input
            type="text"
            value={filterAdminEmail}
            onChange={(event) => setFilterAdminEmail(event.target.value)}
          />
        </PopoverListItem>
        <PopoverListItem>
          <label>By Creation Time</label>
          <Input
            type="text"
            filter={filterCreationTime}
            onChange={(event) => setFilterCreationTime(event.target.value)}
          />
        </PopoverListItem>
      </PopoverBody>
    </div>
  )
}
