import cogoToast from 'cogo-toast';
import { useEffect, useState, useMemo, useCallback } from "react";
import { getProjectUsers } from "../../../services";
import { UserDetail } from '../../UsersList/UserDetail';
import { Pagination } from "../../Shared";
import { Modal } from '../../Shared/Modal'
import {
  PaginationWrapper
} from '../../DeviceList/styles';
import { Content } from "../styles";
import { AddUser } from './AddUser';

export const UserList = (props) => {
  const nShowUsers = 9;
  const {
    project_id,
    filterName,
    filterEmail,
    filterPhoneNumber,
    filterBio,
    isAscending,
    isSortDate,
    filterLocationId,
    filterRole,
    isAddUser,
    onAdded,
    onClose
  } = props;

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [pagination, setPagination] = useState({ currentPage: 0, totalPages: 0 });
  const [isLoading, setIsLoading] = useState(true);

  const handleChangePage = (page) => {
    setPagination({
      ...pagination,
      currentPage: page
    })
  };

  const getUserList = async () => {
    setUsers([]);

    try {
      setIsLoading(true);
      const _users = await getProjectUsers(project_id);
      setUsers(_users);
      setPagination({
        currentPage: 1,
        totalPages: Math.ceil(_users.length / nShowUsers)
      });

    } catch (error) {
      cogoToast.error(error.data.message || error.data);

    } finally {
			setIsLoading(false);
		}
  };

  const usersToShow = useMemo(() => {
    const usersToShow = [];

    let sortedUsers = [];
    if (isSortDate) {
      sortedUsers = filteredUsers.sort((a, b) => {
        if (isAscending) {
          return a.user.UserCreateDate.localeCompare(b.user.UserCreateDate);
        } else {
          return b.user.UserCreateDate.localeCompare(a.user.UserCreateDate);
        }
      })
    } else {
      sortedUsers = filteredUsers.sort((a, b) => {
        const name1 = `${a.user.Attributes.find(attr => attr.Name === 'given_name').Value} ${a.user.Attributes.find(attr => attr.Name === 'family_name').Value}`;
        const name2 = `${b.user.Attributes.find(attr => attr.Name === 'given_name').Value} ${b.user.Attributes.find(attr => attr.Name === 'family_name').Value}`;

        if (isAscending) {
          return name1.localeCompare(name2);
        } else {
          return name2.localeCompare(name1);
        }
      })
    }

    if (pagination.currentPage === 0) return [];

    const startIndex = (pagination.currentPage - 1) * nShowUsers;
    const endIndex = Math.min(sortedUsers.length, startIndex + nShowUsers);
    for (let i = startIndex; i < endIndex; i++) {
      usersToShow.push(sortedUsers[i]);
    }

    return usersToShow;
  }, [filteredUsers, pagination, isAscending, isSortDate]);

  const filterUsers = useCallback(() => {
    let _filteredUsers = [];
    _filteredUsers = users.filter(user => {
      const name = `${user.user.Attributes.find(attr => attr.Name === 'given_name').Value} ${user.user.Attributes.find(attr => attr.Name === 'family_name').Value}`;
      return name.toLowerCase().includes(filterName.trim().toLowerCase())
    })
    _filteredUsers = _filteredUsers.filter(user => {
      const email = user.user.Attributes.find(attr => attr.Name === 'email').Value;
      return email.toLowerCase().includes(filterEmail.trim().toLowerCase())
    })
    _filteredUsers = _filteredUsers.filter(user => {
      const phoneNumber = user.user.Attributes.find(attr => attr.Name === 'phone_number').Value
      return phoneNumber.toLowerCase().includes(filterPhoneNumber.trim().toLowerCase())
    })
    _filteredUsers = _filteredUsers.filter(user => {
      console.log(user)
      const bio = user.user.Attributes.find(attr => attr.Name === 'custom:bio')?.Value
      return bio?.toLowerCase()?.includes(filterBio.trim().toLowerCase())
    })
    if (filterLocationId) {
      _filteredUsers = _filteredUsers.filter(user => user.group_id === filterLocationId);
    }

    if (filterRole) {
      _filteredUsers = _filteredUsers.filter(user => {
        const role = user.user.Attributes.find(attr => attr.Name === 'custom:role').Value
        return role === filterRole;
      });
    }
    
    setFilteredUsers(_filteredUsers);
    
    if (_filteredUsers.length > 0) {
      setPagination({
        currentPage: 1,
        totalPages: Math.ceil(_filteredUsers.length / nShowUsers)
      });
    } else {
      setPagination({
        currentPage: 0,
        totalPages: Math.ceil(_filteredUsers.length / nShowUsers)
      });
    }
  }, [
    filterName,
    filterEmail,
    filterPhoneNumber,
    filterBio,
    filterLocationId,
    filterRole,
    users
  ]);

  useEffect(() => {
    filterUsers();
  }, [
    filterName,
    filterEmail,
    filterPhoneNumber,
    filterBio,
    filterLocationId,
    filterRole,
    users,
    filterUsers
  ]);

  useEffect(() => {
    getUserList();
  }, []);

  return (
    <>
      {isLoading ?
        <Content>
          {[...Array(6).keys()].map(i => (
            <UserDetail key={i} isSkeleton />
          ))}
        </Content>
      :
      <div>
        <Content>
          {usersToShow.map((user, i) => (
            <UserDetail
              key={i}
              no={i+1} 
              user={user.user}
              project_id={project_id}
              onDeleted={() => {
                getUserList();
              }}
            />
          ))}
        </Content>

        {usersToShow.length === 0 ?
          <h3 style={{textAlign:'center', margin: 30}}>No Users</h3>
        :
          <PaginationWrapper>
            <Pagination
              currentPage={pagination.currentPage}
              totalPages={pagination.totalPages}
              handleChangePage={handleChangePage}
            />
          </PaginationWrapper>
        }
      </div>}

      {isAddUser && (
        <Modal
          onClose={() => {
            onClose && onClose();
          }}
        >
          <AddUser
            project_id={project_id}
            onClose={() => {}}
            onAdded={() => {
              onAdded && onAdded();
              getUserList();
            }}
          />
        </Modal>
      )}
    </>
  );
};