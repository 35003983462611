import React from 'react'
import { ProjectList as ProjectListController } from '../../components/ProjectList'

export const ProjectList = (props) => {
  return (
    <>
      <ProjectListController {...props} />
    </>
  )
}
