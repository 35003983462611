import React, { useState } from 'react'
import cogoToast from 'cogo-toast'
import { EditIcon } from '../../Shared/SvgIcons'
import { Button, Input, Select } from '../../Shared'
import {
  Container,
  InnerContainer,
  Header,
  Description,
  FormController
} from './styles'

import { updateDevice } from '../../../services'

export const RenameDevice = (props) => {
  const {
    device,
    deviceTypes,
    onClose
  } = props
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState(device.name)
  const [deviceType, setDeviceType] = useState(device.device_type_id)

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (loading) return;
    try {
      setLoading(true)
      if (!deviceType) {
        return cogoToast.error('Please choose a device type.')
      }
			await updateDevice(device.project_id, device.reg_id, name, deviceType);
			cogoToast.success('Device was updated successfully.');
			device.name = name;
			
      onClose && onClose()
		} catch (error) {
			cogoToast.error(error.data.message || error.data);

		} finally {
			setLoading(false)
		}
  }

  return (
    <Container>
      <InnerContainer onSubmit={handleSubmit}>
        <Header>
          <EditIcon />
          <h1>Edit This Device</h1>
        </Header>
        <Description>Please enter the new name of this device then submit.</Description>
        <FormController>
          <label>Device Name</label>
          <Input
            placeholder='name'
            defaultValue={name}
            required
            onChange={(event) => setName(event.target.value)}
          />
        </FormController>
        <FormController>
          <label>Device Type</label>
          <Select
            options={deviceTypes}
            defaultValue={deviceType}
            onChange={setDeviceType}
          />
        </FormController>
        <Button color='darkBlue' isLoading={loading}>Submit</Button>
      </InnerContainer>
    </Container>
  )
}
