import React, { useState, useCallback } from 'react'
import cogoToast from 'cogo-toast'
import _ from 'lodash'
import utils from '../../../utils';
import { NewProjectIcon } from '../../Shared/SvgIcons'
import { Button, Input } from '../../Shared'
import {
  Container,
  InnerContainer,
  Header,
  Description,
  FormController
} from './styles'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import { getUserByEmail, createProject } from '../../../services';

export const AddProject = (props) => {
  const {
    onClose,
    onAdded,
  } = props

  const [loading, setLoading] = useState(false)
  const [searching, setSearching] = useState(true)
  const [userFound, setUserFound] = useState(false)
  const [projectName, setProjectName] = useState('')
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] =  useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [bio, setBio] = useState('')


  const getUserFromEmail = async (email) => {
		try {
      setSearching(true);
      setUserFound(false);
			
      const user = await getUserByEmail(email);
			setFirstName(user.UserAttributes.find(attr => attr.Name === 'given_name').Value)
      setLastName(user.UserAttributes.find(attr => attr.Name === 'family_name').Value)
      setPhoneNumber(user.UserAttributes.find(attr => attr.Name === 'phone_number').Value)
      setBio(user.UserAttributes.find(attr => attr.Name === 'custom:bio').Value)
      setUserFound(true);

		} catch (error) {
      setFirstName('')
      setLastName('')
      setPhoneNumber('')
      setBio('')
		} finally {
      setSearching(false);
		}
	}

  const debouncedGetUserByEmail = useCallback(_.debounce(getUserFromEmail, 300), []);

  const handleChangeEmail = (event) => {
    const email = event.target.value;
    setEmail(email);

    if (utils.validateEmail(email)) {
      debouncedGetUserByEmail(email)
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (loading) return;
    if (!utils.validateEmail(email)) {
			return cogoToast.error('Enter valid email.');
		}

    try {
      setLoading(true);
      
			await createProject({
				project_name: projectName,
				admin: {
					email: email,
          first_name: firstName,
          last_name: lastName,
          phone_number: phoneNumber,
          bio: bio
				}
			});

      onAdded && onAdded();
			cogoToast.success('Project was created successfully.')

			this.getProjects();

    } catch (error) {
      cogoToast.error(error.data.message || error.data);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      <InnerContainer onSubmit={handleSubmit}>
        <Header>
          <NewProjectIcon />
          <h1>Add New Project</h1>
        </Header>
        <Description>Please fill out the project name and the admin details then submit.</Description>
        <FormController>
          <label>Project Name</label>
          <Input
            type="text"
            value={projectName}
            required
            onChange={(event) => setProjectName(event.target.value)} 
          />
        </FormController>
        <FormController>
          <label>Admin Email</label>
          <Input
            type="email"
            value={email}
            required
            onChange={handleChangeEmail}
          />
        </FormController>

        {!searching &&
        <>
          <FormController>
            <label>First Name</label>
            <Input
              type="text"
              disabled={userFound}
              value={firstName}
              required
              onChange={(event) => setFirstName(event.target.value)}
            />
          </FormController>

          <FormController>
            <label>Last Name</label>
            <Input
              type="text"
              disabled={userFound}
              value={lastName}
              required
              onChange={(event) => setLastName(event.target.value)}
            />
          </FormController>

          <FormController>
            <label>Phone Number</label>
            <PhoneInput
              placeholder="Enter phone number"
              value={phoneNumber}
              disabled={userFound}
              required
              onChange={setPhoneNumber}
            />
          </FormController>

          <FormController>
            <label>Bio</label>
            <Input
              type="text"
              disabled={userFound}
              value={bio}
              onChange={(event) => setBio(event.target.value)}
            />
          </FormController>
       
          <Button color='darkBlue' isLoading={loading}>Submit</Button>
        </>
        }
      </InnerContainer>
    </Container>
  )
}
