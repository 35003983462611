import React, { useState } from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { Modal } from '../../Shared/Modal'
import { UserIcon, EmailIcon, ClockIcon, EditIcon, EditProjectIcon, DeleteProjectIcon } from '../../Shared/SvgIcons'
import { DeleteProject } from '../DeleteProject'
import { EditProject } from '../EditProject'
import { SetAdmin } from '../SetAdmin'
import Skeleton from 'react-loading-skeleton'
import {
  Container,
  Header,
  ProjectInfoItem,
  SetAdminButton,
  ProjectItemContainer,
  ActionContainer,
  EditProjectButton,
  DeleteProjectButton
} from './styles'
import { useSession } from '../../../contexts/SessionContext'

export const ProjectDetail = (props) => {
  const [{ authInfo }] = useSession()
  const { isSkeleton, project, no } = props
  const navigate = useNavigate()

  const [isDelete, setIsDelete] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isSetAdmin, setIsSetAdmin] = useState(false)

  const handleGoToDetail = (e) => {
    if (e.target.closest('.admin') || e.target.closest('.edit') || e.target.closest('.delete')) return
    navigate(`/projects/${project.id}`)
  }

  return (
    <>
      <Container onClick={handleGoToDetail}>
        <Header>
          {isSkeleton ? (
            <>
              <Skeleton width={120} height={25} />
              <Skeleton width={35} height={25} />
            </>
          ) : (
            <>
              <p className='name'>{project.name}</p>
              <p className='number'>{String(no).padStart(2, '0')}</p>
            </>
          )}
        </Header>
        <ProjectItemContainer>
          <ProjectInfoItem>
            {isSkeleton ? <Skeleton width={18} height={18} /> : <UserIcon />}
            <div>
              <label>{isSkeleton ? <Skeleton width={60} /> : 'Admin Name'}</label>
              <p>
                {isSkeleton ? 
                  <Skeleton width={100} /> : 
                  project?.admin_user?.Attributes.find(attr => attr.Name === 'given_name').Value} {project?.admin_user?.Attributes.find(attr => attr.Name === 'family_name').Value
                }
              </p>
            </div>
          </ProjectInfoItem>
          {authInfo.user['custom:role'] === 'admin' && 
            <SetAdminButton onClick={() => !isSkeleton && setIsSetAdmin(true)} className='admin'>
              {isSkeleton ? <Skeleton width={18} height={18} style={{ marginRight: '5px'}} /> : <EditIcon />}
              {isSkeleton ? <Skeleton width={80} /> : <span>Set Admin</span>}
            </SetAdminButton>
          }
        </ProjectItemContainer>
        <ProjectInfoItem>
          {isSkeleton ? <Skeleton width={18} height={18} /> : <EmailIcon />}
          <div>
            <label>{isSkeleton ? <Skeleton width={60} /> : 'Admin Email'}</label>
            <p>
              {isSkeleton ? 
                <Skeleton width={100} /> : 
                project?.admin_user?.Attributes.find(attr => attr.Name === 'email').Value
              }
            </p>
          </div>
        </ProjectInfoItem>
        <ProjectInfoItem>
          {isSkeleton ? <Skeleton width={18} height={18} /> : <ClockIcon />}
          <div>
            <label>{isSkeleton ? <Skeleton width={60} /> : 'Creation Time'}</label>
            <p>
              {isSkeleton ? 
                <Skeleton width={100} /> : 
                moment(project?.created_at).format('YYYY-MM-DD HH:mm')
              }
            </p>
          </div>
        </ProjectInfoItem>
        {/* {authInfo.user['custom:role'] === 'admin' &&
        <ActionContainer>
          <EditProjectButton onClick={() => setIsEdit(true)} className='edit'>
            {isSkeleton ? <Skeleton width={24} height={24} /> : <EditProjectIcon />}
            {isSkeleton ? <Skeleton width={60} style={{ marginLeft: '10px'}} /> : <span className='title'>Edit</span>}
          </EditProjectButton>
          <DeleteProjectButton onClick={() => setIsDelete(true)} className='delete'>
            {isSkeleton ? <Skeleton width={24} height={24} /> : <DeleteProjectIcon />}
            {isSkeleton ? <Skeleton width={60} style={{ marginLeft: '10px'}} /> : <span className='title'>Delete</span>}
          </DeleteProjectButton>
        </ActionContainer>
        } */}
      </Container>
      {isDelete && (
        <Modal
          onClose={() => setIsDelete(false)}
          isSecundry
        >
          <DeleteProject
            onClose={() => setIsDelete(false)}
          />
        </Modal>
      )}
      {isEdit && (
        <Modal
          onClose={() => setIsEdit(false)}
        >
          <EditProject
            onClose={() => setIsEdit(false)}
          />
        </Modal>
      )}

      { isSetAdmin && (
        <Modal
          onClose={() => setIsSetAdmin(false)}
        >
          <SetAdmin
            project={project}
            onClose={() => setIsSetAdmin(false)}
          />
        </Modal>
      )}
    </>
  )
}