import React, { createContext, useContext, useEffect, useState } from 'react';
import { signIn } from '../../services/auth';

/**
 * Create SessionContext
 * This context will manage the session internally and provide an easy interface
 */
export const SessionContext = createContext()

/**
 * Custom provider to session manager
 * This provider has a reducer for manage session state
 * @param {props} props
 */
export const SessionProvider = ({ children }) => {
  const [state, setState] = useState({
    authInfo: null,
    token: null,
    user: null,
    loading: true
  })

  const setValuesFromLocalStorage = async () => {
    const { authInfo } = await getValuesFromLocalStorage()
    setState({
      ...state,
      authInfo,
      loading: false
    })
  }

  const getValuesFromLocalStorage = async () => {
    let authInfo = await localStorage.getItem('authInfo');
    if (authInfo) {
      authInfo = JSON.parse(authInfo);
    }

    return { authInfo }
  }

  const updateUser = async (profile) => {
    const newAuthInfo = {
      ...state.authInfo,
      user: {
        ...state.authInfo.user,
        given_name: profile.first_name,
        family_name: profile.last_name,
        phone_number: profile.phone_number,
        'custom:bio': profile.bio,
        'custom:pushover': profile.pushover,
        'custom:image': profile.picture
      }
    }
    await localStorage.setItem('authInfo', JSON.stringify(newAuthInfo));
    setState({
      ...state,
      authInfo: newAuthInfo
    })
  }

  const login = async ({ email, password }) => {
    const authInfo = await signIn({ email, password });
    await localStorage.setItem('authInfo', JSON.stringify(authInfo));
    
    setState({
      ...state,
      authInfo: authInfo,
    })
  }

  const logout = async () => {
    await localStorage.removeItem('authInfo')
    await localStorage.removeItem('user')
    setState({
      ...state,
      authInfo: false,
      user: null,
      token: null,
      loading: false
    })
  }

  const changeUser = async (user) => {
    await localStorage.setItem('user', user, true)
    setState({
      ...state,
      user,
      loading: false
    })
  }

  
  useEffect(() => {
    const checkLocalStorage = async () => {
      const { authInfo } = await getValuesFromLocalStorage()
      if (!authInfo) {
        logout();
      }
  
      
    }
    const interval = setInterval(() => {
      checkLocalStorage()
    }, 1000)
    return () => clearInterval(interval)
  }, [state])

  useEffect(() => {
    setValuesFromLocalStorage()
  }, [])

  const functions = {
    login,
    logout,
    updateUser,
    changeUser
  }

  return (
    <SessionContext.Provider value={[state, functions]}>
      {children}
    </SessionContext.Provider>
  )
}

/**
 * Hook to get and update session state
 */
export const useSession = () => {
  const sessionManager = useContext(SessionContext)
  return sessionManager || [{}, () => {}]
}
