import React, { useState } from 'react'
import { EditIcon, DeleteIcon, DeviceIcon } from '../Shared/SvgIcons'
import Skeleton from 'react-loading-skeleton'
import {
  Container,
  Header,
  InfoItem,
  ActionContainer,
  EditProjectButton,
  DeleteProjectButton,
} from './styles'
import { EditDeviceType } from '../DeviceTypeList/EditDeviceType'
import { Modal } from '../Shared/Modal'
import { DeleteDeviceType } from '../DeviceTypeList/DeleteDeviceType'
import { useSession } from '../../contexts/SessionContext'

export const DeviceTypeDetail = (props) => {
  const [{ authInfo }] = useSession()
  const { isSkeleton, deviceType, no, onEdited, onDeleted } = props

  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  return (
    <>
      <Container>
        <Header>
          {isSkeleton ? (
            <>
              <Skeleton width={120} height={25} />
              <Skeleton width={35} height={25} />
            </>
          ) : (
            <>
              <p className='name'>{deviceType.name}</p>
              <p className='number'>{String(no).padStart(2, '0')}</p>
            </>
          )}
        </Header>
        <InfoItem>
          {isSkeleton ? <Skeleton width={18} height={18} /> : <DeviceIcon />}
          <div>
            <label>{isSkeleton ? <Skeleton width={60} /> : 'Associated Devices Number'}</label>
            <p>{isSkeleton ? <Skeleton width={100} /> : `${deviceType.device_count || 0} devices`}</p>
          </div>
        </InfoItem>
        {authInfo.user['custom:role'] === 'admin' &&
        <ActionContainer>
          <EditProjectButton onClick={() => console.log('rename')}>
            {isSkeleton ? <Skeleton width={24} height={24} /> : <EditIcon />}
            {isSkeleton ? <Skeleton width={60} style={{ marginLeft: '10px'}} /> : <span className='title' onClick={() => setIsEdit(true)}>Edit</span>}
          </EditProjectButton>
          <DeleteProjectButton onClick={() => console.log('delete')}>
            {isSkeleton ? <Skeleton width={24} height={24} /> : <DeleteIcon />}
            {isSkeleton ? <Skeleton width={60} style={{ marginLeft: '10px'}} /> : <span className='title' onClick={() => setIsDelete(true)}>Delete</span>}
          </DeleteProjectButton>
        </ActionContainer>
        }
      </Container>

      {isEdit && (
        <Modal
          onClose={() => setIsEdit(false)}
        >
          <EditDeviceType
            deviceType={deviceType}
            onClose={() => setIsEdit(false)}
            onEdited={(name, configs) => {
              setIsEdit(false)
              onEdited && onEdited(name, configs)
            }}
          />
        </Modal>
      )}

      {isDelete && (
        <Modal
          onClose={() => setIsDelete(false)}
        >
          <DeleteDeviceType
            deviceType={deviceType}
            onDeleted={() => {
              onDeleted && onDeleted()
              setIsDelete(false)
            }}
            onClose={() => setIsDelete(false)}
          />
        </Modal>
      )}
    </>
  )
}