import React, { useEffect, useState, useMemo, useCallback } from 'react'
import cogoToast from 'cogo-toast'
import { SectionBar, Button, Pagination } from '../Shared'
import { NewUserIcon } from '../Shared/SvgIcons'
import { FilterPopover } from './FilterPopover'
import { SortPopover } from './SortPopover'
import { UserDetail } from './UserDetail'

import {
  Container,
  InnerContainer,
  HeaderContainer,
  TotalProjectsCount,
  DevicessList,
  PaginationWrapper
} from './styles'
import { getUsers } from '../../services'

export const UsersList = () => {
  const nShowUsers = 9;
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [pagination, setPagination] = useState({ currentPage: 1, totalPages: 2 })
  const [isLoading, setIsLoading] = useState(true)

  // Sort
  const [isAscending, setIsAscending] = useState(true);
  const [isSortDate, setIsSortDate] = useState(true);

  // Filter
  const [filterName, setFilterName] = useState('');
  const [filterEmail, setFilterEmail] = useState('');
  const [filterPhoneNumber, setFilterPhoneNumber] = useState('');
  const [filterBio, setFilterBio] = useState('');

  const getUserList = async () => {
    setUsers([]);

		try {
      setIsLoading(true);
			const _users = await getUsers();
      setUsers(_users);
      setPagination({
        currentPage: 1,
        totalPages: Math.ceil(_users.length / nShowUsers)
      });

		} catch (error) {
			cogoToast.error(error.data.message || error.data);

		} finally {
			setIsLoading(false);
		}
  };

  const handleChangePage = (page) => {
    setPagination({
      ...pagination,
      currentPage: page
    })
  };

  const usersToShow = useMemo(() => {
    const usersToShow = [];

    let sortedUsers = [];
    if (isSortDate) {
      sortedUsers = filteredUsers.sort((a, b) => {
        if (isAscending) {
          return a.UserCreateDate.localeCompare(b.UserCreateDate);
        } else {
          return b.UserCreateDate.localeCompare(a.UserCreateDate);
        }
      })
    } else {
      sortedUsers = filteredUsers.sort((a, b) => {
        const name1 = `${a.Attributes.find(attr => attr.Name === 'given_name').Value} ${a.Attributes.find(attr => attr.Name === 'family_name').Value}`;
        const name2 = `${b.Attributes.find(attr => attr.Name === 'given_name').Value} ${b.Attributes.find(attr => attr.Name === 'family_name').Value}`;

        if (isAscending) {
          return name1.localeCompare(name2);
        } else {
          return name2.localeCompare(name1);
        }
      })
    }

    if (pagination.currentPage === 0) return [];

    const startIndex = (pagination.currentPage - 1) * nShowUsers;
    const endIndex = Math.min(sortedUsers.length, startIndex + nShowUsers);
    for (let i = startIndex; i < endIndex; i++) {
      usersToShow.push(sortedUsers[i]);
    }

    return usersToShow;
  }, [filteredUsers, pagination, isAscending, isSortDate]);


  const filterUsers = useCallback(() => {
    let _filteredUsers = [];
    _filteredUsers = users.filter(user => {
      const name = `${user.Attributes.find(attr => attr.Name === 'given_name').Value} ${user.Attributes.find(attr => attr.Name === 'family_name').Value}`;
      return name.toLowerCase().includes(filterName.trim().toLowerCase())
    })
    _filteredUsers = _filteredUsers.filter(user => {
      const email = user.Attributes.find(attr => attr.Name === 'email').Value;
      return email.toLowerCase().includes(filterEmail.trim().toLowerCase())
    })
    _filteredUsers = _filteredUsers.filter(user => {
      const phoneNumber = user.Attributes.find(attr => attr.Name === 'phone_number').Value
      return phoneNumber.toLowerCase().includes(filterPhoneNumber.trim().toLowerCase())
    })
    _filteredUsers = _filteredUsers.filter(user => {
      const bio = user.Attributes.find(attr => attr.Name === 'custom:bio')?.Value || ''
      return bio.toLowerCase().includes(filterBio.trim().toLowerCase())
    })
    
    setFilteredUsers(_filteredUsers);
    
    if (_filteredUsers.length > 0) {
      setPagination({
        currentPage: 1,
        totalPages: Math.ceil(_filteredUsers.length / nShowUsers)
      });
    } else {
      setPagination({
        currentPage: 0,
        totalPages: Math.ceil(_filteredUsers.length / nShowUsers)
      });
    }
  }, [
    filterName,
    filterEmail,
    filterPhoneNumber,
    filterBio, 
    users
  ]);


  useEffect(() => {
    filterUsers();
  }, [
    filterName,
    filterEmail,
    filterPhoneNumber,
    filterBio,
    users,
    filterUsers
  ]);

  useEffect(() => {
    getUserList();
  }, []);

  return (
    <Container>
      <InnerContainer>
        <SectionBar />
        <HeaderContainer>
          <div className='title'>
            <h1>All Users</h1>
            <TotalProjectsCount>You have {users.length} users.</TotalProjectsCount>
          </div>
          <div className='action'>
            <FilterPopover 
              filterName={filterName}
              filterEmail={filterEmail}
              filterPhoneNumber={filterPhoneNumber}
              filterBio={filterBio}
              setFilterName={setFilterName}
              setFilterEmail={setFilterEmail}
              setFilterPhoneNumber={setFilterPhoneNumber}
              setFilterBio={setFilterBio}
            />

            <SortPopover
              isSortDate={isSortDate}
              isAscending={isAscending}
              setIsAscending={setIsAscending}
              setIsSortDate={setIsSortDate}
            />

            <Button
              color='primary'
            >
              <NewUserIcon />
              <span>New User</span>
            </Button>
          </div>
        </HeaderContainer>
        {isLoading ? (
          <DevicessList>
            {[...Array(6).keys()].map(i => (
              <UserDetail key={i} isSkeleton />
            ))}
          </DevicessList>
        ) : (
          <DevicessList>
            {usersToShow.map((user, key) => (
              <UserDetail user={user} key={key}
                no={ (pagination.currentPage - 1) * nShowUsers + key + 1 } 
              />
            ))}
          </DevicessList>
        )}

        { usersToShow.length === 0 ?
          <h3 style={{textAlign:'center', margin: 30}}>No Users</h3>
        :
          <PaginationWrapper>
            <Pagination
              currentPage={pagination.currentPage}
              totalPages={pagination.totalPages}
              handleChangePage={handleChangePage}
            />
          </PaginationWrapper>
        }
      </InnerContainer>
    </Container>
  )
}
