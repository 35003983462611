import React, { useState, useCallback } from 'react'
import cogoToast from 'cogo-toast'
import { NewProjectIcon } from '../../Shared/SvgIcons'
import { Button, Input } from '../../Shared'
import {
  Container,
  InnerContainer,
  Header,
  Description,
  FormController
} from './styles'

import { updateDeviceType } from '../../../services/device-type';

export const EditDeviceType = (props) => {
  const { onEdited, deviceType } = props

  const [loading, setLoading] = useState(false)
  const [name, setName] = useState(deviceType.name)
  const [configs, setConfigs] = useState(deviceType.configs)
  
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (loading) return;

    try {
      setLoading(true);
      await updateDeviceType(deviceType.id, name, configs);

      onEdited && onEdited(name, configs);
			cogoToast.success('Device type was updated successfully.')

    } catch (error) {
      cogoToast.error(error.data.message || error.data);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      <InnerContainer onSubmit={handleSubmit}>
        <Header>
          <NewProjectIcon />
          <h1>Edit Device Type</h1>
        </Header>
        <Description>Please fill out the device type name and the messages then submit.</Description>
        <FormController>
          <label>Device Type Name</label>
          <Input
            type="text"
            value={name}
            required
            onChange={(event) => setName(event.target.value)}
          />
        </FormController>

        {
          [...Array(11).keys()].map(i => 
          <FormController key={i}>
            <label>Message (alarm type {i + 10})</label>
            <Input
              type="text"
              value={configs[i]}
              onChange={(event) => {
                const _configs = [...configs];
                _configs[i] = event.target.value;
                setConfigs(_configs);
              }}
            />
          </FormController>)
        }

        <Button color='darkBlue' isLoading={loading}>Submit</Button>
      </InnerContainer>
    </Container>
  )
}
