import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 172px;
`

export const InnerContainer = styled.div`
  max-width: 1440px;
  margin: 0px auto;
  padding: 0px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column-reverse;
  > div {
    width: 100%;
  }

  @media (min-width: 576px) {
    padding: 0px 20px;
  }

  @media (min-width: 769px) {
    padding: 0px 30px;
    flex-direction: row;
    > div {
      width: 50%;
    }
  }

  @media (min-width: 993px) {
    padding: 0px 40px;
  }

  @media (min-width: 1024px) {
    padding: 0px 60px;
  }
  
  @media (min-width: 1500px) {
    padding: 0px 30px;
  }
`

export const LeftContainer = styled.div`
  padding-right: 0px;
  margin-top: 25px;

  @media (min-width: 769px) {
    padding-right: 20px;
    margin-top: 0px;
  }

  @media (min-width: 1200px) {
    padding-right: 40px;
  }
`

export const ImageWrapper = styled.div`
  img {
    width: 100%;
  }
`

export const RightContainer = styled.div`
  padding-left: 0px;
  > h1 {
    font-weight: 600;
    font-size: 32px;
    line-height: 130%;
    margin-bottom: 25px;
    color: ${props => props.theme.colors.black};
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    margin-bottom: 16px;
  }

  @media (min-width: 769px) {
    padding-left: 20px;
    h1 {
      margin-bottom: 26px;
      font-size: 38px;
    }
    p {
      margin-bottom: 14px;
    }
  }

  @media (min-width: 993px) {
    h1 {
      margin-bottom: 28px;
      font-size: 45px;
    }
    p {
      margin-bottom: 14px;
    }
  }

  @media (min-width: 1200px) {
    padding-left: 40px;
    h1 {
      margin-bottom: 32px;
      font-size: 56px;
    }
    p {
      margin-bottom: 16px;
    }
  }
`
