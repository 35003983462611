import React, { useEffect, useState, useMemo, useCallback } from 'react'
import cogoToast from 'cogo-toast'
import { SectionBar, Button, Pagination } from '../Shared'
import { DeviceIcon } from '../Shared/SvgIcons'
import { FilterPopover } from './FilterPopover'
import { SortPopover } from './SortPopover'
import { DeviceTypeDetail } from '../DeviceTypeDetail'
import {
  Container,
  InnerContainer,
  HeaderContainer,
  TotalProjectsCount,
  DevicessList,
  PaginationWrapper
} from './styles'
import { Modal } from '../Shared/Modal'
import { AddDeviceType } from './AddDeviceType'
import { getDeviceTypes } from '../../services/device-type'
import { useSession } from '../../contexts/SessionContext'

export const DeviceTypeList = () => {
  const [{ authInfo }] = useSession()
  const nShowDeviceTypes = 9;
  const [deviceTypes, setDeviceTypes] = useState([]);
  const [filteredDeviceTypes, setFilteredDeviceTypes] = useState([]);
  const [pagination, setPagination] = useState({ currentPage: 0, totalPages: 0 })
  const [isLoading, setIsLoading] = useState(true)
  const [isAdd, setIsAdd] = useState(false);

  // Sort
  const [isAscending, setIsAscending] = useState(true);

  // Filter
  const [filterName, setFilterName] = useState('');

  const getDeviceTypeList = async () => {
    setDeviceTypes([]);

		try {
      setIsLoading(true);
			const _deviceTypes = await getDeviceTypes();
      setDeviceTypes(_deviceTypes);
      setPagination({
        currentPage: 1,
        totalPages: Math.ceil(_deviceTypes.length / nShowDeviceTypes)
      });

		} catch (error) {
			cogoToast.error(error.data.message || error.data);

		} finally {
			setIsLoading(false);
		}
  };

  const handleChangePage = (page) => {
    setPagination({
      ...pagination,
      currentPage: page
    })
  };

  const deviceTypesToShow = useMemo(() => {
    const deviceTypesToShow = [];

    let sortedDeviceTypes = [];
    sortedDeviceTypes = filteredDeviceTypes.sort((a, b) => {
      if (isAscending) {
        return a.name.localeCompare(b.name);
      } else {
        return b.name.localeCompare(a.name);
      }
    });

    if (pagination.currentPage === 0) return [];

    const startIndex = (pagination.currentPage - 1) * nShowDeviceTypes;
    const endIndex = Math.min(sortedDeviceTypes.length, startIndex + nShowDeviceTypes);
    for (let i = startIndex; i < endIndex; i++) {
      deviceTypesToShow.push(sortedDeviceTypes[i]);
    }

    return deviceTypesToShow;
  }, [filteredDeviceTypes, pagination, isAscending]);


  const filterDevices = useCallback(() => {
    let _filteredDevices = [];
    _filteredDevices = deviceTypes.filter(deviceType => deviceType.name.toLowerCase().includes(filterName.trim().toLowerCase()))
    
    setFilteredDeviceTypes(_filteredDevices);
    
    if (_filteredDevices.length > 0) {
      setPagination({
        currentPage: 1,
        totalPages: Math.ceil(_filteredDevices.length / nShowDeviceTypes)
      });
    } else {
      setPagination({
        currentPage: 0,
        totalPages: Math.ceil(_filteredDevices.length / nShowDeviceTypes)
      });
    }
  }, [
    filterName,
    deviceTypes
  ]);


  useEffect(() => {
    filterDevices();
  }, [
    filterName,
    deviceTypes,
    filterDevices
  ]);

  useEffect(() => {
    getDeviceTypeList();
  }, []);

  return (
    <Container>
      <InnerContainer>
        <SectionBar />
        <HeaderContainer>
          <div className='title'>
            <h1>All Device Types</h1>
            <TotalProjectsCount>You have {deviceTypes.length} device types.</TotalProjectsCount>
          </div>
          <div className='action'>
            <FilterPopover 
              filterName={filterName}
              setFilterName={setFilterName}
            />

            <SortPopover
              isAscending={isAscending}
              setIsAscending={setIsAscending}
            />

            {authInfo.user['custom:role'] === 'admin' &&
              <Button
                color='primary'
                onClick={() => setIsAdd(true)}
              >
                <DeviceIcon />
                <span>New Device Type</span>
              </Button>
            }
          </div>
        </HeaderContainer>
        {isLoading ? (
          <DevicessList>
            {[...Array(6).keys()].map(i => (
              <DeviceTypeDetail key={i} isSkeleton />
            ))}
          </DevicessList>
        ) : (
          <DevicessList>
            {deviceTypesToShow.map((deviceType, key) => (
              <DeviceTypeDetail deviceType={deviceType} key={key}
                no={ (pagination.currentPage - 1) * nShowDeviceTypes + key + 1 }
                onEdited={(name, configs) => {
                  deviceType.name = name;
                  deviceType.configs = configs;
                  setDeviceTypes([...deviceTypes]);
                }}
                onDeleted={getDeviceTypeList} 
              />
            ))}
          </DevicessList>
        )}

        {deviceTypesToShow.length === 0 ?
          <h3 style={{textAlign:'center', margin: 30}}>No Device types</h3>
        :
          <PaginationWrapper>
            <Pagination
              currentPage={pagination.currentPage}
              totalPages={pagination.totalPages}
              handleChangePage={handleChangePage}
            />
          </PaginationWrapper>
        }
      </InnerContainer>

      {isAdd && (
        <Modal
          onClose={() => setIsAdd(false)}
        >
          <AddDeviceType
            onClose={() => setIsAdd(false)}
            onAdded={() => {
              setIsAdd(false)
              getDeviceTypeList();
            }}
          />
        </Modal>
      )}
    </Container>
  )
}
