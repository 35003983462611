import React from 'react'
import { UsersList as UsersListController } from '../../components/UsersList'

export const UsersList = (props) => {
  return (
    <>
      <UsersListController {...props} />
    </>
  )
}
