import axios from 'axios';

export const getLocation = (locationId) => {
  return axios.get(`${process.env.REACT_APP_SERVER_URL}/locations/${locationId}`)
    .then(data => data.data)
    .catch(error => { throw error.response });
};

export const getLocations = (projectId) => {
  return axios.get(`${process.env.REACT_APP_SERVER_URL}/projects/${projectId}/groups`)
    .then(data => data.data)
    .catch(error => { throw error.response });
};

export const createLocation = (project_id, name) => {
  return axios.post(`${process.env.REACT_APP_SERVER_URL}/projects/${project_id}/groups`, { name })
    .then(data => data.data)
    .catch(error => { throw error.response });
};

export const deleteLocation = (projectId, groupId) => {
  return axios.delete(`${process.env.REACT_APP_SERVER_URL}/projects/${projectId}/groups/${groupId}`)
    .then(data => data.data)
    .catch(error => { throw error.response });
};

export const renameLocation = (projectId, groupId, name) => {
  return axios.put(`${process.env.REACT_APP_SERVER_URL}/projects/${projectId}/groups/${groupId}`, { name })
    .then(data => data.data)
    .catch(error => { throw error.response });
};

export const getLocationUsers = (locationId) => {
	return axios.get(`${process.env.REACT_APP_SERVER_URL}/locations/${locationId}/users`)
		.then(data => data.data)
		.catch(error => { throw error.response });
};

export const addUserToLocation = (locationId, userId, alarmTypes) => {
	return axios.post(`${process.env.REACT_APP_SERVER_URL}/locations/${locationId}/users`, {
    user_id: userId,
    alarm_types: alarmTypes
  })
		.then(data => data.data)
		.catch(error => { throw error.response });
};

export const deleteUserFromLocation = (locationId, userId) => {
	return axios.delete(`${process.env.REACT_APP_SERVER_URL}/locations/${locationId}/users/${userId}`)
		.then(data => data.data)
		.catch(error => { throw error.response });
};

export const setAlarmTypesForUser = (locationId, userId, alarmTypes) => {
  return axios.put(`${process.env.REACT_APP_SERVER_URL}/locations/${locationId}/users/${userId}`, {
    alarm_types: alarmTypes
  })
    .then(data => data.data)
    .catch(error => { throw error.response });
};