import styled, { css } from 'styled-components'

export const Container = styled.div`
  position: relative;
`

export const Selected = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 16px;
  padding: 13px 15px;
  background-color: ${props => props.theme.colors.white};
  border-radius: 8px;
  span {
    color: ${props => props.theme.colors.black};
  }
  img {
    width: 14px;
    margin-left: 8px;
  }

  @media (min-width: 768px) {
    font-size: 18px;
  }

  ${({ noValue }) => noValue && css`
    span {
      color: #E3E3E3;
    }
  `}

  ${({ isLeftIcon }) => isLeftIcon && css`
    span {
      margin-left: 10px;
    }
  `}
  ${({ noBg }) => noBg && css`
    background-color: transparent;
  `}
`

export const OptionList = styled.div`
  position: absolute;
  box-shadow: 0px 6px 30px rgb(235 238 243);
  left: 0px;
  width: 100%;
  max-height: 250px;
  overflow: auto;
  border-radius: 8px;
  top: 100%;
  margin-top: 5px;
  z-index: 10;
`

export const Option = styled.div`
  padding: 7px 15px;
  color: ${props => props.theme.colors.black};
  background-color: ${props => props.theme.colors.white};
  transition: all 0.3s linear;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.backgroundActive};
  }
  ${({ selected }) => selected && css`
    background-color: ${props => props.theme.colors.primaryBoxBg};
  `}
`
