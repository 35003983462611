import React, { useState } from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { Modal } from '../../Shared'
import { EllipseIcon, ProjectIcon, GroupIcon, ChatIcon, PriorityIcon, StatusIcon, ClockIcon, EditIcon, DeleteIcon, OfflineIcon } from '../../Shared/SvgIcons'
import { DeleteDevice } from '../DeleteDevice'
import { RenameDevice } from '../RenameDevice'
import Skeleton from 'react-loading-skeleton'
import {
  Container,
  Header,
  InfoItem,
  ActionContainer,
  EditProjectButton,
  DeleteProjectButton
} from './styles'

export const DeviceDetail = (props) => {
  const { isSkeleton, device, no, onDeleted, isProjectHide, canManage, deviceTypes, project_id } = props
  const navigate = useNavigate()

  const [isRename, setIsRename] = useState(false)
  const [isDelete, setIsDelete] = useState()

  const handleGoToDetail = (e) => {
    if (e.target.closest('.edit') || e.target.closest('.delete')) return

    if (project_id) {
      navigate(`/projects/${project_id}/devices/${device.reg_id}`)
    } else {
      navigate(`/devices/${device.reg_id}`)
    }
  }


  return (
    <>
      <Container onClick={handleGoToDetail}>
        <Header>
          {isSkeleton ? (
            <>
              <Skeleton width={120} height={25} />
              <Skeleton width={35} height={25} />
            </>
          ) : (
            <>
              <p className='name'>{device.name}</p>
              <p className='number'>{String(no).padStart(2, '0')}</p>
            </>
          )}
        </Header>
        <InfoItem>
          {isSkeleton ? <Skeleton width={18} height={18} /> : (device.presence === 'online' ? <EllipseIcon /> : <OfflineIcon/>)}
          <div>
            <label>{isSkeleton ? <Skeleton width={60} /> : 'Reg ID'}</label>
            <p>{isSkeleton ? <Skeleton width={100} /> : device.reg_id}</p>
          </div>
        </InfoItem>
        { !isProjectHide &&
        <InfoItem>
          {isSkeleton ? <Skeleton width={18} height={18} /> : <ProjectIcon />}
          <div>
            <label>{isSkeleton ? <Skeleton width={60} /> : 'Project Name'}</label>
            <p>{isSkeleton ? <Skeleton width={100} /> : device.project?.name}</p>
          </div>
        </InfoItem>
        }
        <InfoItem>
          {isSkeleton ? <Skeleton width={18} height={18} /> : <GroupIcon />}
          <div>
            <label>{isSkeleton ? <Skeleton width={60} /> : 'Location'}</label>
            <p>{isSkeleton ? <Skeleton width={100} /> : device.group?.name}</p>
          </div>
        </InfoItem>
        <InfoItem>
          {isSkeleton ? <Skeleton width={18} height={18} /> : <ChatIcon />}
          <div>
            <label>{isSkeleton ? <Skeleton width={60} /> : 'Message'}</label>
            <p>{isSkeleton ? <Skeleton width={100} /> : device.message}&nbsp;</p>
          </div>
        </InfoItem>
        <InfoItem>
          {isSkeleton ? <Skeleton width={18} height={18} /> : <PriorityIcon />}
          <div>
            <label>{isSkeleton ? <Skeleton width={60} /> : 'Priority'}</label>
            <p>{isSkeleton ? <Skeleton width={100} /> : device.priority}&nbsp;</p>
          </div>
        </InfoItem>
        <InfoItem>
          {isSkeleton ? <Skeleton width={18} height={18} /> : <StatusIcon />}
          <div>
            <label>{isSkeleton ? <Skeleton width={60} /> : 'Status'}</label>
            <p>{isSkeleton ? <Skeleton width={100} /> : device.status2}&nbsp;</p>
          </div>
        </InfoItem>
        <InfoItem>
          {isSkeleton ? <Skeleton width={18} height={18} /> : <ClockIcon />}
          <div>
            <label>{isSkeleton ? <Skeleton width={60} /> : 'Received At'}</label>
            <p>{isSkeleton ? <Skeleton width={100} /> : device.msg_received_at ? moment(device.msg_received_at).format('YYYY-MM-DD HH:mm') : ''}&nbsp;</p>
          </div>
        </InfoItem>
        { canManage &&
          <ActionContainer>
            <EditProjectButton onClick={() => setIsRename(true)} className='edit'>
              {isSkeleton ? <Skeleton width={24} height={24} /> : <EditIcon />}
              {isSkeleton ? <Skeleton width={60} style={{ marginLeft: '10px'}} /> : <span className='title'>Edit</span>}
            </EditProjectButton>
            <DeleteProjectButton onClick={() => setIsDelete(true)} className='delete'>
              {isSkeleton ? <Skeleton width={24} height={24} /> : <DeleteIcon />}
              {isSkeleton ? <Skeleton width={60} style={{ marginLeft: '10px'}} /> : <span className='title'>Delete</span>}
            </DeleteProjectButton>
          </ActionContainer>
        }
      </Container>
      {isRename && (
        <Modal
          onClose={() => setIsRename(false)}
        >
          <RenameDevice
            device={device}
            deviceTypes={deviceTypes}
            onClose={() => setIsRename(false)}
          />
        </Modal>
      )}
      {isDelete && (
        <Modal
          onClose={() => setIsDelete(false)}
        >
          <DeleteDevice
            device={device}
            onDeleted={() => {
              onDeleted && onDeleted()
              setIsDelete(false)
            }}
            onClose={() => setIsDelete(false)}
          />
        </Modal>
      )}
    </>
  )
}