import cogoToast from 'cogo-toast'
import React, { useState } from 'react'
import { useTheme } from 'styled-components'
import { deleteUserFromLocation } from '../../../services'
import { Button } from '../../Shared'
import {
  Container,
  Header,
  Description
} from './styles'

export const DeleteUser = (props) => {
  const {
    location_id,
    user,
    onDeleted
  } = props  

  const theme = useTheme()
  const [loading, setLoading] = useState(false)

  const handleDeleteUser = async () => {
    if (loading) return;

    try {
      setLoading(true)
      await deleteUserFromLocation(location_id, user.Attributes.find(attr => attr.Name === 'sub').Value);
			cogoToast.success('User was deleted from the location successfully.');
			onDeleted && onDeleted();
    } catch (error) {
      console.log(error);
      cogoToast.error(error.data.message || error.data);
    } finally {
      setLoading(false)
    }
  }

  return(
    <Container>
      <Header>
        <img src={theme.icons.deleteIcon} alt='deleteIcon' />
        <h1>Are You Sure ?</h1>
      </Header>
      <Description>Please confirm that you want to delete this user from the location.</Description>
      <Button
        color='red'
        isLoading={loading}
        onClick={handleDeleteUser}
      >
        Confirm & Delete
      </Button>
    </Container>
  )
}
