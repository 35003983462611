import React, { useRef, useState } from 'react'
import cogoToast from 'cogo-toast'
import { UploadIcon } from '../../Shared/SvgIcons'
import { Button } from '../../Shared'
import {
  Container,
  InnerContainer,
  Header,
  Description,
  FormController
} from './styles'
import { uploadPackageToS3 } from '../../../services/auth'
import { useSession } from '../../../contexts/SessionContext'


export const UploadPackage = (props) => {
  const { onClose } = props
  const [{ authInfo }] = useSession()
  const inputRef = useRef()
  const fileRef = useRef()
  const [fileName, setFileName] = useState('')
  const [loading, setLoading] = useState(false);
  
  const handleChange = (event) => {
    fileRef.current = event.target.files[0];
    setFileName(event.target.files[0].name)
  };

  const handleUpload = async (event) => {
    event.preventDefault();
    if (!fileName) {
      return cogoToast.error("Please choose the file.");
    }

    try {
      setLoading(true);
      await uploadPackageToS3(authInfo.AuthenticationResult.IdToken, fileRef.current);
      cogoToast.success("Successfully uploaded the package.");
      onClose && onClose();

    } catch(error) {
      cogoToast.error(error.data.message || error.data);

    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <InnerContainer onSubmit={handleUpload}>
        <Header>
          <UploadIcon />
          <h1>Upload Package</h1>
        </Header>
        <Description>Choose package file to upload by clicking “Browse” then click “Upload”</Description>
        <FormController>
          <span>{fileName ? fileName : 'Choose file'}</span>
          <input type='file' name="file" id="file" ref={inputRef} onChange={handleChange} />
          <Button color='darkBlue' onClick={(event) => {
            event.preventDefault();
            inputRef?.current && inputRef.current.click()
          }}>Browse</Button>
        </FormController>
        <Button
          color='darkBlue'
          isLoading={loading}
        >
          Upload
        </Button>
      </InnerContainer>
    </Container>
  )
}
