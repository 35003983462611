import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  background-color: ${props => props.theme.colors.white};
`

export const InnerContainer = styled.div`
  max-width: 1440px;
  margin: 0px auto;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 576px) {
    padding: 15px 20px;
  }

  @media (min-width: 769px) {
    padding: 20px 30px;
  }

  @media (min-width: 993px) {
    padding: 24px 40px;
  }

  @media (min-width: 1024px) {
    padding: 28px 60px;
  }
  
  @media (min-width: 1500px) {
    padding: 28px 30px;
  }
`

export const Logo = styled.img`
  height: 25px;
  object-fit: cover;
  cursor: pointer;
  transition: all 0.2s ease-out;
  &:hover {
    transform: scale(1.04);
  }

  @media (min-width: 576px) {
    height: 33px;
  }
`

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: 15px;
  }
  @media (min-width: 576px) {
    button {
      margin-left: 25px;
    }
  }
`

export const MenuList = styled.div`
  display: flex;
  align-items: center;
`

export const MenuItem = styled.span`
  margin: 0px 16px;
  font-weight: 500;
  font-size: 18px;
  color: ${props => props.theme.colors.black};
  cursor: pointer;
  transition: all 0.3s linear;
  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`

export const MobileMenu = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 32px;
  svg {
    transition: all 0.3s linear;
    &:hover {
      fill: ${props => props.theme.colors.primary};
    }
  }
`

export const MobileMenuWrapper = styled.div`
  & > div {
    padding: 1.8em 1.8em 1.8em 1.8em;
    font-size: 1.15em;
  }
  position: fixed;
  right: inherit;
  z-index: 1100;
  width: 0%;
  height: 100%;
  transition: all 0.5s ease 0s;
  top: 0px;
  left: 0px;
  overflow: auto;
  background: rgb(255, 255, 255);
`

export const MobileMenuItem = styled.div`
  padding: 24px 24px 24px 0px;
  border-bottom: 1px solid ${props => props.theme.colors.gray};
  & > span {
    font-weight: 500 !important;
    font-size: 24px !important;
    margin-top: 24px !important;
    margin-bottom: 24px !important;
    transition: 0.2s;
    cursor: pointer;
    &:hover {
      color: ${props => props.theme.colors.primary}
    }
  }
`

export const OverLay = styled.div`
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  opacity: 1;
  transition: opacity 0.3s ease 0s;
  top: 0px;
  left: 0px;
`
