import styled from 'styled-components'

export const Container = styled.div`
  min-height: 100vh;
`
export const InnerContainer = styled.div`
  max-width: 1440px;
  min-height: inherit;
  margin: 0px auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 576px) {
    padding: 15px 20px;
  }

  @media (min-width: 768px) {
    padding: 20px 30px;
  }

  @media (min-width: 993px) {
    padding: 24px 40px;
  }

  @media (min-width: 1024px) {
    padding: 28px 60px;
  }
  
  @media (min-width: 1500px) {
    padding: 28px 30px;
  }
`
export const LogoWrapper = styled.div`
  margin-bottom: 36px;
  width: 200px;
  img {
    width: 100%;
  }
  @media (min-width: 768px) {
    margin-bottom: 56px;
    width: 260px;
  }
`
export const LoginForm = styled.form`
  background: ${props => props.theme.colors.white};
  padding: 30px 20px;
  box-shadow: 0px 2px 30px rgba(215, 224, 235, 0.5);
  border-radius: 12px;
  width: 100%;

  @media (min-width: 768px) {
    max-width: 500px;
    padding: 48px;
  }
`
export const HeaderTitle = styled.div`
  margin-bottom: 36px;
  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    color: ${props => props.theme.colors.black};
    margin-top: 12px;
  }
  @media (min-width: 768px) {
    margin-bottom: 72px;
    h2 {
      font-size: 28px;
    }
  }
`
export const FormController = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  label {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
  }

  input {
    padding: 10px 15px;
  }

  @media (min-width: 768px) {
    margin-bottom: 40px;
    label {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
`
export const RedirectLink = styled.span`
  color: ${props => props.theme.colors.primary};
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  margin-left: 28px;
  &:hover {
    text-decoration: underline;
  }
`
export const FormBottomContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 36px;

  > button {
    display: flex;
    align-items: center;
    border-radius: 4px;

    svg {
      margin-left: 10px;
      transition: all 0.2s linear;
    }
    &:hover {
      svg {
        transform: translateX(3px);
      }
    }
  }
  @media (min-width: 768px) {
    margin-top: 56px;
    > button {
      height: 55px;
    }
  }
`
