import { useState, useMemo, useCallback, useEffect } from 'react';
import { LocationDetail } from '../LocationDetail';
import { Content } from "../styles";
import { PaginationWrapper } from '../../DeviceList/styles';
import { Pagination } from "../../Shared";
import { Modal } from '../../Shared/Modal'
import { AddLocation } from './AddLocation';

export const LocationList = (props) => {
  const {
    project_id,
    project,
    locationList,
    filterLocationName,
    isAscending,
    isLoading,
    isAddLocation,
    onAdded,
    onDeleted,
    onEdited
  } = props;
  const nShowsLocation = 9;

  const [filteredLocations, setFilteredLocations] = useState([]);
  const [pagination, setPagination] = useState({ currentPage: 1, totalPages: 1 });

  const handleChangePage = (page) => {
    setPagination({
      ...pagination,
      currentPage: page
    })
  };

  const locationsToShow = useMemo(() => {
    const locationsToShow = [];

    let sortedLocations = filteredLocations.sort((a, b) => {
      if (isAscending) {
        return a.name.localeCompare(b.name);
      } else {
        return b.name.localeCompare(a.name);
      }
    })
    if (pagination.currentPage === 0) return [];

    const startIndex = (pagination.currentPage - 1) * nShowsLocation;
    const endIndex = Math.min(sortedLocations.length, startIndex + nShowsLocation);
    for (let i = startIndex; i < endIndex; i++) {
      locationsToShow.push(sortedLocations[i]);
    }

    return locationsToShow;
  }, [filteredLocations, pagination, isAscending]);

  const filterLocations = useCallback(() => {
    let _filteredLocations = [];
    _filteredLocations = locationList.filter(location => location.name.toLowerCase().includes(filterLocationName.trim().toLowerCase()))
     
    setFilteredLocations(_filteredLocations);
    
    if (_filteredLocations.length > 0) {
      setPagination({
        currentPage: 1,
        totalPages: Math.ceil(_filteredLocations.length / nShowsLocation)
      });
    } else {
      setPagination({
        currentPage: 0,
        totalPages: Math.ceil(_filteredLocations.length / nShowsLocation)
      });
    }
  }, [
    filterLocationName,
    locationList
  ]);

  useEffect(() => {
    filterLocations();
  }, [
    filterLocationName,
    locationList,
    filterLocations
  ]);


  return (
    <>
      { isLoading ?
        <Content>
          {[...Array(6).keys()].map(i => (
            <LocationDetail key={i} isSkeleton />
          ))}
        </Content>
      :
        <div>
          <Content>
            {locationsToShow.map((location, i) => (
              <LocationDetail
                key={i}
                project_id={project_id}
                location={location}
                no={i + 1}
                onDeleted={onDeleted}
                onEdited={onEdited}
                canManage={project && project.role !== 'user'}
              />
            ))}
          </Content>
          {locationsToShow.length === 0 ?
            <h3 style={{textAlign:'center', margin: 30}}>No Locations</h3>
          :
            <PaginationWrapper>
              <Pagination
                currentPage={pagination.currentPage}
                totalPages={pagination.totalPages}
                handleChangePage={handleChangePage}
              />
            </PaginationWrapper>
          }
        </div>
      }

      {isAddLocation && (
        <Modal
          onClose={() => {
            onAdded && onAdded(null);
          }}
        >
          <AddLocation
            project_id={project_id}
            onClose={() => {}}
            onAdded={(location) => {
              onAdded && onAdded(location);
            }}
          />
        </Modal>
      )}
    </>
  );
};