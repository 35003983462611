import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  color: ${props => props.theme.colors.white};

  > button {
    margin-top: 70px;
    padding: 15px 18px;
    font-weight: 700;
    font-size: 20px;
  }

  @media (min-width: 769px) {
    > button {
      margin-top: 70px;
      padding: 16px 21px;
      font-size: 22px;
    }
  }

  @media (min-width: 1440px) {
    button {
      margin-top: 80px;
      padding: 19px 24px;
      font-size: 24px;
    }
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 28px;
  > img {
    width: 28px;
    margin-right: 10px;
  }
  h1 {
    font-weight: 700;
    font-size: 32px;
  }
  @media (min-width: 576px) {
    margin-bottom: 30px;
    > img {
      width: 32px;
      margin-right: 25px;
    }
    h1 {
      font-size: 42px;
    }
  }
  @media (min-width: 769px) {
    margin-bottom: 30px;
    > img {
      width: 35px;
      margin-right: 25px;
    }
    h1 {
      font-size: 45px;
    }
  }

  @media (min-width: 1440px) {
    margin-bottom: 32px;
    > img {
      width: 53px;
      margin-right: 32px;
    }
    h1 {
      font-size: 56px;
    }
  }
`

export const Description = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  text-align: center;

  @media (min-width: 576px) {
    font-size: 22px;
  }

  @media (min-width: 769px) {
    font-size: 25px;
  }

  @media (min-width: 1440px) {
    font-size: 28px;
  }
`
