import React from 'react'
import { useTheme } from 'styled-components'
import { Button } from '../../Shared'
import {
  Container,
  Header,
  Description
} from './styles'

export const DeleteProject = (props) => {
  const {
    onClose
  } = props

  const theme = useTheme()

  const handleDeleteProject = () => {
    // code here
    onClose && onClose()
  }

  return(
    <Container>
      <Header>
        <img src={theme.icons.deleteIcon} alt='deleteIcon' />
        <h1>Are You Sure ?</h1>
      </Header>
      <Description>Please confirm that you want to delete this project.</Description>
      <Button color='red' onClick={handleDeleteProject}>Confirm & Delete</Button>
    </Container>
  )
}
