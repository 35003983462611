import React, { useState, useCallback } from 'react'
import cogoToast from 'cogo-toast'
import { NewProjectIcon } from '../../Shared/SvgIcons'
import { Button, Input, Select } from '../../Shared'
import {
  Container,
  InnerContainer,
  Header,
  Description,
  FormController
} from './styles'

import { setAlarmTypesForUser } from '../../../services';

export const SetConfig = (props) => {
  const { onConfigured, location_id, user } = props

  const [loading, setLoading] = useState(false)
  const [alarmTypes, setAlarmTypes] = useState(user.alarm_types || '')

  const isValid = (str) => {
    let lower = 1, upper = 384;
      
    // Removing the unnecessary spaces
    str = str.replace(/\s/g, '');
    
    // Split the string by comma (,)
    const nums = str.split(',');
    const track = {};
    
    // Visit the numbers
    for (const num of nums) {
      
      // Check if any number contains a dash (-)
      if (/\-/.test(num)) {
        
        // If has dash then split by dash and get the upper and lower bounds.
        const [l, u] = num.split('-').map(x => x * 1);
        
        // Visit from lower to upper bound
        for (let i = l; i <= u; i++) {
          
          // If any number of the range doesn't exceed the upper
          // or lower bound i.e. [1, 384] range and did not
          // appear before then track this number.
          // otherwise return false i.e. mark it as invalid.
          if (i >= lower && i <= upper && track[i] === undefined) {
            track[i] = true;
          } else {
            return false;
          }
        }
        
      } else {
        
        // Checking again if it exceed the range [1, 384] or appears before.
        if (num * 1 >= lower && num * 1 <= upper && track[num] === undefined) {
          track[num] = true;
        } else {
          return false;
        }
      }
    }
    
    // If everything okay then return true, i.e. valid.
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (loading) return;

    if (!isValid(alarmTypes)) {
      return cogoToast.error('Enter valid alarm types.');
    }
    
    try {
      setLoading(true);
      await setAlarmTypesForUser(location_id, user.user.Attributes.find(attr => attr.Name === 'sub')?.Value, alarmTypes);
      cogoToast.success('User was added successfully.')
      onConfigured && onConfigured(alarmTypes);

    } catch (error) {
      cogoToast.error(error.data.message || error.data);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <InnerContainer onSubmit={handleSubmit}>
        <Header>
          <NewProjectIcon />
          <h1>Set Config</h1>
        </Header>
        <Description>Please fill out the alarm types that user should receive then submit.</Description>
        <FormController>
          <label>Alarm Types</label>
          <Input
            type="text"
            placeholder="1,11-20"
            value={alarmTypes}
            required
            onChange={(event) => setAlarmTypes(event.target.value)}
          />
        </FormController>

        <Button color='darkBlue' isLoading={loading}>Submit</Button>

      </InnerContainer>
    </Container>
  )
}
