import axios from 'axios';

export const createProject = (params) => {
	return axios.post(`${process.env.REACT_APP_SERVER_URL}/projects`, params)
		.then(data => data.data)
		.catch(error => { throw error.response });
};

export const getProject = (projectId) => {
	return axios.get(`${process.env.REACT_APP_SERVER_URL}/projects/${projectId}`)
		.then(data => data.data)
		.catch(error => { throw error.response });
};

export const getProjects = () => {
	return axios.get(`${process.env.REACT_APP_SERVER_URL}/projects`)
		.then(data => data.data)
		.catch(error => { throw error.response });
};

export const setOriginAdmin = (id, params) => {
	return axios.post(`${process.env.REACT_APP_SERVER_URL}/projects/${id}/origin-admin`, params)
		.then(data => data.data)
		.catch(error => { throw error.response });
};

export const getProjectUsers = (projectId) => {
	const url = `${process.env.REACT_APP_SERVER_URL}/projects/${projectId}/users`;
	return axios.get(url)
		.then(data => data.data)
		.catch(error => { throw error.response });
};

export const inviteUser = (projectId, invite_user, role) => {
	const url = `${process.env.REACT_APP_SERVER_URL}/projects/${projectId}/invite-user`;
	return axios.post(url, { invite_user, role })
		.then(data => data.data)
		.catch(error => { throw error.response });
};

export const deleteUserFromProject = (projectId, userId) => {
	return axios.delete(`${process.env.REACT_APP_SERVER_URL}/projects/${projectId}/users/${userId}`)
		.then(data => data.data)
		.catch(error => { throw error.response });
};

export const changeRoleOfUser = (projectId, userId, role) => {
	return axios.put(`${process.env.REACT_APP_SERVER_URL}/projects/${projectId}/users/${userId}/role`, { role })
		.then(data => data.data)
		.catch(error => { throw error.response });
};

export const getProjectDevices = (projectId) => {
	const url = `${process.env.REACT_APP_SERVER_URL}/projects/${projectId}/devices`;
	return axios.get(url)
		.then(data => data.data)
		.catch(error => { throw error.response });
}

export const addDevice = (projectId, reg_id, name, location_id, device_type_id) => {
	const url = `${process.env.REACT_APP_SERVER_URL}/projects/${projectId}/devices`;
	return axios.post(url, { reg_id, name, location_id, device_type_id })
		.then(data => data.data)
		.catch(error => { throw error.response });
}