import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.white};
`

export const InnerContainer = styled.form`
  width: 100%;
  max-width: 557px;
  > button {
    margin-top: 70px;
    padding: 15px 18px;
    font-weight: 700;
    font-size: 20px;
  }

  @media (min-width: 769px) {
    > button {
      margin-top: 70px;
      padding: 16px 21px;
      font-size: 22px;
    }
  }

  @media (min-width: 1440px) {
    button {
      margin-top: 80px;
      padding: 19px 24px;
      font-size: 24px;
    }
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 28px;
  > svg {
    width: 38px;
    height: 26px;
    min-width: 35px;
    margin-right: 10px;
  }
  h1 {
    font-weight: 700;
    font-size: 32px;
  }
  @media (min-width: 576px) {
    margin-bottom: 30px;
    > svg {
      width: 40px;
      height: 28px;
      min-width: 40px;
      margin-right: 15px;
    }
    h1 {
      font-size: 38px;
    }
  }
  @media (min-width: 769px) {
    margin-bottom: 30px;
    > svg {
      width: 56px;
      height: 43px;
      margin-right: 25px;
    }
    h1 {
      font-size: 45px;
    }
  }

  @media (min-width: 1440px) {
    margin-bottom: 32px;
    > svg {
      width: 71px;
      height: 56px;
      margin-right: 32px;
    }
    h1 {
      font-size: 56px;
    }
  }
`

export const Description = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  margin-bottom: 50px;

  @media (min-width: 576px) {
    font-size: 22px;
  }

  @media (min-width: 769px) {
    font-size: 25px;
    margin-bottom: 80px;
  }

  @media (min-width: 1440px) {
    font-size: 28px;
    margin-bottom: 100px;
  }
`

export const FormController = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  label {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 10px;
    line-height: 160%;
  }

  input, textarea {
    padding: 10px 15px;
    font-size: 16px;
  }
  textarea {
    height: 250px;
  }

  @media (min-width: 768px) {
    margin-bottom: 32px;
    label {
      font-size: 24px;
      margin-bottom: 18px;
    }
    input, textarea {
      font-size: 18px;
      border: 1px solid ${props => props.theme.colors.lightGray} ;
    }
  }
`