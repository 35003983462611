import React, { useEffect, useState, useMemo, useCallback } from 'react'
import cogoToast from 'cogo-toast';
import Skeleton from 'react-loading-skeleton';
import { SectionBar, Button, Pagination} from '../Shared'
import { useNavigate, useParams } from 'react-router-dom'
import { BackIcon, NewUserIcon } from '../Shared/SvgIcons'
import { Modal } from '../Shared/Modal'
import { FilterPopover } from './FilterPopover';
import { SortPopover } from './SortPopover';
import { UserDetail } from './UserDetail';
import { AddUser } from './AddUser';
import {
  Container,
  Content,
  InnerContainer,
  HeaderContainer,
  BackButtonWrapper,
} from './styles'
import {
  PaginationWrapper
} from '../DeviceList/styles';
import { getLocation, getLocationUsers, getProjectUsers } from '../../services';
// import { UserList } from './UserList';

export const LocationDetails = (props) => {
  const { id } = useParams();
  const navigate = useNavigate()
  const nShowUsers = 9;

  const [isAddUser, setIsAddUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState(null);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [projectUsers, setProjectUsers] = useState([]);
  const [pagination, setPagination] = useState({ currentPage: 0, totalPages: 0 });
  

  // User Filter
  const [filterName, setFilterName] = useState('');
  const [filterEmail, setFilterEmail] = useState('');
  const [filterPhoneNumber, setFilterPhoneNumber] = useState('');
  const [filterBio, setFilterBio] = useState('');
  
  // Sort
  const [isAscending, setIsAscending] = useState(true);
  const [isSortDate, setIsSortDate] = useState(true);

  const usersToShow = useMemo(() => {
    const usersToShow = [];

    let sortedUsers = [];
    if (isSortDate) {
      sortedUsers = filteredUsers.sort((a, b) => {
        if (isAscending) {
          return a.user.UserCreateDate.localeCompare(b.user.UserCreateDate);
        } else {
          return b.user.UserCreateDate.localeCompare(a.user.UserCreateDate);
        }
      })
    } else {
      sortedUsers = filteredUsers.sort((a, b) => {
        const name1 = `${a.user.Attributes.find(attr => attr.Name === 'given_name').Value} ${a.user.Attributes.find(attr => attr.Name === 'family_name').Value}`;
        const name2 = `${b.user.Attributes.find(attr => attr.Name === 'given_name').Value} ${b.user.Attributes.find(attr => attr.Name === 'family_name').Value}`;

        if (isAscending) {
          return name1.localeCompare(name2);
        } else {
          return name2.localeCompare(name1);
        }
      })
    }

    if (pagination.currentPage === 0) return [];

    const startIndex = (pagination.currentPage - 1) * nShowUsers;
    const endIndex = Math.min(sortedUsers.length, startIndex + nShowUsers);
    for (let i = startIndex; i < endIndex; i++) {
      usersToShow.push(sortedUsers[i]);
    }

    return usersToShow;
  }, [filteredUsers, pagination, isAscending, isSortDate]);

  const filterUsers = useCallback(() => {
    let _filteredUsers = [];
    _filteredUsers = users.filter(user => {
      const name = `${user.user.Attributes.find(attr => attr.Name === 'given_name').Value} ${user.user.Attributes.find(attr => attr.Name === 'family_name').Value}`;
      return name.toLowerCase().includes(filterName.trim().toLowerCase())
    })
    _filteredUsers = _filteredUsers.filter(user => {
      const email = user.user.Attributes.find(attr => attr.Name === 'email').Value;
      return email.toLowerCase().includes(filterEmail.trim().toLowerCase())
    })
    _filteredUsers = _filteredUsers.filter(user => {
      const phoneNumber = user.user.Attributes.find(attr => attr.Name === 'phone_number').Value
      return phoneNumber.toLowerCase().includes(filterPhoneNumber.trim().toLowerCase())
    })
    _filteredUsers = _filteredUsers.filter(user => {
      const bio = user.user.Attributes.find(attr => attr.Name === 'custom:bio')?.Value
      return bio?.toLowerCase()?.includes(filterBio.trim().toLowerCase())
    })
    
    setFilteredUsers(_filteredUsers);
    
    if (_filteredUsers.length > 0) {
      setPagination({
        currentPage: 1,
        totalPages: Math.ceil(_filteredUsers.length / nShowUsers)
      });
    } else {
      setPagination({
        currentPage: 0,
        totalPages: Math.ceil(_filteredUsers.length / nShowUsers)
      });
    }
  }, [
    filterName,
    filterEmail,
    filterPhoneNumber,
    filterBio, 
    users
  ]);

  const handleChangePage = (page) => {
    setPagination({
      ...pagination,
      currentPage: page
    })
  };

  const getUsers = async () => {
    try {
      setLoading(true)
      const _users = await getLocationUsers(id);
      setUsers(_users);

    } catch (error) {
      cogoToast.error(error.data.message || error.data);
    } finally {
      setLoading(false)
    }
  };

  const getUsersOfProject = async (projectId) => {
    try {
      const _projectUsers = await getProjectUsers(projectId);
      setProjectUsers(_projectUsers);
    } catch (error) {
      cogoToast.error(error.data.message || error.data)
    }
  };

  const getLocationDetails = async () => {
    try {
      setLoading(true);
      const _location = await getLocation(id);
      setLocation(_location);

      await getUsers();
      await getUsersOfProject(_location.project_id);
    } catch (error) {
      cogoToast.error(error.data.message || error.data);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    filterUsers();
  }, [
    filterName,
    filterEmail,
    filterPhoneNumber,
    filterBio,
    users,
    filterUsers
  ]);

  useEffect(() => {
    getLocationDetails();
  }, [])


  return (
    <>
      <Container>
        <InnerContainer>
          <SectionBar />
          <HeaderContainer>
            <div className='title'>
              {loading ? <Skeleton width={190} height={56} /> : <h1>{location?.name}</h1>}
              <BackButtonWrapper onClick={() => navigate(`/projects/${location?.project_id}`)}>
                <BackIcon />
                <span>Back to Locations</span>
              </BackButtonWrapper>
            </div>
            <div className='action'>
              <FilterPopover
                filterName={filterName}
                filterEmail={filterEmail}
                filterPhoneNumber={filterPhoneNumber}
                filterBio={filterBio}
                setFilterName={setFilterName}
                setFilterEmail={setFilterEmail}
                setFilterPhoneNumber={setFilterPhoneNumber}
                setFilterBio={setFilterBio}
              />

              <SortPopover
                isAscending={isAscending}
                isSortDate={isSortDate}
                setIsAscending={setIsAscending}
                setIsSortDate={setIsSortDate}
              />

              <Button
                color='primary'
                onClick={() => setIsAddUser(true)}
              >
                <NewUserIcon />
                <span>Add User</span>
              </Button>
            </div>
          </HeaderContainer>
          
          {loading ?
            <Content>
              {[...Array(6).keys()].map(i => (
                <UserDetail key={i} isSkeleton />
              ))}
            </Content>
          :
          <div>
            <Content>
              {usersToShow.map((user, i) => (
                <UserDetail
                  key={i}
                  no={i+1} 
                  user={user}
                  location_id={id}
                  onDeleted={() => {
                    setUsers(users.filter(_user=> user.user_id !== _user.user_id));
                  }}
                  onConfigured={(alarm_types) => {
                    user.alarm_types = alarm_types;
                    setUsers([...users]);
                  }}
                />
              ))}
            </Content>

            { usersToShow.length === 0 ?
              <h3 style={{textAlign:'center', margin: 30}}>No users in this location</h3>
            :
              <PaginationWrapper>
                <Pagination
                  currentPage={pagination.currentPage}
                  totalPages={pagination.totalPages}
                  handleChangePage={handleChangePage}
                />
              </PaginationWrapper>
            }
          </div>}
        </InnerContainer>
      </Container>

      {isAddUser && projectUsers.length > 0 && (
        <Modal
          onClose={() => setIsAddUser(false)}
        >
          <AddUser
            location={location}
            projectUsers={projectUsers}
            onClose={() => setIsAddUser(false)}
            onAdded={() => {
              setIsAddUser(false);
              getUsers()
            }}
          />
        </Modal>
      )}
    </>
  )
}