import moment from 'moment';
import React, { useEffect, useMemo, useState, useCallback } from 'react'
import cogoToast from 'cogo-toast'
import { SectionBar, Button, Pagination } from '../Shared'
import { NewProjectIcon } from '../Shared/SvgIcons'
import { FilterPopover } from './FilterPopover'
import { SortPopover } from './SortPopover'
import { ProjectDetail } from './ProjectDetail'
import {
  Container,
  InnerContainer,
  HeaderContainer,
  ProjectsList,
  TotalProjectsCount,
  PaginationWrapper
} from './styles'
import { Modal } from '../Shared/Modal'
import { AddProject } from './AddProject'
import { getProjects } from '../../services/project'
import { useSession } from '../../contexts/SessionContext';

export const ProjectList = (props) => {
  const [{authInfo}] = useSession();
  const nShowsProjects = 9;
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [pagination, setPagination] = useState({ currentPage: 0, totalPages: 0 });
  const [isAdd, setIsAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Sort
  const [isAscending, setIsAscending] = useState(true);
  const [isSortDate, setIsSortDate] = useState(true);

  // Filter
  const [filterProjectName, setFilterProjectName] = useState('');
  const [filterAdminName, setFilterAdminName] = useState('');
  const [filterAdminEmail, setFilterAdminEmail] = useState('');
  const [filterCreationTime, setFilterCreationTime] = useState('');

  const getProjectList = async (continueKey) => {
    setProjects([]);

		try {
      setIsLoading(true);
			const _projects = await getProjects(continueKey);
      setProjects(_projects);
      setPagination({
        currentPage: 1,
        totalPages: Math.ceil(_projects.length / nShowsProjects)
      });

		} catch (error) {
			cogoToast.error(error.data.message || error.data);

		} finally {
			setIsLoading(false);
		}
  };

  const handleChangePage = (page) => {
    setPagination({
      ...pagination,
      currentPage: page
    })
  };

  const projectsToShow = useMemo(() => {
    const projectsToShow = [];

    let sortedProjects = [];
    if (isSortDate) {
      sortedProjects = filteredProjects.sort((a, b) => {
        if (isAscending) {
          return a.created_at - b.created_at;
        } else {
          return b.created_at - a.created_at;
        }
      })
    } else {
      sortedProjects = filteredProjects.sort((a, b) => {
        if (isAscending) {
          return a.name.localeCompare(b.name);
        } else {
          return b.name.localeCompare(a.name);
        }
      })
    }

    if (pagination.currentPage === 0) return [];

    const startIndex = (pagination.currentPage - 1) * nShowsProjects;
    const endIndex = Math.min(sortedProjects.length, startIndex + nShowsProjects);
    for (let i = startIndex; i < endIndex; i++) {
      projectsToShow.push(sortedProjects[i]);
    }

    return projectsToShow;
  }, [filteredProjects, pagination, isAscending, isSortDate]);

  const filterProjects = useCallback(() => {
    let _filteredProjects = [];
    _filteredProjects = projects.filter(project => project.name.toLowerCase().includes(filterProjectName.trim().toLowerCase()))
    _filteredProjects = _filteredProjects.filter(project => {
      const userName = `${project?.admin_user?.Attributes.find(attr => attr.Name === 'given_name').Value} ${project?.admin_user?.Attributes.find(attr => attr.Name === 'family_name').Value}`;
      return userName.toLowerCase().includes(filterAdminName.trim().toLowerCase())
    });
    _filteredProjects = _filteredProjects.filter(project => {
      const email = project?.admin_user?.Attributes.find(attr => attr.Name === 'email').Value;
      return email.toLowerCase().includes(filterAdminEmail.trim().toLowerCase())
    });
    _filteredProjects = _filteredProjects.filter(project => {
      const creationTime = moment(project?.created_at).format('YYYY-MM-DD HH:mm');
      return creationTime.toLowerCase().includes(filterCreationTime.trim().toLowerCase())
    });
    setFilteredProjects(_filteredProjects);
    
    if (_filteredProjects.length > 0) {
      setPagination({
        currentPage: 1,
        totalPages: Math.ceil(_filteredProjects.length / nShowsProjects)
      });
    } else {
      setPagination({
        currentPage: 0,
        totalPages: Math.ceil(_filteredProjects.length / nShowsProjects)
      });
    }
  }, [
    filterProjectName,
    filterAdminName,
    filterAdminEmail,
    filterCreationTime,
    projects,
  ]);

  useEffect(() => {
    filterProjects();
  }, [
    filterProjectName,
    filterAdminName,
    filterAdminEmail,
    filterCreationTime,
    projects,
    filterProjects
  ]);
    
  useEffect(() => {
    getProjectList();
  }, [])

  return (
    <>
      <Container>
        <InnerContainer>
          <SectionBar />
          <HeaderContainer>
            <div className='title'>
              <h1>Projects</h1>
              <TotalProjectsCount>You have {projects.length} projects.</TotalProjectsCount>
            </div>
            <div className='action'>
              <FilterPopover
                filterProjectName={filterProjectName}
                filterAdminName={filterAdminName}
                filterAdminEmail={filterAdminEmail}
                filterCreationTime={filterCreationTime}
                setFilterProjectName={setFilterProjectName}
                setFilterAdminName={setFilterAdminName}
                setFilterAdminEmail={setFilterAdminEmail}
                setFilterCreationTime={setFilterCreationTime}
              />

              <SortPopover
                isSortDate={isSortDate}
                isAscending={isAscending}
                setIsAscending={setIsAscending}
                setIsSortDate={setIsSortDate}
              />
              {authInfo.user['custom:role'] === 'admin' && 
                <Button
                  color='primary'
                  onClick={() => setIsAdd(true)}
                >
                  <NewProjectIcon />
                  <span>New Project</span>
                </Button>
              }
            </div>
          </HeaderContainer>
          {isLoading && (
            <ProjectsList>
              {[...Array(6).keys()].map(i => (
                <ProjectDetail key={i} isSkeleton />
              ))}
            </ProjectsList>
          )}
          {!isLoading && (
            <ProjectsList>
              {projectsToShow.map((project, key) => (
                <ProjectDetail project={project} key={key} 
                  no={(pagination.currentPage - 1) * nShowsProjects + key + 1} 
                />
              ))}
            </ProjectsList>
          )}

          { projectsToShow.length === 0 ?
            <h3 style={{textAlign:'center', margin: 30}}>No Projects</h3>
          :
            <PaginationWrapper>
              <Pagination
                currentPage={pagination.currentPage}
                totalPages={pagination.totalPages}
                handleChangePage={handleChangePage}
              />
            </PaginationWrapper>
          }
        </InnerContainer>
      </Container>
      {isAdd && (
        <Modal
          onClose={() => setIsAdd(false)}
        >
          <AddProject
            onClose={() => setIsAdd(false)}
            onAdded={() => {
              setIsAdd(false)
              getProjectList()
            }}
          />
        </Modal>
      )}
    </>
  )
}
