import styled, { css } from 'styled-components'

export const Container = styled.div`
  padding-top: 120px;
  margin-bottom: 80px;

  @media (min-width: 768px) {
    padding-top: 140px;
    margin-bottom: 0px;
  }
`
export const InnerContainer = styled.div`
  max-width: 1440px;
  min-height: inherit;
  margin: 0px auto;
  padding: 15px;

  @media (min-width: 576px) {
    padding: 15px 20px;
  }

  @media (min-width: 768px) {
    padding: 20px 30px;
  }

  @media (min-width: 993px) {
    padding: 24px 40px;
  }

  @media (min-width: 1024px) {
    padding: 28px 60px;
  }
  
  @media (min-width: 1500px) {
    padding: 28px 30px;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  h1 {
    font-weight: 600;
    font-size: 40px;
    line-height: 100%;
    color: ${props => props.theme.colors.black};
    margin-right: 10px;
  }

  > div.action {
    display: flex;
    align-items: center;
    margin-top: 32px;

    > button {
      margin-left: 20px;
      height: 55px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 10px;
      }
      span {
        font-weight: 700;
        font-size: 16px;
      }
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    > div.action {
      margin-top: 0;
      
      > button {
        margin-left: 40px;
      }
    }
  }
  @media (min-width: 1024px) {
    h1 {
      font-size: 56px;
    }
  }
`

export const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 50px;
  flex-direction: column;
  align-items: flex-start;

  > div {
    margin-top: 40px;
  }

  .new-btn {
    padding: 16px;
    white-space: nowrap;
    svg {
      margin-right: 10px;
      height: 20px;
      max-width: 25px;

      path {
        fill: ${props => props.theme.colors.primary};
      }
    }
    &:hover {
      svg {
        path {
          fill: ${props => props.theme.colors.white};
        }
      }
    }
  }
  @media (min-width: 576px) {
    flex-direction: row;
    .new-btn {
      padding: 17px;
      svg {
        margin-right: 12px;
      }
    }
  }
`

export const MenuListWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  @media (min-width: 769px) {
    width: initial;
    justify-content: flex-start;
  }
`

export const BackButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  margin-top: 16px;
  width: fit-content;
  padding: 5px;
  border-radius: 5px;
  transition: all 0.3s linear;

  span {
    margin-left: 12px;
    font-weight: 500;
    font-size: 16px;
    color: ${props => props.theme.colors.primary};
    line-height: 150%;
    font-family: 'Maven Pro';
  }

  &:hover {
    background: #e0eeff;
  }
`

export const MenuItem = styled.div`
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: 17px;
  margin-right: 14px;

  span {
    font-family: 'Maven Pro';
    font-weight: 700;
    font-size: 18px;
    margin-left: 12px;
  }

  svg {
    height: 20px;
    max-width: 25px;
    path {
      fill: #757B82;
    }
  }

  ${({ active }) => active && css`
    background-color: ${props => props.theme.colors.primary};
    svg {
      path {
        fill: ${props => props.theme.colors.white};
      }
    }
    span {
      color: ${props => props.theme.colors.white};
    }
  `}

  @media (min-width: 576px) {
    padding: 18px;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Content = styled.div`
  margin-top: 32px;

  @media (min-width: 768px) {
    margin-top: 52px;
  }
  @media (min-width: 992px) {
    display: flex;
    flex-wrap: wrap;
    margin-left: -28px;
    width: calc(100% + 56px);
  }
`

export const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  &.user-role {
    .custom-select {
      > div:first-child {
        svg {
          path {
            fill: #757B82;
          }
        }
      }
    }
  }
`

export const TabSelectWrapper = styled.div`
  position: relative;
`

export const SelectedWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const DropDownList = styled.div`
  position: absolute;
  overflow: hidden;
  z-index: 1;
  top: 100%;
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  border-radius: 8px;
  margin-top: 10px;
  box-shadow: 0px 6px 30px rgb(235 238 243);
`

export const DropDownItem = styled.div`
  padding: 10px 15px;
  cursor: pointer;
  transition: all 0.3s linear;
  span {
    font-weight: 700;
    font-size: 18px;
  }
  &:hover {
    background-color: ${props => props.theme.colors.backgroundActive};
  }
  ${({ active }) => active && css`
    background-color: ${props => props.theme.colors.primaryBoxBg};
  `}
`