import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin-top: 72px;
  
  > div {
    width: 100%;
    margin-top: 20px;
  }
  @media (min-width: 576px) {
    padding: 15px 20px;
  }
  @media (min-width: 769px) {
    margin-top: 82px;
    padding: 0px;
    flex-direction: row;
    > div {
      width: 50%;
      margin-top: 20px;
    }
  }
  @media (min-width: 993px) {
    margin-top: 90px;
  }
  @media (min-width: 1024px) {
    margin-top: 98px;
  }
`

export const LeftContainer = styled.div`
  padding: 0px;

  @media (min-width: 769px) {
    padding-left: 30px;
  }

  @media (min-width: 993px) {
    padding-left: 40px;
  }

  @media (min-width: 1024px) {
    padding-left: 60px;
  }
  
  @media (min-width: 1500px) {
    padding-left: calc((100% - 1440px) / 2 + 30px);
  }
`

export const RightContainer = styled.div`
  padding-left: 0px;

  @media (min-width: 769px) {
    padding-left: 20px;
  }

  @media (min-width: 1200px) {
    padding-left: 40px;
  }
`

export const ImgWrapper = styled.div`
  img {
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
  }
  @media (min-width: 769px) {
    img {
      border-radius: 20px 0px 0px 20px;
    }
  }
`

export const TextWrapper = styled.div`
  > h1 {
    font-weight: 700;
    font-size: 35px;
    line-height: 120%;
    color: ${props => props.theme.colors.black};
    span {
      color: ${props => props.theme.colors.primary};
      background-color: ${props => props.theme.colors.primaryBoxBg};
      border: 1px solid ${props => props.theme.colors.primary};
      border-radius: 4px;
    }
    margin-bottom: 30px;
  }

  > p {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
  }

  @media (min-width: 769px) {
    h1 {
      font-size: 44px;
      margin-bottom: 20px;
      line-height: 130%;
    }
    p {
      font-size: 18px;
      line-height: 150%;
    }
  }

  @media (min-width: 993px) {
    h1 {
      font-size: 60px;
      margin-bottom: 28px;
      line-height: 140%;
    }
    p {
      font-size: 20px;
      line-height: 160%;
    }
  }

  @media (min-width: 1200px) {
    h1 {
      font-size: 68px;
    }
    p {
      font-size: 20px;
    }
  }

  @media (min-width: 1440px) {
    h1 {
      font-size: 80px;
    }
    p {
      font-size: 22px;
    }
  }
`

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  span {
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    color: ${props => props.theme.colors.black};
  }
  img {
    height: 24px;
    object-fit: cover;
    margin-left: 12px;
  }

  @media (min-width: 768px) {
    margin-top: 30px;
  }

  @media (min-width: 993px) {
    margin-top: 40px;
  }

  @media (min-width: 1200px) {
    margin-top: 66px;
  }
`
