import React, { useState, useCallback } from 'react'
import cogoToast from 'cogo-toast'
import { NewProjectIcon } from '../../Shared/SvgIcons'
import { Button, Input, Select } from '../../Shared'
import {
  Container,
  InnerContainer,
  Header,
  Description,
  FormController
} from './styles'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import { addUserToLocation } from '../../../services';

export const AddUser = (props) => {
  const { onAdded, location, projectUsers } = props

  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState(null)

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (loading) return;
    if (!user) {
      return cogoToast.error('Choose the user to be added to the location.');
    }

    try {
      setLoading(true);

      await addUserToLocation(location.id, user.Attributes.find(attr => attr.Name === 'sub')?.Value, []);
				
      onAdded && onAdded();
			cogoToast.success('User was added successfully.')

    } catch (error) {
      cogoToast.error(error.data.message || error.data);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      <InnerContainer onSubmit={handleSubmit}>
        <Header>
          <NewProjectIcon />
          <h1>Add New User</h1>
        </Header>
        <Description>Please fill out the user details then submit.</Description>
        <FormController>
          <label>Choose User</label>
          <Select
            options={projectUsers.map(user => {
              return { 
                value: user.user.Attributes.find(attr => attr.Name === 'sub')?.Value,
                name: `${user.user.Attributes.find(attr => attr.Name === 'given_name')?.Value} ${user.user.Attributes.find(attr => attr.Name === 'family_name')?.Value}`
              };
            })}
            defaultValue={user?.Attributes.find(attr => attr.Name === 'sub')?.Value}
            onChange={(userId) => {
              setUser(projectUsers.find(user => user.user_id === userId)?.user)
            }}
          />
        </FormController>
        
        { user &&
          <>
            <FormController>
              <label>Email</label>
              <Input
                type="email"
                value={user.Attributes.find(attr => attr.Name === 'email')?.Value}
                readOnly
              />
            </FormController>

            <FormController>
              <label>First Name</label>
              <Input
                type="text"
                value={user.Attributes.find(attr => attr.Name === 'given_name')?.Value}
                readOnly
              />
            </FormController>

            <FormController>
              <label>Last Name</label>
              <Input
                type="text"
                value={user.Attributes.find(attr => attr.Name === 'family_name')?.Value}
                readOnly
              />
            </FormController>

            <FormController>
              <label>Phone Number</label>
              <PhoneInput
                disabled
                value={user.Attributes.find(attr => attr.Name === 'phone_number')?.Value}
                onChange={() => {}}
              />
            </FormController>

            <FormController>
              <label>Bio</label>
              <Input
                type="text"
                value={user.Attributes.find(attr => attr.Name === 'custom:bio')?.Value}
                readOnly
              />
            </FormController>
        
            <Button color='darkBlue' isLoading={loading}>Submit</Button>
          </>
        }
      </InnerContainer>
    </Container>
  )
}
