import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { EditIcon, DeleteIcon, DeviceIcon } from '../../Shared/SvgIcons'
import Skeleton from 'react-loading-skeleton'
import {
  Container,
  Header,
  InfoItem,
  ActionContainer,
  EditProjectButton,
  DeleteProjectButton,
  DefaultDisabled
} from './styles'
import { DeleteLocation } from '../LocationList/DeleteLocation'
import { Modal } from '../../Shared/Modal'
import { EditLocation } from '../LocationList/EditLocation'

export const LocationDetail = (props) => {
  const navigate = useNavigate();
  const { isSkeleton, location, no, onDeleted, onEdited, canManage, project_id } = props
  const [isDelete, setIsDelete] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const goLocationDetails = () => {
    if (canManage) {
      navigate(`/projects/${project_id}/locations/${location.id}`)
    }
  };

  return (
    <>
      <Container onClick={goLocationDetails}>
        <Header>
          {isSkeleton ? (
            <>
              <Skeleton width={120} height={25} />
              <Skeleton width={35} height={25} />
            </>
          ) : (
            <>
              <p className='name'>{location.name}</p>
              <p className='number'>{String(no).padStart(2, '0')}</p>
            </>
          )}
        </Header>
        <InfoItem>
          {isSkeleton ? <Skeleton width={18} height={18} /> : <DeviceIcon />}
          <div>
            <label>{isSkeleton ? <Skeleton width={60} /> : 'Associated Devices Number'}</label>
            <p>{isSkeleton ? <Skeleton width={100} /> : `${location.device_count || 0} devices`}</p>
          </div>
        </InfoItem>
        {canManage &&
          <ActionContainer>
            {location?.is_default ? (
              <DefaultDisabled>
                <DeleteIcon />
                <span>Default location can’t be deleted</span>
              </DefaultDisabled>
          ) : (
              <>
                <EditProjectButton onClick={(event) => {
                  event.stopPropagation()
                  !isSkeleton && setIsEdit(true)
                }}>
                  {isSkeleton ? <Skeleton width={24} height={24} /> : <EditIcon />}
                  {isSkeleton ? <Skeleton width={60} style={{ marginLeft: '10px'}} /> : <span className='title'>Rename</span>}
                </EditProjectButton>
                <DeleteProjectButton onClick={(event) => {
                  event.stopPropagation()
                  !isSkeleton && setIsDelete(true)
                }}>
                  {isSkeleton ? <Skeleton width={24} height={24} /> : <DeleteIcon />}
                  {isSkeleton ? <Skeleton width={60} style={{ marginLeft: '10px'}} /> : <span className='title'>Delete</span>}
                </DeleteProjectButton>
              </>
            )}
          </ActionContainer>
        }
      </Container>

      {isDelete && (
        <Modal
          onClose={() => setIsDelete(false)}
        >
          <DeleteLocation
            location={location}
            onClose={() => setIsDelete(false)}
            onDeleted={() => {
              onDeleted && onDeleted(location)
              setIsDelete(false)
            }}
          />
        </Modal>
      )}

      {isEdit && (
        <Modal
          onClose={() => setIsEdit(false)}
        >
          <EditLocation
            location={location}
            onClose={() => setIsEdit(false)}
            onEdited={(location) => {
              onEdited && onEdited(location)
              setIsEdit(false)
            }}
          />
        </Modal>
      )}
    </>
  )
}