import React, { useState } from 'react'
import { Button, Input, Select } from '../../Shared'
import { useTheme } from 'styled-components'
import {
  Container,
  InnerContainer,
  Header,
  Description,
  FormController
} from './styles'

export const EditProject = (props) => {
  const {
    onClose
  } = props
  
  const theme = useTheme()
  const [loading, setLoading] = useState(false)

  const inputList = [
    { value: 1, name: 1 },
    { value: 2, name: 2 },
    { value: 3, name: 3 },
    { value: 4, name: 4 },
    { value: 5, name: 5 },
    { value: 6, name: 6 },
    { value: 7, name: 7 }
  ]

  const priorityList = [
    { value: 1, name: 1 },
    { value: 2, name: 2 },
    { value: 3, name: 3 },
    { value: 4, name: 4 },
    { value: 5, name: 5 },
    { value: 6, name: 6 },
    { value: 7, name: 7 }
  ]

  const invertList = [
    { value: 1, name: 'On' },
    { value: 0, name: 'Off' }
  ]

  const handleChangeInput = (value) => {
    console.log(value)
  }

  const handleChangePriority = (value) => {
    console.log(value)
  }

  const handleChangeInvert = (value) => {
    console.log(value)
  }

  const handleEditProject = () => {
    // code here
    setLoading(true)
    // onClose && onClose()
  }

  return (
    <Container>
      <InnerContainer>
        <Header>
          <img src={theme.icons.editIcon} alt='' />
          <h1>Project Configure</h1>
        </Header>
        <Description>Please fill out the config details and the admin details then submit.</Description>
        <FormController>
          <label>Input</label>
          <Select
            options={inputList}
            defaultValue={1}
            onChange={(value) => handleChangeInput(value)}
          />
        </FormController>
        <FormController>
          <label>Message</label>
          <Input
            placeholder='Leave your message.'
          />
        </FormController>
        <FormController>
          <label>Priority</label>
          <Select
            options={priorityList}
            defaultValue={1}
            onChange={(value) => handleChangePriority(value)}
          />
        </FormController>
        <FormController>
          <label>Invert</label>
          <Select
            options={invertList}
            defaultValue={1}
            onChange={(value) => handleChangeInvert(value)}
          />
        </FormController>

        <FormController>
          <label>Device Type</label>
          <Select
            options={invertList}
            defaultValue={1}
            onChange={(value) => handleChangeInvert(value)}
          />
        </FormController>
        <Button color='darkBlue' isLoading={loading} onClick={handleEditProject}>Submit</Button>
      </InnerContainer>
    </Container>
  )
}
