import styled from 'styled-components'

export const  Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  img {
    height: 100px;
    margin-bottom: 20px;
  }
  h2 {
    margin-bottom: 18px;
    font-weight: 600;
    line-height: 130%;
    text-align: center;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    text-align: center;
  }

  @media (min-width: 576px) {
    img {
      height: 100px;
      margin-bottom: 35px;
    }
    h2 {
      margin-bottom: 20px;
      font-size: 32px;
    }
    p {
      font-size: 16px;
    }
  }

  @media (min-width: 1200px) {
    img {
      height: 130px;
      margin-bottom: 40px;
    }
    h2 {
      margin-bottom: 24px;
      font-size: 36px;
    }
    p {
      font-size: 18px;
    }
  }
`
