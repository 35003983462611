import styled from 'styled-components'

export const Container = styled.div`
  padding-top: 120px;
  margin-bottom: 80px;

  @media (min-width: 768px) {
    padding-top: 140px;
    margin-bottom: 0px;
  }
`
export const InnerContainer = styled.div`
  max-width: 1440px;
  min-height: inherit;
  margin: 0px auto;
  padding: 15px;

  @media (min-width: 576px) {
    padding: 15px 20px;
  }

  @media (min-width: 768px) {
    padding: 20px 30px;
  }

  @media (min-width: 993px) {
    padding: 24px 40px;
  }

  @media (min-width: 1024px) {
    padding: 28px 60px;
  }
  
  @media (min-width: 1500px) {
    padding: 28px 30px;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  h1 {
    font-weight: 600;
    font-size: 40px;
    line-height: 100%;
    color: ${props => props.theme.colors.black};
  }

  > div.action {
    display: flex;
    align-items: center;
    margin-top: 32px;

    > button {
      margin-left: 20px;
      height: 55px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 10px;
        width: 24px;
        path {
          fill: ${props => props.theme.colors.white};
        }
      }
      span {
        font-weight: 700;
        font-size: 16px;
      }
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    > div.action {
      margin-top: 0;
      
      > button {
        margin-left: 40px;
      }
    }
  }
  @media (min-width: 1024px) {
    h1 {
      font-size: 56px;
    }
  }
`
export const TotalProjectsCount = styled.p`
  font-size: 16px;
  line-height: 160%;
  margin-top: 16px;
`
export const DevicessList = styled.div`
  margin-top: 32px;

  @media (min-width: 768px) {
    margin-top: 52px;
  }
  @media (min-width: 992px) {
    display: flex;
    flex-wrap: wrap;
    margin-left: -28px;
    width: calc(100% + 56px);
  }
`
export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 70px 0;
`
