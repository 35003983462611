import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { usePopper } from 'react-popper'
import { ArrowDown, UserIcon, LockIcon, LogoutIcon, UploadIcon } from '../../Shared/SvgIcons'
import { useSession } from '../../../contexts/SessionContext'
import {
  HeaderItem,
  PopoverBody,
  PopoverList,
  PopoverHeader,
  PopoverListItem,
} from './styles'
import { Modal } from '../../Shared'
import { UploadPackage } from '../UploadPackage'

export const UserPopover = (props) => {
  const navigate = useNavigate()
  const [{ authInfo }, { logout }] = useSession()
  const [open, setOpen] = useState(false)
  const referenceElement = useRef()
  const popperElement = useRef()

  const [isUploadPackage, setisUploadPackage] = useState(false)

  const popper = usePopper(referenceElement.current, popperElement.current, {
    placement: 'bottom-end',
    modifiers: [
      { name: 'arrow' },
      {
        name: 'offset',
        options: {
          offset: [0, 12]
        }
      }
    ]
  })

  const { styles, attributes, update } = popper

  useEffect(() => {
    update && update()
  }, [open])

  const handleClickOutside = (e) => {
    if (!open) return
    const outsidePopover = !popperElement.current?.contains(e.target)
    const outsidePopoverMenu = !referenceElement.current?.contains(e.target)
    if (outsidePopover && outsidePopoverMenu) {
      setOpen(false)
    }
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 27) {
      setOpen(false)
    }
  }

  useEffect(() => {
    window.addEventListener('mouseup', handleClickOutside)
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('mouseup', handleClickOutside)
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [open])

  const popStyle = { ...styles.popper, visibility: open ? 'visible' : 'hidden', minWidth: '227px' }
  if (!open) {
    popStyle.transform = 'translate3d(0px, 0px, 0px)'
  }

  const handleGoToPage = (index) => {
    navigate(index)
    setOpen(false)
  }

  return (
    <>
      <div style={{ overflow: 'hidden' }}>
        <HeaderItem
          ref={referenceElement}
          onClick={() => setOpen(!open)}
        >
          <img src={authInfo.user['custom:image'] || '/assets/images/user.png'} alt='user' />
          <ArrowDown />
        </HeaderItem>
        <PopoverBody ref={popperElement} style={popStyle} {...attributes.popper}>
          <PopoverHeader>
            <p className='name'>{authInfo.user.given_name} {authInfo.user.family_name}</p>
            <p className='email'>{authInfo.user.email}</p>
          </PopoverHeader>
          <PopoverList>
            <PopoverListItem
              onClick={() => handleGoToPage('/my-profile')}
            >
              <UserIcon />
              <span>My Profile</span>
            </PopoverListItem>
            <PopoverListItem onClick={() => setisUploadPackage(true)}>
              <UploadIcon />
              <span>Upload Package</span>
            </PopoverListItem>
            <PopoverListItem
              onClick={() => handleGoToPage('/changepass')}
            >
              <LockIcon />
              <span>Change Password</span>
            </PopoverListItem>
          </PopoverList>
          <PopoverListItem onClick={() => {
              logout()
              .then(() => {
                navigate('/login')
              });
            }}
          >
            <LogoutIcon />
            <span>Sign Out</span>
          </PopoverListItem>
        </PopoverBody>
      </div>
      {isUploadPackage && (
        <Modal
          onClose={() => setisUploadPackage(false)}
        >
          <UploadPackage
            onClose={() => setisUploadPackage(false)}
          />
        </Modal>
      )}
    </>
  )
}
