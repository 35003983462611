import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 154px;
`

export const InnerContainer = styled.div`
  max-width: 1440px;
  margin: 0px auto;
  padding: 0px 15px;

  @media (min-width: 576px) {
    padding: 0px 20px;
  }

  @media (min-width: 768px) {
    padding: 0px 30px;
  }

  @media (min-width: 993px) {
    padding: 0px 40px;
  }

  @media (min-width: 1024px) {
    padding: 0px 60px;
  }
  
  @media (min-width: 1500px) {
    padding: 0px 30px;
  }
`

export const SectionHeader = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  > h1 {
    font-weight: 600;
    font-size: 32px;
    line-height: 130%;
    color: ${props => props.theme.colors.black};
    margin-right: 0px;
    margin-bottom: 25px;
  }
  p {
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    color: ${props => props.theme.colors.primary};
    max-width: 570px;
    font-family: 'PT Sans', sans-serif;
    font-style: italic;
  }

  @media (min-width: 769px) {
    h1 {
      font-size: 38px;
    }
    p {
      font-size: 26px;
    }
  }

  @media (min-width: 993px) {
    flex-direction: row;
    align-items: center;
    h1 {
      font-size: 45px;
      margin-bottom: 0px;
      max-width: 467px;
    }
    p {
      font-size: 28px;
    }
  }

  @media (min-width: 1200px) {
    h1 {
      font-size: 56px;
      margin-right: 100px;
    }
    p {
      font-size: 32px;
    }
  }
`

export const BoxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 80px;
  flex-wrap: wrap;
  margin-left: 0px;
  width: 100%;
  
  > div {
    margin: 20px 0px;
    width: 100%;
  }

  @media (min-width: 576px) {
    margin-left: -20px;
    width: calc(100% + 40px);
    > div {
      margin: 15px 20px;
      width: calc(50% - 40px);
    }
  }

  @media (min-width: 769px) {
    margin-left: -30px;
    width: calc(100% + 60px);
    > div {
      margin: 15px 30px;
      width: calc(33.33% - 60px);
    }
  }

  @media (min-width: 993px) {
    margin-left: -60px;
    width: calc(100% + 120px);
    > div {
      margin: 20px 60px;
      width: calc(33.33% - 120px);
    }
  }
`
