import React, { useState } from 'react'
import moment from 'moment'
import Skeleton from 'react-loading-skeleton'
import { Modal } from '../../Shared'
import { MessageIcon, PhoneIcon, BioGrapy, ClockIcon, DeleteIcon } from '../../Shared/SvgIcons'
import { DeleteUser } from '../DeleteUser'
import {
  Container,
  Header,
  InfoItem,
  ActionContainer,
  DeleteButton
} from './styles'

export const UserDetail = (props) => {
  const { isSkeleton, user, no, project_id, onDeleted } = props
  const [isDelete, setIsDelete] = useState(false)

  return (
    <>
      <Container>
        <Header>
          {isSkeleton ? (
            <>
              <Skeleton width={120} height={25} />
              <Skeleton width={35} height={25} />
            </>
          ) : (
            <>
              <p className='name'>
                {user.Attributes.find(attr => attr.Name === 'given_name').Value} {user.Attributes.find(attr => attr.Name === 'family_name').Value}
              </p>
              <p className='number'>{String(no).padStart(2, '0')}</p>
            </>
          )}
        </Header>
        <InfoItem>
          {isSkeleton ? <Skeleton width={18} height={18} /> : <MessageIcon />}
          <div>
            <label>{isSkeleton ? <Skeleton width={60} /> : 'User Email'}</label>
            <p>{isSkeleton ? <Skeleton width={100} /> : user.Attributes.find(attr => attr.Name === 'email').Value}</p>
          </div>
        </InfoItem>
        <InfoItem>
          {isSkeleton ? <Skeleton width={18} height={18} /> : <PhoneIcon />}
          <div>
            <label>{isSkeleton ? <Skeleton width={60} /> : 'Phone Number'}</label>
            <p>{isSkeleton ? <Skeleton width={100} /> : user.Attributes.find(attr => attr.Name === 'phone_number').Value}</p>
          </div>
        </InfoItem>
        <InfoItem>
          {isSkeleton ? <Skeleton width={18} height={18} /> : <BioGrapy />}
          <div>
            <label>{isSkeleton ? <Skeleton width={60} /> : 'Bio'}</label>
            <p>{isSkeleton ? <Skeleton width={100} /> : user.Attributes.find(attr => attr.Name === 'custom:bio')?.Value}</p>
          </div>
        </InfoItem>
        <InfoItem>
          {isSkeleton ? <Skeleton width={18} height={18} /> : <ClockIcon />}
          <div>
            <label>{isSkeleton ? <Skeleton width={60} /> : 'Created At'}</label>
            <p>{isSkeleton ? <Skeleton width={100} /> : moment(user.UserCreateDate).format('YYYY-MM-DD HH:mm')}</p>
          </div>
        </InfoItem>
        <ActionContainer>
          <DeleteButton onClick={() => setIsDelete(true)}>
            {isSkeleton ? <Skeleton width={24} height={24} /> : <DeleteIcon />}
            {isSkeleton ? <Skeleton width={60} style={{ marginLeft: '10px'}} /> : <span className='title'>Delete</span>}
          </DeleteButton>
        </ActionContainer>
      </Container>
      {isDelete && (
        <Modal
          onClose={() => setIsDelete(false)}
        >
          <DeleteUser
            project_id={project_id}
            user={user}
            onClose={() => setIsDelete(false)}
            onDeleted={() => onDeleted && onDeleted()}
          />
        </Modal>
      )}
    </>
  )
}