import styled from 'styled-components'

export const Container = styled.div`
  border: 1px solid #E9EFFC;
  border-radius: 8px;
  background-color: ${props => props.theme.colors.white};
  padding: 32px 28px;
  margin: 15px 0;

  @media (min-width: 992px) {
    width: calc(50% - 56px);
    margin: 28px;
  }

  @media (min-width: 1300px) {
    width: calc(33.33% - 56px);
    margin: 28px;
  }
`
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;

  p.name {
    color: ${props => props.theme.colors.black};
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    margin-right: 10px;
  }
  p.number {
    font-weight: 700;
    font-size: 32px;
    line-height: 100%;
    color: ${props => props.theme.colors.gray};
  }
`
export const InfoItem = styled.div`
  display: flex;
  margin-bottom: 34px;
  svg {
    width: 20px;
    path {
      fill: #757B82;
    }
  }
  > div {
    margin-left: 16px;
    label {
      font-size: 12px;
      line-height: 120%;
    }
    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;
      color: ${props => props.theme.colors.black};
      margin-top: 4px;
    }
  }
`

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 30px;
`
export const EditProjectButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  display: flex;
  align-items: center;
  margin-right: 36px;
  cursor: pointer;

  span.title {
    margin-left: 8px;
    font-weight: 600;
    font-size: 18px;
    color: ${props => props.theme.colors.primary};
  }
`
export const DeleteProjectButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;

  span.title {
    margin-left: 8px;
    font-weight: 600;
    font-size: 18px;
    color: ${props => props.theme.colors.red};
  }
`
