import React, { useState, useEffect, useMemo, useCallback } from 'react'
import cogoToast from 'cogo-toast';
import { SectionBar, Button, Pagination } from '../Shared'
import { DeviceIcon } from '../Shared/SvgIcons'
import { FilterPopover } from './FilterPopover'
import { SortPopover } from './SortPopover'
import { DeviceDetail } from './DeviceDetail'

import {
  Container,
  InnerContainer,
  HeaderContainer,
  TotalProjectsCount,
  DevicessList,
  PaginationWrapper
} from './styles'
import { getDevices } from '../../services'
import { getDeviceTypes } from '../../services/device-type';

export const DeviceList = () => {
  const nShowsDevices = 9;
  const [devices, setDevices] = useState([]);
  const [deviceTypes, setDeviceTypes] = useState([]);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [pagination, setPagination] = useState({ currentPage: 0, totalPages: 0 });
  const [isLoading, setIsLoading] = useState(true);

  // Sort
  const [isAscending, setIsAscending] = useState(true);
  const [isSortDate, setIsSortDate] = useState(true);

  // Filter
  const [filterDeviceName, setFilterDeviceName] = useState('');
  const [filterDeviceRegID, setFilterDeviceRegID] = useState('');
  const [filterProjectName, setFilterProjectName] = useState('');
  const [filterLocation, setFilterLocation] = useState('');
  
  const getDeviceTypeList = async () => {
    setDeviceTypes([]);

    try {
      const _deviceTypes = await getDeviceTypes();
      setDeviceTypes(_deviceTypes.map(deviceType => {
        return {value: deviceType.id, name: deviceType.name}
      }));
    } catch (error) {
      cogoToast.error(error.data.message || error.data);
    }
  };

  const getDeviceList = async () => {
    setDevices([]);

    try {
      setIsLoading(true);
      const _devices = await getDevices();
      setDevices(_devices);
      setPagination({
        currentPage: 1,
        totalPages: Math.ceil(_devices.length / nShowsDevices)
      });

    } catch (error) {
      cogoToast.error(error.data.message || error.data);

    } finally {
			setIsLoading(false);
		}
  };

  const handleChangePage = (page) => {
    setPagination({
      ...pagination,
      currentPage: page
    })
  }

  const devicesToShow = useMemo(() => {
    const devicesToShow = [];

    let sortedDevices = [];
    if (isSortDate) {
      sortedDevices = filteredDevices.sort((a, b) => {
        if (isAscending) {
          return a.msg_received_at - b.msg_received_at;
        } else {
          return b.msg_received_at - a.msg_received_at;
        }
      })
    } else {
      sortedDevices = filteredDevices.sort((a, b) => {
        if (isAscending) {
          return a.name.localeCompare(b.name);
        } else {
          return b.name.localeCompare(a.name);
        }
      })
    }

    if (pagination.currentPage === 0) return [];

    const startIndex = (pagination.currentPage - 1) * nShowsDevices;
    const endIndex = Math.min(sortedDevices.length, startIndex + nShowsDevices);
    for (let i = startIndex; i < endIndex; i++) {
      devicesToShow.push(sortedDevices[i]);
    }

    return devicesToShow;
  }, [filteredDevices, pagination, isAscending, isSortDate]);


  const filterDevices = useCallback(() => {
    let _filteredDevices = [];
    _filteredDevices = devices.filter(device => device.project?.name.toLowerCase().includes(filterProjectName.trim().toLowerCase()))
    _filteredDevices = _filteredDevices.filter(device => device.name.toLowerCase().includes(filterDeviceName.trim().toLowerCase()))
    _filteredDevices = _filteredDevices.filter(device => device.reg_id.toLowerCase().includes(filterDeviceRegID.trim().toLowerCase()))
    _filteredDevices = _filteredDevices.filter(device => device.group?.name.toLowerCase().includes(filterLocation.trim().toLowerCase()))
    
    setFilteredDevices(_filteredDevices);
    
    if (_filteredDevices.length > 0) {
      setPagination({
        currentPage: 1,
        totalPages: Math.ceil(_filteredDevices.length / nShowsDevices)
      });
    } else {
      setPagination({
        currentPage: 0,
        totalPages: Math.ceil(_filteredDevices.length / nShowsDevices)
      });
    }
  }, [
    filterProjectName,
    filterDeviceName,
    filterDeviceRegID,
    filterLocation, 
    devices
  ]);


  useEffect(() => {
    filterDevices();
  }, [
    filterProjectName,
    filterDeviceName,
    filterDeviceRegID,
    filterLocation,
    devices,
    filterDevices
  ]);


  useEffect(() => {
    getDeviceList();
    getDeviceTypeList();
  }, [])

  return (
    <Container>
      <InnerContainer>
        <SectionBar />
        <HeaderContainer>
          <div className='title'>
            <h1>All Devices</h1>
            <TotalProjectsCount>You have {devices.length} devices.</TotalProjectsCount>
          </div>
          <div className='action'>
            <FilterPopover
              filterDeviceName={filterDeviceName}
              filterDeviceRegID={filterDeviceRegID}
              filterProjectName={filterProjectName}
              filterLocation={filterLocation}
              setFilterDeviceName={setFilterDeviceName}
              setFilterDeviceRegID={setFilterDeviceRegID}
              setFilterProjectName={setFilterProjectName}
              setFilterLocation={setFilterLocation}
            />
            
            <SortPopover
              isSortDate={isSortDate}
              isAscending={isAscending}
              setIsAscending={setIsAscending}
              setIsSortDate={setIsSortDate}
            />
          </div>
        </HeaderContainer>
        {isLoading && (
            <DevicessList>
              {[...Array(6).keys()].map(i => (
                <DeviceDetail key={i} isSkeleton />
              ))}
            </DevicessList>
          )}
          {!isLoading && (
            <DevicessList>
              {devicesToShow.map((device, key) => (
                <DeviceDetail device={device} key={key} 
                  no={ (pagination.currentPage - 1) * nShowsDevices + key + 1 }
                  deviceTypes={deviceTypes}
                  onDeleted={getDeviceList} 
                  canManage={true}
                />
              ))}
            </DevicessList>
          )}
        
        {devicesToShow.length === 0 ?
          <h3 style={{textAlign:'center', margin: 30}}>No Devices</h3>
        :
          <PaginationWrapper>
            <Pagination
              currentPage={pagination.currentPage}
              totalPages={pagination.totalPages}
              handleChangePage={handleChangePage}
            />
          </PaginationWrapper>
        }
      </InnerContainer>
    </Container>
  )
}
