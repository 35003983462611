import React, { useState } from 'react'
import cogoToast from 'cogo-toast'
import { useTheme } from 'styled-components'
import { Button } from '../../../Shared'
import {
  Container,
  Header,
  Description
} from './styles'

import { deleteLocation } from '../../../../services'

export const DeleteLocation = (props) => {
  const { location, onDeleted } = props

  const theme = useTheme()
  const [loading, setLoading] = useState(false)

  const handleDeleteLocation = async () => {
    if (loading) return;
    try {
      setLoading(true);
			await deleteLocation(location.project_id, location.id);
      cogoToast.success('Location was deleted successfully.');
			onDeleted && onDeleted()

		} catch (error) {
			cogoToast.error(error.data.message || error.data);

		} finally {
      console.log('finally')
			setLoading(false)
		}
  }

  return(
    <Container>
      <Header>
        <img src={theme.icons.deleteIcon} alt='deleteIcon' />
        <h1>Are You Sure ?</h1>
      </Header>
      <Description>Please confirm that you want to delete this location.</Description>
      <Button
        color='red'
        isLoading={loading}
        onClick={handleDeleteLocation}
      >
        Confirm & Delete
      </Button>
    </Container>
  )
}
