import React, { useState } from 'react'
import cogoToast from 'cogo-toast';
import { PasswordIcon, CheckIcon } from '../Shared/SvgIcons'
import { Input, Button } from '../Shared'

import {
  Container,
  InnerContainer,
  FormContainer,
  HeaderContainer,
  PhotoWrapper,
  MainInfoContainer,
  Row,
  FormController,
  MainFormContainer
} from './styles'

import { changePassword } from '../../services/auth';

export const ChangePassword = () => {
  const [password, setPassword] = useState('')
  const [confirmPass, setConfirmPass] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isLoading) return;
    if (password !== confirmPass) return;

    setIsLoading(true);

    try {
      await changePassword(password);
			cogoToast.success('Password was updated successfully.');
      setPassword('')
      setConfirmPass('')

    } catch (error) {
      cogoToast.error(error.data.message || error.data);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <InnerContainer>
        <FormContainer>
          <HeaderContainer>
            <PhotoWrapper>
              <PasswordIcon />
            </PhotoWrapper>
            <MainInfoContainer>
              <p className='name'>Change Password</p>
              <p className='role'>Enter your new password and confirm it then submit</p>
            </MainInfoContainer>
          </HeaderContainer>
          <MainFormContainer onSubmit={handleSubmit}>
            <Row>
              <FormController>
                <label>New Password</label>
                <Input
                  type='password'
                  required
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
              </FormController>
              <FormController>
                <label>Confirm Password</label>
                <Input
                  type='password'
                  required
                  value={confirmPass}
                  onChange={(event) => setConfirmPass(event.target.value)}
                />
              </FormController>
            </Row>
            <Button
              color='primary'
              isLoading={isLoading}
            >
              <CheckIcon />
              <span>Change Password</span>
            </Button>
          </MainFormContainer>
        </FormContainer>
      </InnerContainer>
    </Container>
  )
}