import AWS from 'aws-sdk';
import axios from 'axios';

export const signIn = ({ email, password }) => {
	return axios.post(`${process.env.REACT_APP_SERVER_URL}/auth/signin`, { email, password })
		.then(data => data.data)
		.catch(error => { throw error.response });
};

export const forgotPassword = (email) => {
	return axios.post(`${process.env.REACT_APP_SERVER_URL}/auth/forgot-password`, { email })
		.then(data => data.data)
		.catch(error => { throw error.response });
};

export const confirmPassword = (email, code, password) => {
	return axios.post(`${process.env.REACT_APP_SERVER_URL}/auth/confirm-password`, { email, code, password })
		.then(data => data.data)
		.catch(error => { throw error.response });
};

export const changePassword = (password) => {
	return axios.post(`${process.env.REACT_APP_SERVER_URL}/users/update-password`, { password })
		.then(data => data.data)
		.catch(error => { throw error.response });
};

export const updateProfile = (params) => {
	return axios.post(`${process.env.REACT_APP_SERVER_URL}/users/update-profile`, params)
		.then(data => data.data)
		.catch(error => { throw error.response });
};

export const uploadPackageToS3 = async (idToken, file) => {
  const poolKey = `cognito-idp.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${process.env.REACT_APP_AWS_USER_POOLID}`;
  AWS.config.region = process.env.REACT_APP_AWS_REGION;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_IDENTITY_POOLID,
    Logins: {
      [poolKey]: idToken
    }
  });

  await AWS.config.credentials.getPromise();

  const s3 = new AWS.S3();
  const params = {
    Bucket: process.env.REACT_APP_AWS_S3_BUCKET,
    Key: `packages.zip`,
    Body: file,
    ACL: 'public-read'
  };

  const s3Obj = await s3.upload(params).promise();

  await axios.post(`${process.env.REACT_APP_SERVER_URL}/upload/package`, { url: s3Obj.Location })
};

export const uploadPhoto = async (idToken, file) => {
  const poolKey = `cognito-idp.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${process.env.REACT_APP_AWS_USER_POOLID}`;
  AWS.config.region = process.env.REACT_APP_AWS_REGION;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_IDENTITY_POOLID,
    Logins: {
      [poolKey]: idToken
    }
  });

  await AWS.config.credentials.getPromise();

  const s3 = new AWS.S3();
  const params = {
    Bucket: process.env.REACT_APP_AWS_S3_BUCKET,
    Key: `${Date.now()}-${file.name}`,
    Body: file,
    ACL: 'public-read'
  };

  const s3Obj = await s3.upload(params).promise();
	return s3Obj.Location;
}