import axios from 'axios';
import { Navigate } from 'react-router-dom'
import { useSession } from '../../contexts/SessionContext'

export const ProtectedRoute = ({ children }) => {
  const [{ authInfo }] = useSession()

  if (!authInfo) {
    axios.defaults.headers.get["Authorization"] = ``;
    axios.defaults.headers.post["Authorization"] = ``;
    axios.defaults.headers.put["Authorization"] = ``;
    axios.defaults.headers.delete["Authorization"] = ``;

    return <Navigate to="/login" replace />

  } else {
    axios.defaults.headers.get["Authorization"] = `Bearer ${authInfo.AuthenticationResult.IdToken}`;
    axios.defaults.headers.post["Authorization"] = `Bearer ${authInfo.AuthenticationResult.IdToken}`;
    axios.defaults.headers.put["Authorization"] = `Bearer ${authInfo.AuthenticationResult.IdToken}`;
    axios.defaults.headers.delete["Authorization"] = `Bearer ${authInfo.AuthenticationResult.IdToken}`;
  }

  return children
}