import styled, { css } from 'styled-components'

export const HeaderItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  img {
    margin-right: 8px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px solid #DDDDDD;
    background-color: #DDDDDD;
  }
`

export const PopoverBody = styled.div`
  background-color: ${props => props.theme.colors.white};
  z-index: 1001;
  border: 1px solid #E9EFFC;
  box-shadow: 0px 2px 30px rgba(215, 224, 235, 0.5);
  border-radius: 4px;
  padding: 18px;
`
export const PopoverHeader = styled.div`
  padding-bottom: 19px;
  border-bottom: 1px solid #E0E0E0;
  p.name {
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: ${props => props.theme.colors.black};
    margin-bottom: 8px;
  }
  p.email {
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    color: #757B82;
  }
`

export const PopoverList = styled.div`
  padding: 15px 0;
  margin-bottom: 8px;
  border-bottom: 1px solid #E0E0E0;
`

export const PopoverListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
  cursor: pointer;

  svg {
    margin-right: 8px;
  }
`
