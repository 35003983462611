import React, { useState } from 'react'
import cogoToast from 'cogo-toast'
import { NewProjectIcon } from '../../../Shared/SvgIcons'
import { Button, Input, Select } from '../../../Shared'
import {
  Container,
  InnerContainer,
  Header,
  Description,
  FormController
} from './styles'
import { addDevice } from '../../../../services'

export const AddDevice = (props) => {
  const { locations, deviceTypes, project_id, onAdded } = props

  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [regId, setRegId] = useState('')
  const [location, setLocation] = useState('')
  const [deviceType, setDeviceType] = useState('')
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (loading) return;

    if (!location) {
      return cogoToast.error('Choose the location.');
    }

    if (!deviceType) {
      return cogoToast.error('Choose the device type.');
    }

    try {
      setLoading(true);
      await addDevice(project_id, regId, name, location, deviceType);

      onAdded && onAdded();
			cogoToast.success('Device was created successfully.')

    } catch (error) {
      cogoToast.error(error.data.message || error.data);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      <InnerContainer onSubmit={handleSubmit}>
        <Header>
          <NewProjectIcon />
          <h1>Add New Device</h1>
        </Header>
        <Description>Please fill out the device details and then submit.</Description>
        <FormController>
          <label>Device Name</label>
          <Input
            type="text"
            value={name}
            required
            onChange={(event) => setName(event.target.value)}
          />
        </FormController>

        <FormController>
          <label>Reg ID</label>
          <Input
            type="text"
            value={regId}
            required
            onChange={(event) => setRegId(event.target.value)}
          />
        </FormController>

        <FormController>
          <label>Location</label>
          <Select
            options={locations}
            defaultValue={location}
            onChange={setLocation}
          />
        </FormController>

        <FormController>
          <label>Device Type</label>
          <Select
            options={deviceTypes}
            defaultValue={deviceType}
            onChange={setDeviceType}
          />
        </FormController>

        <Button color='darkBlue' isLoading={loading}>Submit</Button>
      </InnerContainer>
    </Container>
  )
}
