import React from 'react'
import { DeviceTypeList as DeviceTypeListController } from '../../components/DeviceTypeList'

export const DeviceTypeList = (props) => {
  return (
    <>
      <DeviceTypeListController {...props} />
    </>
  )
}
