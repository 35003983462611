import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 170px;
`

export const InnerContainer = styled.div`
  max-width: 1440px;
  margin: 0px auto;
  padding: 0px 15px;
  display: flex;
  flex-direction: column;

  > div {
    width: 100%;
  }

  @media (min-width: 576px) {
    padding: 0px 20px;
  }

  @media (min-width: 769px) {
    padding: 0px 30px;
    flex-direction: row;
    > div {
      width: 50%;
    }
  }

  @media (min-width: 993px) {
    padding: 0px 40px;
  }

  @media (min-width: 1024px) {
    padding: 0px 60px;
  }
  
  @media (min-width: 1500px) {
    padding: 0px 30px;
  }
`

export const LeftContainer = styled.div`
  > h1 {
    font-weight: 600;
    font-size: 35px;
    line-height: 130%;
    margin-bottom: 20px;
    color: ${props => props.theme.colors.black};
  }
  > p {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    margin-bottom: 40px;
  }
  @media (min-width: 769px) {
    > h1 {
      margin-bottom: 20px;
      font-size: 40px;
    }
    > p {
      font-size: 18px;
      margin-bottom: 58px;
      max-width: 400px;
    }
  }

  @media (min-width: 993px) {
    > h1 {
      margin-bottom: 22px;
      font-size: 45px;
    }
    > p {
      font-size: 22px;
      margin-bottom: 67px;
    }
  }

  @media (min-width: 1200px) {
    > h1 {
      margin-bottom: 24px;
      font-size: 56px;
    }
    > p {
      font-size: 24px;
      margin-bottom: 77px;
    }
  }
`

export const RightContainer = styled.div`
  padding-left: 0px;
  margin-top: 30px;

  @media (min-width: 769px) {
    margin-top: 0px;
    padding-left: 20px;
  }

  @media (min-width: 1200px) {
    padding-left: 40px;
  }
`

export const ContactBox = styled.div`
  display: flex;
  margin-bottom: 50px;
  svg {
    margin-right: 25px;
    width: 30px;
    margin-top: 5px;
  }
  > div {
    h2 {
      font-weight: 600;
      font-size: 24px;
      line-height: 160%;
      margin-bottom: 5px;
    }
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 160%;
    }
  }
`

export const SocialListWrapper = styled.div`
  padding-top: 20px;
  h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 160%;
    margin-bottom: 20px;
  }
`

export const SocialList = styled.div`
  display: flex;
  align-items: center;
  a {
    margin: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      svg {
        transition: 0.3s ease-out;
        transform: scale(1.04);
      }
    }
  }
`

export const ContactForm = styled.form`
  button {
    height: 46px;
    width: 170px;
    font-size: 18px;
    margin-right: 20px;
    font-weight: 700;
    margin-top: 35px;
  }

  @media (min-width: 768px) {
    button {
      height: 46px;
      width: 175px;
      font-size: 20px;
      margin-top: 35px;
    }
  }

  @media (min-width: 993px) {
    button {
      height: 58px;
      width: 190px;
      font-size: 22px;
      margin-top: 40px;
    }
  }

  @media (min-width: 1200px) {
    button {
      margin-top: 50px;
      height: 69px;
      width: 205px;
      font-size: 24px;
    }
  }
`

export const FormController = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  label {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 160%;
  }

  input, textarea {
    padding: 10px 15px;
    font-size: 16px;
  }
  textarea {
    height: 250px;
  }

  @media (min-width: 768px) {
    margin-bottom: 32px;
    label {
      font-size: 24px;
      margin-bottom: 18px;
    }
    input, textarea {
      font-size: 24px;
      border: 1px solid ${props => props.theme.colors.lightGray} ;
    }
  }
`

export const TermsAgree = styled.div`
  display: flex;
  align-items: center;
  span {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 24px;
      height: 24px;
      color: ${props => props.theme.colors.lightGray};
      &.active {
        color: ${props => props.theme.colors.primary};
      }
    }
  }
  p {
    margin-left: 12px;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    user-select: none;
  }
`
