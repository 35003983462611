import axios from 'axios';

export const getUserByEmail = (email) => {
	return axios.post(`${process.env.REACT_APP_SERVER_URL}/users/get-user`, { email })
		.then(data => data.data)
		.catch(error => { throw error.response });
}

export const getUsers = () => {
	const url = `${process.env.REACT_APP_SERVER_URL}/users`;
	return axios.get(url)
		.then(data => data.data)
		.catch(error => { throw error.response });
}

export const deleteUser = (id) => {
	return axios.delete(`${process.env.REACT_APP_SERVER_URL}/users/${id}`)
		.then(data => data.data)
		.catch(error => { throw error.response });
}