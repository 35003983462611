import axios from 'axios';

export const getDevices = (continueKey) => {
	return axios.get(`${process.env.REACT_APP_SERVER_URL}/devices?continueKey=${continueKey ? continueKey : ''}`)
		.then(data => data.data)
		.catch(error => { throw error.response });
};

export const updateDevice = (projectId, regId, name, deviceType) => {
	return axios.put(`${process.env.REACT_APP_SERVER_URL}/projects/${projectId}/devices/${regId}`,
		{ name: name, device_type_id: deviceType })
		.then(data => data.data)
		.catch(error => { throw error.response });
};

export const getDeviceMessages = (projectId, regId, continueKey) => {
	return axios.get(`${process.env.REACT_APP_SERVER_URL}/projects/${projectId}/devices/${regId}/messages?continueKey=${continueKey ? continueKey : ''}`)
		.then(data => data.data)
		.catch(error => { throw error.response });
};

export const deleteDevice = (projectId, regId) => {
	return axios.delete(`${process.env.REACT_APP_SERVER_URL}/projects/${projectId}/devices/${regId}`)
		.then(data => data.data)
		.catch(error => { throw error.response });
}

export const getMessages = (regId, nextToken) => {
	return axios.get(`${process.env.REACT_APP_SERVER_URL}/devices/${regId}/messages?nextToken=${nextToken || ''}`)
		.then(data => data.data)
		.catch(error => { throw error.response });
};