import { useState, useEffect, useMemo, useCallback } from "react";
import cogoToast from "cogo-toast";
import { Pagination } from "../../Shared";
import { getProjectDevices } from "../../../services";
import { DeviceDetail } from "../../DeviceList/DeviceDetail";
import { Modal } from '../../Shared/Modal'
import {
  PaginationWrapper
} from '../../DeviceList/styles';
import { Content } from "../styles";
import { AddDevice } from "./AddDevice";
import { getDeviceTypes } from "../../../services/device-type";

export const DeviceList = (props) => {
  const {
    project_id,
    project,
    filterDeviceName,
    filterDeviceRegID,
    filterLocationId,
    isAscending,
    isSortDate,
    isAddDevice,
    onAdded,
    locations
  } = props;
  const nShowsDevices = 9;

  const [devices, setDevices] = useState([]);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [pagination, setPagination] = useState({ currentPage: 0, totalPages: 0 });
  const [isLoading, setIsLoading] = useState(true);
  const [deviceTypes, setDeviceTypes] = useState([]);

  const getDeviceTypeList = async () => {
    setDeviceTypes([]);

    try {      
      const _deviceTypes = await getDeviceTypes();
      setDeviceTypes(_deviceTypes.map(deviceType => {
        return {value: deviceType.id, name: deviceType.name}
      }));
    } catch (error) {
      cogoToast.error(error.data.message || error.data);
    }
  };

  const getDeviceList = async () => {
    setDevices([]);

    try {
      setIsLoading(true);
      const _devices = await getProjectDevices(project_id);
      setDevices(_devices);
      setPagination({
        currentPage: 1,
        totalPages: Math.ceil(_devices.length / nShowsDevices)
      });

    } catch (error) {
      cogoToast.error(error.data.message || error.data);

    } finally {
			setIsLoading(false);
		}
  };

  const handleChangePage = (page) => {
    setPagination({
      ...pagination,
      currentPage: page
    })
  };

  const devicesToShow = useMemo(() => {
    const devicesToShow = [];

    let sortedDevices = [];
    if (isSortDate) {
      sortedDevices = filteredDevices.sort((a, b) => {
        if (isAscending) {
          return a.msg_received_at - b.msg_received_at;
        } else {
          return b.msg_received_at - a.msg_received_at;
        }
      })
    } else {
      sortedDevices = filteredDevices.sort((a, b) => {
        if (isAscending) {
          return a.name.localeCompare(b.name);
        } else {
          return b.name.localeCompare(a.name);
        }
      })
    }

    if (pagination.currentPage === 0) return [];

    const startIndex = (pagination.currentPage - 1) * nShowsDevices;
    const endIndex = Math.min(sortedDevices.length, startIndex + nShowsDevices);
    for (let i = startIndex; i < endIndex; i++) {
      devicesToShow.push(sortedDevices[i]);
    }

    return devicesToShow;
  }, [filteredDevices, pagination, isAscending, isSortDate]);

  const filterDevices = useCallback(() => {
    let _filteredDevices = [];
    _filteredDevices = devices.filter(device => device.name.toLowerCase().includes(filterDeviceName.trim().toLowerCase()))
    _filteredDevices = _filteredDevices.filter(device => device.reg_id.toLowerCase().includes(filterDeviceRegID.trim().toLowerCase()))
    if (filterLocationId) {
      _filteredDevices = _filteredDevices.filter(device => device.group_id === filterLocationId)
    }
    
    setFilteredDevices(_filteredDevices);
    
    if (_filteredDevices.length > 0) {
      setPagination({
        currentPage: 1,
        totalPages: Math.ceil(_filteredDevices.length / nShowsDevices)
      });
    } else {
      setPagination({
        currentPage: 0,
        totalPages: Math.ceil(_filteredDevices.length / nShowsDevices)
      });
    }
  }, [
    filterDeviceName,
    filterDeviceRegID,
    filterLocationId,
    devices
  ]);

  useEffect(() => {
    filterDevices();
  }, [
    filterDeviceName,
    filterDeviceRegID,
    filterLocationId,
    devices,
    filterDevices
  ]);

  useEffect(() => {
    getDeviceList();
    getDeviceTypeList();
  }, []);

  return (
    <>
      {
        isLoading ?
          <Content>
            {[...Array(6).keys()].map(i => (
              <DeviceDetail key={i} isSkeleton />
            ))}
          </Content>
        :
        <div>
          <Content>
            {devicesToShow.map((device, key) => (
              <DeviceDetail
                key={key}
                project_id={project_id}
                isProjectHide={true}
                device={device}
                deviceTypes={deviceTypes}
                no={(pagination.currentPage - 1) * nShowsDevices + key + 1}
                onDeleted={() => getDeviceList()} 
                canManage={project && project.role !== 'user'}
              />
            ))}
          </Content>

          { devicesToShow.length === 0 ?
            <h3 style={{textAlign:'center', margin: 30}}>No Devices</h3>
            :
            <PaginationWrapper>
              <Pagination
                currentPage={pagination.currentPage}
                totalPages={pagination.totalPages}
                handleChangePage={handleChangePage}
              />
            </PaginationWrapper>
           }
          
        </div>
      }
      
      {isAddDevice && (
        <Modal
          onClose={() => {
            onAdded && onAdded();
          }}
        >
          <AddDevice
            project_id={project_id}
            deviceTypes={deviceTypes}
            locations={locations.filter(location => location.value !== '')}
            onClose={() => {}}
            onAdded={() => {
              onAdded && onAdded();
              getDeviceList();
            }}
          />
        </Modal>
      )}
    </>
  );
};