import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  padding: 20px 0px;
`

export const NumberWrapper = styled.div`
  font-family: 'Maven Pro';
  font-weight: 400;
  font-size: 48px;
  line-height: 100%;
  color: ${props => props.theme.colors.darkGray};
  margin-right: 32px;
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`

export const ContentWrapper = styled.div`
  flex: 1;
`

export const ContentHeader = styled.div`
  background: #E0EEFF;
  border-radius: 16px 16px 0px 0px;
  padding: 16px 16px 20px 16px;
  display: flex;
  justify-content: space-between;
  > div:last-child {
    margin-right: 0px;
  }

  @media (min-width: 576px) {
    padding: 20px;
    justify-content: flex-start;
  }
`

export const ContentFooter = styled.div`
  background: #FFFFFF;
  border: 1px solid #E9EFFC;
  border-radius: 0px 0px 16px 16px;
  padding: 20px;
  span {
    font-family: 'Maven Pro';
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
  }
`

export const InfoItem = styled.div`
  display: flex;
  margin-right: 15px;
  svg {
    width: 20px;
    min-width: 20px;
    path {
      fill: #757B82;
    }
  }
  > div {
    margin-left: 12px;
    label {
      font-size: 12px;
      line-height: 120%;
    }
    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 120%;
      color: ${props => props.theme.colors.black};
      margin-top: 4px;
    }
  }

  @media (min-width: 576px) {
    margin-right: 30px;
    > div {
      margin-left: 16px;
      p {
        font-size: 16px;
      }
    }
  }
  @media (min-width: 768px) {
    margin-right: 48px;
  }
`