import React, { useState, useCallback } from 'react'
import { useTheme } from 'styled-components'
import cogoToast from 'cogo-toast'
import _ from 'lodash'
import utils from '../../../utils';
import { Button, Input } from '../../Shared'
import {
  Container,
  InnerContainer,
  Header,
  Description,
  FormController
} from './styles'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import { getUserByEmail, setOriginAdmin } from '../../../services';

export const SetAdmin = (props) => {
  const {
    project,
    onClose
  } = props
  
  const theme = useTheme()
  const [loading, setLoading] = useState(false)
  const [searching, setSearching] = useState(true)
  const [userFound, setUserFound] = useState(false)
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] =  useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [bio, setBio] = useState('')


  const getUserFromEmail = async (email) => {
		try {
      setSearching(true);
      setUserFound(false);
			
      const user = await getUserByEmail(email);
			setFirstName(user.UserAttributes.find(attr => attr.Name === 'given_name').Value)
      setLastName(user.UserAttributes.find(attr => attr.Name === 'family_name').Value)
      setPhoneNumber(user.UserAttributes.find(attr => attr.Name === 'phone_number').Value)
      setBio(user.UserAttributes.find(attr => attr.Name === 'custom:bio').Value)

      setUserFound(true);
		} catch (error) {
      setFirstName('')
      setLastName('')
      setPhoneNumber('')
      setBio('')
		} finally {
      setSearching(false);
		}
	}

  const debouncedGetUserByEmail = useCallback(_.debounce(getUserFromEmail, 300), []);

  const handleChangeEmail = (event) => {
    const email = event.target.value;
    setEmail(email);

    if (utils.validateEmail(email)) {
      debouncedGetUserByEmail(email)
    }
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (loading) return;
    try {
      setLoading(true);

      await setOriginAdmin(project.id, {
				admin: {
					email, firstName, lastName, phoneNumber, bio
				}
			});

			project.admin_user = {
				Attributes: [{
					Name: 'email',
					Value: email
				}, {
					Name: 'given_name',
					Value: firstName
				}, {
					Name: 'family_name',
					Value: lastName
				}]
			};

			onClose && onClose();
			cogoToast.success('Project admin was updated successfully.')

    } catch (error) {
      cogoToast.error(error.data.message || error.data);
    } finally {
      setLoading(false);
    }
    setLoading(true);
  };

  const handleEditProject = () => {
    // code here
    setLoading(true)
    // onClose && onClose()
  }

  return (
    <Container>
      <InnerContainer onSubmit={handleSubmit}>
        <Header>
          <img src={theme.icons.editIcon} alt='' />
          <h1>Set Admin</h1>
        </Header>
        <Description>Please fill out the admin details and submit.</Description>
        <FormController>
          <label>Admin Email</label>
          <Input
            type="email"
            value={email}
            required
            onChange={handleChangeEmail}
          />
        </FormController>

        {!searching &&
        <>
          <FormController>
            <label>First Name</label>
            <Input
              type="text"
              disabled={userFound}
              value={firstName}
              required
              onChange={(event) => setFirstName(event.target.value)}
            />
          </FormController>

          <FormController>
            <label>Last Name</label>
            <Input
              type="text"
              disabled={userFound}
              value={lastName}
              required
              onChange={(event) => setLastName(event.target.value)}
            />
          </FormController>

          <FormController>
            <label>Phone Number</label>
             <PhoneInput
              disabled={userFound}
              placeholder="Enter phone number"
              value={phoneNumber}
              required
              onChange={setPhoneNumber}
            />
          </FormController>

          <FormController>
            <label>Bio</label>
            <Input
              type="text"
              disabled={userFound}
              value={bio}
              onChange={(event) => setBio(event.target.value)}
            />
          </FormController>
       
          <Button color='darkBlue' isLoading={loading}>Submit</Button>
        </>
        }

      </InnerContainer>
    </Container>
  )
}
