import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.white};
`

export const InnerContainer = styled.form`
  width: 100%;
  max-width: 557px;
  > button {
    margin-top: 70px;
    padding: 15px 18px;
    font-weight: 700;
    font-size: 20px;
  }

  @media (min-width: 769px) {
    > button {
      margin-top: 70px;
      padding: 16px 21px;
      font-size: 22px;
    }
  }

  @media (min-width: 1440px) {
    > button {
      margin-top: 80px;
      padding: 19px 24px;
      font-size: 24px;
    }
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 28px;
  > svg {
    width: 40px;
    height: 32px;
    min-width: 40px;
    margin-right: 10px;
    path {
      fill: ${props => props.theme.colors.white};
    }
  }
  h1 {
    font-weight: 700;
    font-size: 32px;
  }
  @media (min-width: 576) {
    margin-bottom: 30px;
    > svg {
      width: 45px;
      height: 34px;
      margin-right: 20px;
    }
    h1 {
      font-size: 40px;
    }
  }
  @media (min-width: 769px) {
    > svg {
      width: 56px;
      height: 43px;
      margin-right: 25px;
    }
    h1 {
      font-size: 45px;
    }
  }

  @media (min-width: 1440px) {
    margin-bottom: 32px;
    > svg {
      width: 71px;
      height: 56px;
      margin-right: 32px;
    }
    h1 {
      font-size: 56px;
    }
  }
`

export const Description = styled.p`
  font-weight: 400;
  font-size: 22px;
  line-height: 150%;
  text-align: center;
  margin-bottom: 50px;

  @media (min-width: 769px) {
    font-size: 25px;
    margin-bottom: 80px;
  }

  @media (min-width: 1440px) {
    font-size: 28px;
    margin-bottom: 100px;
  }
`

export const FormController = styled.div`
  margin-bottom: 20px;
  padding: 1px 1px 1px 15px;
  background-color: ${props => props.theme.colors.white};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-weight: 400;
    font-size: 16px;
    color: ${props => props.theme.colors.headingColor};
    flex: 1;
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  button {
    font-weight: 700;
    font-size: 16px;
    padding: 12px 18px;
  }

  @media (min-width: 769px) {
    button {
      font-size: 18px;
      padding: 15px 23px;
    }
  }

  @media (min-width: 1440px) {
    button {
      font-size: 20px;
      padding: 19px 26px;
    }
  }
`