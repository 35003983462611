import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  .contact-us {
    height: 46px;
    width: 130px;
    font-size: 20px;
    margin-right: 20px;
    font-weight: 700;
    white-space: nowrap;
  }


  @media (min-width: 768px) {
    margin-top: 32px;
    .contact-us {
      height: 46px;
      width: 120px;
      font-size: 20px;
      margin-right: 20px;
    }
  }

  @media (min-width: 993px) {
    margin-top: 42px;
    .contact-us {
      height: 58px;
      width: 150px;
      font-size: 22px;
      margin-right: 30px;
    }
  }

  @media (min-width: 1200px) {
    margin-top: 68px;
    .contact-us {
      height: 69px;
      width: 174px;
      font-size: 24px;
      margin-right: 46px;
    }
  }
`

export const PlayWrapper = styled.div`
  display: flex;
  align-items: center;
  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    color: ${props => props.theme.colors.primary};
    display: none;
  }
  button {
    margin-right: 10px;
    padding: 12px 11px 12px 13px;
    &:hover {
      transform: scale(1.03);
    }
  }

  @media (min-width: 380px) {
    span {
      display: block;
    }
  }

  @media (min-width: 768px) {
    span {
      font-size: 18px;
    }
    button {
      margin-right: 10px;
      padding: 12px 11px 12px 13px;
    }
  }

  @media (min-width: 993px) {
    span {
      font-size: 22px;
    }
    button {
      margin-right: 15px;
      padding: 14px 13px 14px 15px;
    }
  }

  @media (min-width: 1200px) {
    span {
      font-size: 24px;
    }
    button {
      padding: 17px 16px 17px 18px;
    }
  }
`