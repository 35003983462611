import React from 'react'
import Skeleton from 'react-loading-skeleton'
import moment from 'moment'
import { ClockIcon, PriorityIcon, StatusIcon } from '../../Shared/SvgIcons'
import {
  Container,
  NumberWrapper,
  ContentWrapper,
  ContentHeader,
  InfoItem,
  ContentFooter
} from './styles'

export const Message = (props) => {
  const {
    message,
    isSkeleton,
    no
  } = props

  return (
    <Container>
      <NumberWrapper>
        {isSkeleton
          ? <Skeleton width={30} height={30} />
          : <span>{no}</span>}
      </NumberWrapper>
      <ContentWrapper>
        <ContentHeader>
          <InfoItem>
            {isSkeleton ? <Skeleton width={18} height={18} /> : <PriorityIcon />}
            <div>
              <label>{isSkeleton ? <Skeleton width={60} /> : 'Priority'}</label>
              <p>{isSkeleton ? <Skeleton width={100} /> : message.priority}</p>
            </div>
          </InfoItem>
          <InfoItem>
            {isSkeleton ? <Skeleton width={18} height={18} /> : <StatusIcon />}
            <div>
              <label>{isSkeleton ? <Skeleton width={60} /> : 'Status'}</label>
              <p>{isSkeleton ? <Skeleton width={100} /> : message.status}</p>
            </div>
          </InfoItem>
          <InfoItem>
            {isSkeleton ? <Skeleton width={18} height={18} /> : <ClockIcon />}
            <div>
              <label>{isSkeleton ? <Skeleton width={60} /> : 'Created At'}</label>
              <p>{isSkeleton ? <Skeleton width={100} /> : message.time ? moment(message.time).format('YYYY-MM-DD HH:mm A') : ''}</p>
            </div>
          </InfoItem>
        </ContentHeader>
        <ContentFooter>
          {isSkeleton ? <Skeleton width={120} /> : <span>{message.message}</span>}
        </ContentFooter>
      </ContentWrapper>
    </Container>
  )
}
