import React, { useRef, useState } from 'react'
import cogoToast from 'cogo-toast'
import { PhotoIcon, CheckIcon } from '../Shared/SvgIcons'
import { Input, Button } from '../Shared'
import {
  Container,
  InnerContainer,
  HeaderContainer,
  PhotoWrapper,
  MainInfoContainer,
  ChangePhotoButton,
  Row,
  FormController,
  ProfileForm,
  MainFormContainer
} from './styles'
import { useSession } from '../../contexts/SessionContext'
import { updateProfile, uploadPhoto } from '../../services/auth'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

export const Profile = () => {
  const [{ authInfo }, {updateUser}] = useSession()
  const [photoState, setPhotoState] = useState(authInfo.user['custom:image'] || '/assets/images/user.png')
  const inputRef = useRef()
  const fileRef = useRef();
  const [firstName, setFirstName] = useState(authInfo.user.given_name)
  const [lastName, setLastName] = useState(authInfo.user.family_name)
  const [phoneNumber, setPhoneNumber] = useState(authInfo.user.phone_number)
  const [bio, setBio] = useState(authInfo.user['custom:bio'])
  const [pushover, setPushover] = useState(authInfo.user['custom:pushover'])
  const [isLoading, setIsLoading] = useState(false);

  const handleFiles = (e) => {
    e.preventDefault()
    e.stopPropagation()
    let files = e.target.files
    if (files.length === 1) {
      const type = files[0].type.split('/')[0]
      if (type === 'image') {
        fileRef.current = files[0]
        const reader = new window.FileReader()
        reader.readAsDataURL(files[0])
        reader.onload = () => {
          setPhotoState(reader.result)
        }
      }
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isLoading) return;
    try {
      setIsLoading(true);

      let picture = "";
      if (fileRef.current) {
        picture = await uploadPhoto(authInfo.AuthenticationResult.IdToken, fileRef.current);
      }

      const profile = {
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber,
        bio: bio,
        pushover: (pushover || ''),
        picture: picture,
      };
      await updateProfile(profile);
      await updateUser(profile);
      cogoToast.success('Your profile was updated successfully.');

    } catch (error) {
      cogoToast.error(error.data.message || error.data);

    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container>
      <InnerContainer>
        <ProfileForm>
          <HeaderContainer>
            <PhotoWrapper>
              <img src={photoState} alt='user' />
            </PhotoWrapper>
            <MainInfoContainer>
              <p className='name'>{authInfo.user.given_name} {authInfo.user.family_name}</p>
              <p className='role'>{authInfo.user['custom:bio']}</p>
              <ChangePhotoButton
                onClick={() => inputRef.current.click()}
              >
                <PhotoIcon />
                <span>Change Profile Photo</span>
                <input
                  ref={inputRef}
                  type='file'
                  accept='image/png, image/jpeg, image/jpg'
                  onChange={handleFiles}
                />
              </ChangePhotoButton>
            </MainInfoContainer>
          </HeaderContainer>

          <MainFormContainer onSubmit={handleSubmit}>
            <Row>
              <FormController>
                <label>Email</label>
                <Input
                  readOnly
                  defaultValue={authInfo.user.email}
                />
              </FormController>
              <FormController>
                <label>Phone Number</label>
                <PhoneInput
                  value={phoneNumber}
                  required
                  onChange={setPhoneNumber}
                />
              </FormController>
            </Row>
            <Row>
              <FormController>
                <label>First Name</label>
                <Input
                  defaultValue={firstName}
                  required
                  onChange={(event) => setFirstName(event.target.value)}
                />
              </FormController>
              <FormController>
                <label>Last Name</label>
                <Input
                  defaultValue={lastName}
                  required
                  onChange={(event) => setLastName(event.target.value)}
                />
              </FormController>
            </Row>
            <Row>
              <FormController>
                <label>Bio</label>
                <Input
                  defaultValue={bio}
                  required
                  onChange={(event) => setBio(event.target.value)}
                />
              </FormController>
              <FormController>
                <label>PushOver Key</label>
                <Input
                  defaultValue={pushover}
                  onChange={(event) => setPushover(event.target.value)}
                />
              </FormController>
            </Row>
            <Button
              color='primary'
              isLoading={isLoading}
            >
              <CheckIcon />
              <span>Save Profile</span>
            </Button>
          </MainFormContainer>
        </ProfileForm>
      </InnerContainer>
    </Container>
  )
}