import styled from 'styled-components'

export const Container = styled.div``

export const InnerContainer = styled.div`
  max-width: 1440px;
  margin: 0px auto;
  padding: 20px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  
  @media (min-width: 576px) {
    padding: 20px 20px;
  }
  
  @media (min-width: 768px) {
    padding: 30px 30px;
  }
  
  @media (min-width: 850px) {
    flex-direction: row;
  }
  @media (min-width: 993px) {
    padding: 35px 40px;
  }

  @media (min-width: 1024px) {
    padding: 40px 60px;
  }
  
  @media (min-width: 1500px) {
    padding: 46px 30px;
  }
`

export const LogoAndMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
  @media (min-width: 850px) {
    margin-bottom: 0px;
    flex-direction: row;
  }
`

export const Logo = styled.img`
  height: 30px;
  object-fit: cover;
  cursor: pointer;
  transition: all 0.2s ease-out;
  &:hover {
    transform: scale(1.04);
  }
  @media (min-width: 576px) {
    height: 28px;
  }
  @media (min-width: 769px) {
    height: 30px;
  }
  @media (min-width: 993px) {
    height: 38px;
  }
  @media (min-width: 1200px) {
    height: 42px;
  }
`

export const MenuList = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-top: 20px;

  flex-direction: column;
  @media (min-width: 769px) {
    margin-left: 15px;
  }
  @media (min-width: 850px) {
    margin-top: 0px;
    flex-direction: row;
  }
  @media (min-width: 993px) {
    margin-left: 20px;
  }
  @media (min-width: 1200px) {
    margin-left: 35px;
  }
`

export const MenuItem = styled.span`
  cursor: pointer;
  margin: 5px 15px;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: ${props => props.theme.colors.black};
  transition: all 0.3s linear;
  &:hover {
    color: ${props => props.theme.colors.primary};
  }
  @media (min-width: 769px) {
    font-size: 16px;
    margin: 0px 10px;
  }
  @media (min-width: 993px) {
    margin: 0px 18px;
  }
  @media (min-width: 1200px) {
    margin: 0px 24px;
    font-size: 18px;
  }
`

export const CopyWriter = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  color: ${props => props.theme.colors.black};

  @media (min-width: 769px) {
    font-size: 16px;
  }

  @media (min-width: 1200px) {
    font-size: 18px;
  }
`
