import React, { useState, useCallback } from 'react'
import cogoToast from 'cogo-toast'
import _ from 'lodash'
import utils from '../../../../utils';
import { NewProjectIcon } from '../../../Shared/SvgIcons'
import { Button, Input, Select } from '../../../Shared'
import { UserIcon } from '../../../Shared/SvgIcons'
import {
  Container,
  InnerContainer,
  Header,
  Description,
  FormController
} from './styles'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import { getUserByEmail, inviteUser } from '../../../../services';

export const AddUser = (props) => {
  const { onAdded, project_id } = props

  const roleList = [
    {value: '', name: 'All Roles'},
    {value: 'admin', name: 'Administrator'},
    {value: 'user', name: 'User'}
  ]

  const [loading, setLoading] = useState(false)
  const [searching, setSearching] = useState(true)
  const [userFound, setUserFound] = useState(false)
  const [role, setRole] = useState('')
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] =  useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [bio, setBio] = useState('')


  const getUserFromEmail = async (email) => {
		try {
      setSearching(true);
      setUserFound(false);
			
      const user = await getUserByEmail(email);
			setFirstName(user.UserAttributes.find(attr => attr.Name === 'given_name').Value)
      setLastName(user.UserAttributes.find(attr => attr.Name === 'family_name').Value)
      setPhoneNumber(user.UserAttributes.find(attr => attr.Name === 'phone_number').Value)
      setBio(user.UserAttributes.find(attr => attr.Name === 'custom:bio').Value)
      setUserFound(true);

		} catch (error) {
      setFirstName('')
      setLastName('')
      setPhoneNumber('')
      setBio('')
		} finally {
      setSearching(false);
		}
	}

  const debouncedGetUserByEmail = useCallback(_.debounce(getUserFromEmail, 300), []);

  const handleChangeEmail = (event) => {
    const email = event.target.value;
    setEmail(email);

    if (utils.validateEmail(email)) {
      debouncedGetUserByEmail(email)
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (loading) return;
    if (!utils.validateEmail(email)) {
			return cogoToast.error('Enter valid email.');
		}

    try {
      setLoading(true);

      if (!role) {
        return cogoToast.error('Please choose the role.');
      }
      await inviteUser(
				project_id,
				{
					email: email,
          first_name: firstName,
          last_name: lastName,
          phone_number: phoneNumber,
          bio: bio
				},
				role
			);

      onAdded && onAdded();
			cogoToast.success('We\'ve sent invitation to the user successfully.')

    } catch (error) {
      cogoToast.error(error.data.message || error.data);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      <InnerContainer onSubmit={handleSubmit}>
        <Header>
          <NewProjectIcon />
          <h1>Add New User</h1>
        </Header>
        <Description>Please fill out the user details then submit.</Description>
        <FormController>
          <label>User Role</label>
          <Select
            options={roleList}
            defaultValue={role}
            onChange={setRole}
          />
        </FormController>
        <FormController>
          <label>Email</label>
          <Input
            type="email"
            value={email}
            required
            onChange={handleChangeEmail}
          />
        </FormController>

        {!searching &&
        <>
          <FormController>
            <label>First Name</label>
            <Input
              type="text"
              disabled={userFound}
              value={firstName}
              required
              onChange={(event) => setFirstName(event.target.value)}
            />
          </FormController>

          <FormController>
            <label>Last Name</label>
            <Input
              type="text"
              disabled={userFound}
              value={lastName}
              required
              onChange={(event) => setLastName(event.target.value)}
            />
          </FormController>

          <FormController>
            <label>Phone Number</label>
            <PhoneInput
              disabled={userFound}
              placeholder="Enter phone number"
              value={phoneNumber}
              required
              onChange={setPhoneNumber}
            />
          </FormController>

          <FormController>
            <label>Bio</label>
            <Input
              type="text"
              disabled={userFound}
              value={bio}
              onChange={(event) => setBio(event.target.value)}
            />
          </FormController>
       
          <Button color='darkBlue' isLoading={loading}>Submit</Button>
        </>
        }
      </InnerContainer>
    </Container>
  )
}
