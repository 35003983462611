import styled from 'styled-components'

export const Container = styled.div`
  padding-top: 120px;
  margin-bottom: 80px;

  @media (min-width: 768px) {
    padding-top: 140px;
    margin-bottom: 0px;
  }
`
export const InnerContainer = styled.div`
  max-width: 1440px;
  min-height: inherit;
  margin: 0px auto;
  padding: 15px;

  @media (min-width: 576px) {
    padding: 15px 20px;
  }

  @media (min-width: 768px) {
    padding: 20px 30px;
  }

  @media (min-width: 993px) {
    padding: 24px 40px;
  }

  @media (min-width: 1024px) {
    padding: 28px 60px;
  }
  
  @media (min-width: 1500px) {
    padding: 28px 30px;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  h1 {
    font-weight: 600;
    font-size: 40px;
    line-height: 100%;
    color: ${props => props.theme.colors.black};
    margin-right: 10px;
  }

  > div.action {
    display: flex;
    align-items: center;
    margin-top: 32px;

    > button {
      margin-left: 20px;
      height: 55px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 10px;
      }
      span {
        font-weight: 700;
        font-size: 16px;
      }
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    > div.action {
      margin-top: 0;
      
      > button {
        margin-left: 40px;
      }
    }
  }
  @media (min-width: 1024px) {
    h1 {
      font-size: 56px;
    }
  }
`

export const MessageList = styled.div`
  margin-top: 32px;
`
export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 70px 0;
`

export const BackButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  margin-top: 16px;
  width: fit-content;
  padding: 5px;
  border-radius: 5px;
  transition: all 0.3s linear;

  span {
    margin-left: 12px;
    font-weight: 500;
    font-size: 16px;
    color: ${props => props.theme.colors.primary};
    line-height: 150%;
    font-family: 'Maven Pro';
  }

  &:hover {
    background: #e0eeff;
  }
`